import React, { useState, useContext, useEffect, } from "react";

import PassportEditor from "./PassportEditor";
import InterestLibraryEditor from "./InterestLibraryEditor";
import ProfileContext from "../contexts/ProfileContext";
import Tabs from "./Tabs";
import { Checkbox } from 'semantic-ui-react'
import firebase from "../firebase";

import PathbreakerLogo from "../images/pathbreaker-logo-latest-black.png"

import "../styles/NewUserFlow.scss";
import AuthContext from "../contexts/AuthContext";

const NewUserFlow = (props) => {

    const [activeView, setActiveView] = useState(0);

    const profileContext = useContext(ProfileContext);

    if (activeView === 0) {

        return <UserChannelForm handleNext={() => setActiveView(1)}/>

    } else if (activeView === 1) {
        return (
            <div className="profile-modal">
                <div className="profile-modal-header">
                    <span>
                        My Profile
                        <div 
                            className="profile-save-button" 
                            onClick={() => {
                                profileContext.saveToDB();
                                profileContext.setNewUser(false);
                            }}>
                            <i className="material-icons">done</i>
                        </div>
                    </span>
                    <div className="profile-modal-subheader">
                        Customize your profile below! You can also access your profile at any point using the navigator.
                    </div>
                </div>
                <Tabs>
                    <div label="Passport">
                        <div className="profile-modal-section-header">
                            Select any categories below which you feel impact how you travel:
                        </div>
                        <PassportEditor />
                    </div>
                    <div label="Interest Library">
                        <div className="profile-modal-section-header">
                            Tell us about the things you enjoy doing or aspire to do while traveling:
                        </div>
                        <InterestLibraryEditor inProfileModal={true}/>
                    </div>
                </Tabs>
            </div>
        )
    }
}

const UserChannelForm = (props) => {

    const { handleNext } = props;

    const [channels, setChannels] = useState([]);
    const [other, setOther] = useState('');

    const { email } = useContext(AuthContext)

    useEffect(() => {
        if (other && !channels.includes('other')) setOther('')
    }, [channels])

    const options = [
        {
            key: 'instagram',
            value: 'instagram',
            text: 'Instagram'
        },
        {
            key: 'tiktok',
            value: 'tiktok',
            text: 'TikTok'
        },
        {
            key: 'linkedin',
            value: 'linkedin',
            text: 'LinkedIn'
        },
        {
            key: 'word-of-mouth',
            value: 'word-of-mouth',
            text: 'Word of Mouth'
        },
        {
            key: 'the-travel-squad',
            value: 'the-travel-squad',
            text: 'The Travel Squad'
        },
        {
            key: 'single-women-solo-travel',
            value: 'single-women-solo-travel',
            text: 'Single Women Solo Travel'
        },
        {
            key: 'other',
            value: 'other',
            text: 'Other'
        },
    ]

    const toggleValue = (option, bool) => {
        console.log(option, bool)
        if (bool) {
            let newChannels = [...channels];
            newChannels.push(option);
            setChannels(newChannels)
        } else {
            let newChannels = channels.filter(val => val !== option);
            setChannels(newChannels);
        }
    }

    const onClickNext = () => {

        let value = channels.join(',');
        if (value.includes('other')) {
            value = value.replace('other', other);
        }

        firebase.firestore()
            .collection('user-acquisition-channels')
            .add({
                email: email,
                date: new Date(),
                channels: value,
            })
            .then(res => {
                console.log("FEEDBACK SUBMITTED: ", res);
                handleNext();
            })
            .catch(err => {
                console.error("Failed to submit: ", err);
            })
    }

    const isNextDisabled = !channels.length ? true : (!channels.includes('other') || other) ? false : true;

    return (
        <div className="user-channel-form">

            <h2>Welcome to</h2>

            <img src={PathbreakerLogo} />

            <h4>How did you hear about us? (Select all that apply)</h4>

            <div className="checkboxes">
                {options.map((option, key) => (
                    <Checkbox
                        key={option.key}
                        label={option.text}
                        checked={channels.includes(option.value)}
                        onChange={(e, data) => toggleValue(option.value, data.checked)}
                    />
                ))}
            </div>
            

            {channels.includes('other') ? (
                <input
                    placeholder="Tell us more..."
                    value={other}
                    onChange={e => setOther(e.target.value)}
                />
            ) : null}

            <div className={`next-btn${isNextDisabled ? ' disabled' : ''}`} onClick={isNextDisabled ? () => {} : onClickNext}>
                <i className="material-icons">arrow_forward_ios</i>
            </div>

        </div>
    )
}

export default NewUserFlow;
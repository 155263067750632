
// take coordinates of 2 nodes
// return route between these two nodes
const getRoute = async (coords, type) => {
    let nodeCoords = coords.join(';');

    let url = `https://api.mapbox.com/directions/v5/mapbox/${type}/${nodeCoords}?geometries=geojson&steps=true&&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

    return new Promise((resolve, reject) => {
        let routeCoords = [];
        let req = new XMLHttpRequest();
        req.responseType = 'json';
        req.open('GET', url, true);
        req.onload  = () => {
            // var distance = jsonResponse.routes[0].distance*0.001; // convert to km
            // var duration = jsonResponse.routes[0].duration/60; // convert to minutes

            // if route exists, set routeCoords as the response
            // if route doesnt exist, set routeCoords as the input coords -- this will connect locations as direct line
            if(req.response.code == "InvalidInput" || !req.response.routes || req.response.routes.length == 0) {

                routeCoords = {
                    coordinates: coords,
                    type: "LineString"
                }
                routeCoords.distance = getDistanceFromLngLatInKm(coords[0], coords[1]);

            } else {
                routeCoords = req.response.routes[0].geometry;
                routeCoords.distance = req.response.routes[0].distance*0.001;
                routeCoords.duration = req.response.routes[0].duration/60;
            }

            // correct line if shortest route is across 180 meridian rather than across world

            let startLng = routeCoords.coordinates[0][0];
            let endLng = routeCoords.coordinates[1][0];
            if (endLng - startLng >= 180) {
                routeCoords.coordinates[1][0] -= 360;
            }

            resolve(routeCoords);
        };
        req.onerror = () => {
            reject({
                status: this.status,
                statusText: req.statusText
            });
        };
        req.send();
    })
}

const getRouteThroughPoints = async (coordList, navType) => {
    if(coordList.length > 1) {
        let coordinates = [];
        let distance = 0;
        for(let i = 0; i < coordList.length - 1; i++) {
            let pathwaySegment = await getRoute([coordList[i], coordList[i+1]], navType);
            coordinates.push.apply(coordinates, pathwaySegment.coordinates);
            distance += pathwaySegment.distance;
        }
        return {
            coordinates,
            distance,
        };
    } else return []
}

const formatPathFromDb = async (dbPath, pathId = null) => {

    const path = {
        pid: dbPath.id,
        ...(dbPath.user_id && {
            userId: dbPath.user_id
        }),
        pathName: dbPath.path_name,
        ...(dbPath.start_date && { startDate: new Date(Date.parse(dbPath.start_date))}),
        ...(dbPath.end_date && { endDate: new Date(Date.parse(dbPath.end_date))}),
        ...(dbPath.caption && { caption: dbPath.caption}),
        ongoing: dbPath.ongoing,
        future: dbPath.future,
        details: {
            cohort: dbPath.cohorts.map(cohort => cohort.name),
            companions: dbPath.companions.map(companion => companion.name),
            pets: dbPath.pets.map(pet => pet.name),
            spaces: dbPath.spaces.map(space => space.name),
            stays: dbPath.stays.map(stay => stay.name),
            length: dbPath.length ? [dbPath.length] : [],
            budgets: dbPath.budget ? [dbPath.budget] : [],
        },
        tags: {
            recreation: dbPath.tags.reduce((result, tag) => { 
                if(tag.category == 'recreation') result.push(tag.name);
                return result;
            }, []),
            culture: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'culture') result.push(tag.name);
                return result;
             }, []),
            wellness: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'wellness') result.push(tag.name);
                return result;
            }, []),
            nature: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'nature') result.push(tag.name);
                return result;
            }, []),
            shopping: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'shopping') result.push(tag.name);
                return result;
            }, []),
            fun: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'fun') result.push(tag.name);
                return result;
            }, []),
            sightseeing: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'sightseeing') result.push(tag.name);
                return result;
            }, []),
            nightlife: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'nightlife') result.push(tag.name);
                return result;
            }, []),
            cuisine: dbPath.tags.reduce((result, tag) => {
                if(tag.category == 'cuisine') result.push(tag.name);
                return result;
            }, []),
        },
        secrets: [],
        nodes: dbPath.breaks.map(node => {
            return {
                id: node.id,
                address: node.address,
                placeName: node.place_name,
                country: node.country,
                long: node.longitude,
                lat: node.latitude,
                ...(pathId && {
                    pathId: pathId,
                }),
                breakIndex: node.break_index,
                fields: {
                    title: node.title ? node.title : "",
                    description: node.description ? node.description : "",
                    breakType: node.break_type,
                    length: node.length,
                    images: node.images.map(img => {
                        return {
                            id: img.id,
                            url: img.image_url
                        }
                    })
                },
                subbreaks: node.subbreaks.map(sb => {
                    return {
                        id: sb.id,
                        placeName: sb.place_name,
                        address: sb.address,
                        country: sb.country,
                        long: sb.longitude,
                        lat: sb.latitude,
                        breakIndex: node.break_index,
                        subbreakIndex: sb.subbreak_index,
                        images: sb.images.map(img => ({
                            id: img.id,
                            url: img.image_url
                        })),
                        fields: {
                            description: sb.description ? sb.description : "",
                            breakType: sb.break_type,
                        }
                    }
                })

            }
        }),
        routePoints: dbPath.pathways.map(pathway => {
            return {
                id: pathway.id,
                type: "LineString",
                navType: pathway.nav_type,
                navIndex: pathway.pathway_index,
                ...(pathId && {
                    pathId: pathId.toString(),
                }),
                startCoords: [pathway.start_lng, pathway.start_lat],
                endCoords: [pathway.end_lng, pathway.end_lat],
                label: pathway.label,
                index: pathway.pathway_index, // ! MAY NOT BE NEEDED... SHOULD JUST USE NAVINDEX - including so nothing breaks
                fields: {
                    description: pathway.description,
                    images: pathway.images.map(img => {
                        return {
                            id: img.id,
                            url: img.image_url
                        }
                    }),
                    transportTypes: pathway.transport_types,
                },
                midpoints: pathway.waypoints.map(wp => {
                    return { // ! MAY NEED TO DO THIS DIFFERENTLY (using getInfoFromCoordinates method potentially)
                        coordinates: [wp.longitude, wp.latitude],
                        address: wp.address,
                    }
                })
            }
        })
    }

    path.routePoints = await Promise.all(path.routePoints.map(pathway => getRouteForPathway(pathway)));
        
    return path;
}

const getRouteForPathway = async (pathway) => {
    const route = await getRouteThroughPoints([
        pathway.startCoords,
        ...(pathway.midpoints.map(wp => wp.coordinates)),
        pathway.endCoords
    ], pathway.navType);

    pathway.coordinates = await route.coordinates;
    pathway.distance = await route.distance;

    return pathway;
}

// * ------ DISTANCE FORMULAS ------

const getDistanceFromLngLatInKm = (coords1, coords2) => {
    const [ lng1, lat1 ] = coords1;
    const [ lng2, lat2 ] = coords2;

    const R = 6371; // Radius of the earth in km
    const dLat = degToRad(lat2-lat1);  // degrees to radians
    const dLon = degToRad(lng2-lng1); 
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    return d;
}


const degToRad = (deg) => {
    return deg * (Math.PI/180)
}

const kmToMiles = (distance) => {
    return (0.621371 * distance);
}

const getTotalDistanceCoveredKm = (paths) => {
    let totalDistance = 0;
    paths.forEach(path => {
        console.log("PATH: ", path);
        if(path.routePoints && path.routePoints.length) {
            path.routePoints.forEach(way => {
                if(way.distance) {
                    totalDistance += way.distance;
                }
            })
        }
    })
    return totalDistance
}

// * -------------------------------


const pathUtils = {
    getRoute,
    getRouteThroughPoints,
    formatPathFromDb,
    kmToMiles,
    getTotalDistanceCoveredKm,
}

export default pathUtils;
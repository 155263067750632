import React, { Component, Fragment, useContext, useEffect, useState } from 'react';
import firebase from '../firebase';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { toast, ToastContainer } from 'react-toastify';
import AuthContext from '../contexts/AuthContext';
import ProfileContext from '../contexts/ProfileContext';
import UserDataContext from '../contexts/UserDataContext';
import ScaleLoader from 'react-spinners/ScaleLoader';

import logoBlack from '../images/pathbreaker-logo-latest-black.png';
import appleLogoBlack from '../images/apple-logo-black.png';
import '../styles/AuthPage.scss';

import PrivacyPolicy from '../resources/privacy-policy.pdf';
import AcceptableUsePolicy from '../resources/acceptable-use-policy.pdf';
import userApi from '../utils/userApi';

const firebaseUiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
}

const authViews = {
    SIGNIN: 'signIn',
    SIGNUP: 'signUp'
}

const AuthPage = (props) => {

    const authContext = useContext(AuthContext);
    const profileContext = useContext(ProfileContext);
    const userDataContext = useContext(UserDataContext);

    const [loading, setLoading] = useState(true);
    const [authType, setAuthType] = useState(authViews.SIGNIN);
    const [accessCodeGranted, setAccessCodeGranted] = useState(false);

    useEffect(() => {
        let mounted = true;
        setTimeout(() => {
            if(mounted) setLoading(false);
        }, 3000);
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                setLoading(true);
                if(!authContext.userId) {
                    fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users?email=${firebase.auth().currentUser.email}`)
                        .then(res => res.json())
                        .then(dbUser => {
                            // * if error, need to create this user
                            if(dbUser.error) {
                                // console.log("ON AUTH STATE CHANGE, tried to get user but failed so posting new user to db");
                                createNewUser(firebase.auth().currentUser.email);
                                profileContext.setNewUser(true);
                            } else if(dbUser.id) {
                                loadUser(dbUser);
                            }
                        })
                        .catch(err => {
                            console.error("FAILED TO GET USER FROM DB: ", err);
                        })
                }
            }
        });
        return () => { mounted = false }
    }, [])

    const loadUser = (user) => {
        authContext.setUserId(user.id);
        authContext.setEmail(user.email)
        profileContext.loadProfileFieldsFromDB(user);
        userDataContext.loadPathDataFromDB(user.paths, user.favorite_paths);
    }

    const createNewUser = (email, username = null, firebaseCreateUserCallback = null) => {

        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                'email': email,
                ...(username && {'username': username})
            })
        })
            .then(res => res.json())
            .then(user => {
                console.log("SUCCESSFULLY CREATED USER: ", user);
                loadUser(user)
                profileContext.setNewUser(true)
                if(firebaseCreateUserCallback) firebaseCreateUserCallback();
            })
            .catch(err => {
                toast.error("CREATE USER FAILED: ", err);
            })
    }

    return (
        <div className="auth-page-container">
            {loading ? (
                <ScaleLoader color={"#fff"} loading={loading} />
            ) : (
                <div className='auth-container'>
                    <div className='container'>
                        {authType === 'signIn' ? (
                            <Fragment>
                                <SignIn loadUser={loadUser} setLoading={setLoading} />
                                <span 
                                    onClick={() => setAuthType('forgotPassword')}
                                >Recover password</span>
                                <p>New here? <span onClick={() => setAuthType('signUp')}>Create account.</span></p>

                            </Fragment>
                        ) : authType === 'signUp' ? (
                            <Fragment>
                                {/* {!accessCodeGranted ? (
                                    <AccessCodeView setAccessCodeGranted={setAccessCodeGranted} setAuthType={setAuthType} />
                                ) : ( */}
                                <SignUp createUser={createNewUser} setLoading={setLoading} />
                                <p>Have an account? <span onClick={() => setAuthType('signIn')}>Sign In.</span></p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <ForgotPassword />
                                <p><span onClick={() => setAuthType('signIn')}>Back to Sign In</span></p>
                            </Fragment>
                            
                        )}
                        {/* {
                            authType !== 'forgotPassword' ?
                            <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
                            :
                            null
                        } */}
                    </div> 
                </div>
            )}
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

const SignIn = (props) => {

    const { loadUser, setLoading } = props;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signIn = (e) => {

        e.preventDefault();

        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users?email=${email}`)
            .then(res => res.json())
            .then(user => {
                
                if(user.error) {
                    toast.error(user.error)
                } else {
                    firebase.auth().signInWithEmailAndPassword(email, password)
                        .then(res => {
                            setLoading(true);
                            setTimeout(() => {
                                loadUser(user);
                            }, 2000)
                        })
                        .catch(err => {
                            console.error("Failed to login to firebase: ", err);
                            toast.error(err.message)
                        })
                }
            })
            .catch(err => {
                toast.error("Failed to connect to database: ", err)
            })
    }

    return (
        <div className='sign-in'>
            <img className="logo" src={logoBlack} />
            <form onSubmit={signIn}>
                <input type='text' placeholder='Enter your email' value={email} onChange={e => setEmail(e.currentTarget.value)} />
                <input type='password' placeholder='Enter your password' value={password} onChange={e => setPassword(e.currentTarget.value)} />
                <div className="auth-submit-button" onClick={signIn}>Sign In</div>
            </form>
            <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} />
            <AppleSignInButton />
        </div>
    )
}

const SignUp = (props) => {

    const { createUser, setLoading } = props;

    const profileContext = useContext(ProfileContext);

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [usernameInvalid, setUsernameInvalid] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    useEffect(() => {
        if(usernameInvalid) {
            setUsernameInvalid(false);
        }
    }, [username])

    const signUp = async (e) => {

        e.preventDefault();

        if(password.length < 8) {
            toast.error("Password must be at least 8 characters");
            return;
        }

        if(password !== passwordConfirm) {
            setPassword('');
            setPasswordConfirm('');
            toast.error("Passwords must match");
            return;
        }

        const usernameExists = await userApi.checkUsernameExists(username);
        if(username && usernameExists.exists) {
            setUsername('');
            setUsernameInvalid(true);
            return;
        }

        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users?email=${email}`)
            .then(res => res.json())
            .then(user => {
                if(user.id) {
                    toast.error("User with this email already exists")
                } else {
                    setLoading(true);
                    createUser(email, username, () => {
                        firebase.auth().createUserWithEmailAndPassword(email, password)
                            .then(res => {
                                firebase.auth().signInWithEmailAndPassword(email, password)
                                    .catch(err => toast.error(err.message))
                            })
                            .catch(err => toast.error(err.message))
                    })
                }
            })
    }

    return (
        <div className='sign-up'>
            <img className="logo" src={logoBlack} />
            <h2>Create your account:</h2>
            <form onSubmit={signUp}>
                <input {...(usernameInvalid && { className: "username-invalid"})} type='text' value={username} placeholder='Username' onChange={e => setUsername(e.currentTarget.value)} />
                {usernameInvalid && (
                    <label>Username already exists, please choose another</label>
                )}
                <input type='text' value={email} placeholder='Email' onChange={e => setEmail(e.currentTarget.value)} />
                <input type='password' value={password} placeholder='Enter password' onChange={e => setPassword(e.currentTarget.value)} />
                <input type='password' value={passwordConfirm} placeholder='Re-enter password' onChange={e => setPasswordConfirm(e.currentTarget.value)} />
                <div
                    className="agree-terms-container"
                    
                >
                    <input 
                        type="checkbox" 
                        value={acceptTerms}
                        checked={acceptTerms}
                        onChange={() => setAcceptTerms(!acceptTerms)}
                        
                    />
                    <div >
                        I confirm I have read and agree to the <a href={PrivacyPolicy} target="_blank">Privacy Policy</a> and <a href={AcceptableUsePolicy} target="_blank">Acceptable Use Policy</a>
                    </div>
                </div>
                <div className={`auth-submit-button${acceptTerms ? '' : ' disabled'}`} onClick={acceptTerms ? signUp : () => {}}>Sign Up</div>
                {acceptTerms ? <StyledFirebaseAuth uiConfig={firebaseUiConfig} firebaseAuth={firebase.auth()} /> : null }
            </form>
        </div>
    )
}

const ForgotPassword = (props) => {

    const [email, setEmail] = useState('')

    const sendRecoveryEmail = (e) => {

        e.preventDefault();

        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                toast.success(`Recovery email sent to ${email}`)
                setEmail('');
            })
            .catch((err) => toast.error(err.message)) 
    }

    return (
        <div className='sign-up'>
            <img className="logo" src={logoBlack} />
            <h2>Recover Password:</h2>
            <form onSubmit={sendRecoveryEmail}>
                <input 
                    type='text' 
                    placeholder='Enter your email address' 
                    value={email} 
                    onChange={e => setEmail(e.currentTarget.value)} 
                />
                <div className="auth-submit-button" onClick={sendRecoveryEmail}>Email me a recovery link</div>
            </form>
        </div>
    )
}

const AppleSignInButton = (props) => {

    const appleProvider = new firebase.auth.OAuthProvider('apple.com');

    const handleClick = () => {

        firebase.auth()
        .signInWithPopup(appleProvider)
            .then((result) => {
                const user = result.user;
                const credential = result.credential;
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The credential that was used.
                const credential = error.credential;
                console.log("ERROR: ", error);
            })
    }

    return (
        <div className="apple-sign-in-button">
            <button
                onClick={handleClick}
            >
                <img src={appleLogoBlack} />
                Sign In with Apple
            </button>
        </div>
        
    )

    
}

export default AuthPage;

import React, { Component, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';

const AuthContext = React.createContext('ben');

export const AuthStore = (props) => {

    const [userId, setUserId] = useState(0);
    const [email, setEmail] = useState(null);
    const [authModalOpen, setAuthModalOpen] = useState(false)

    // const history = useHistory();

    // useEffect(() => {
    //     if(userId) {
    //         history.push("/");
    //     }
    // }, [userId])

    const logOut = () => {
        setUserId(0);
        setEmail(null);
    }

    return (
        <AuthContext.Provider value = {{
            userId,
            setUserId,

            email,
            setEmail,

            authModalOpen,
            setAuthModalOpen,

            logOut
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
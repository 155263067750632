import React, { useRef, useContext, useEffect, useState } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import UserDataContext from '../contexts/UserDataContext';
import { useBreakpoints } from "react-breakpoints-hook";
// import '../styles/PathCreator.scss';
import '../styles/MapSearchBar.scss';

import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng, } from "use-places-autocomplete";

import PoweredByGoogle from "../images/powered-by-google/powered_by_google_on_white.png";
import SearchIcon from "../images/search.png";

const googleApiLibraries = ["places"]
 
const MapSearchBar = (props) => {

    const {
        setSearchResult,
        searchBarRef,
    } = props;

    // const { isLoaded } = useLoadScript({
    //     googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    //     libraries: googleApiLibraries
    // });

    const {
        ready,
        value,
        setValue,
        suggestions: {status, data},
        clearSuggestions,
    } = usePlacesAutocomplete();

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([])

    const userDataContext = useContext(UserDataContext);

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    useEffect(() => {
        console.log("SEARCH DATA: ", data);
    }, [data])

    const onSelectResult = (result) => {

        const {
            description,
            structured_formatting: {
                main_text,
            },
            terms,
        } = result;

        setValue('');
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
        .then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            const resultPoint = {
                placeName: main_text,
                address: description,
                country: terms.slice(-1)[0].value,
                long: lng,
                lat: lat,
            }
            // setSearchResult(resultPoint);
            userDataContext.addSearchPopupToMap(resultPoint);
            userDataContext.flyToNode([lng, lat]);
            if(mobile) userDataContext.setPBPaneView(null);
        });
        
    }

    return (
        <div className="map-search-bar">
            <div className="search-input-container">
                <img src={SearchIcon} />
                <input
                    ref={searchBarRef}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    disabled={!ready}
                    placeholder="Search map"
                />
            </div>
            
            {status === "OK" && (
                <div className="search-results-container">
                    {data.map((result) => (
                        <div
                            className="search-result"
                            key={result.place_id}
                            onClick={() => onSelectResult(result)}
                        >
                            <div className="search-result-name">{result.structured_formatting.main_text}</div>
                            <div className="search-result-address">{result.description}</div>
                        </div>
                    ))}
                    <div className="powered-by-google-footer">
                        <img src={PoweredByGoogle}/>
                    </div>
                </div>
            )}
        </div>
    )

    
}

export default MapSearchBar;


import _ from 'lodash';

const formatPassportFromDb = (passport) => {

    let userPassport = {};

    let formatPassport = _.omit({
        ...passport,
        birthday: passport.birthday ? new Date(Date.parse(passport.birthday)) : null,
        nationality: passport.nationalities.map(nation => nation.name),
        ethnicity: passport.ethnicities.map(ethnicity => ethnicity.name),
        gender: passport.genders.map(gender => gender.name),
        language: passport.languages.map(lang => lang.name),
        religion: passport.religions.map(religion => religion.name),
        sexuality: passport.sexualities.map(sex => sex.name),
        mobility: passport.mobility.map(mobility => mobility.name),
    }, ['ethnicities', 'nationalities', 'genders', 'languages', 'religions', 'sexualities', 'id']);

    Object.entries(formatPassport).forEach(([category, value]) => {
        userPassport[category] = {};
        if(value && (value.constructor !== Array || value.length)) {
            userPassport[category].impactTravel = true;
            userPassport[category].value = value;
        } else {
            userPassport[category].impactTravel = false;
            if(['hometown', 'diet', 'medical', 'impariment', 'neurodiversity', 'mental'].includes(category)) {
                userPassport[category].value = '';
            } else if (category === 'birthday') {
                userPassport[category].value = null;
            } else {
                userPassport[category].value = [];
            }
        }
    });

    return userPassport;
}

const formatInterestsFromDb = (interests) => {

    const interestLib = {
        recreation: [],
        culture: [],
        wellness: [],
        nature: [],
        shopping: [],
        fun: [],
        sightseeing: [],
        nightlife: [],
        cuisine: [],
    }

    interests.forEach(interest => {
        interestLib[interest.category].push(interest.name);
    })

    return interestLib;
}

const profileUtils = {
    formatPassportFromDb,
    formatInterestsFromDb,
}

export default profileUtils;
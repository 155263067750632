import React, { useState } from "react";
import '../styles/IconDropdown.scss';

// optionsView = 'horizontal-scroll', 'grid'

const IconDropdown = (props) => {

    const { 
        currentActive,
        clickIconHandler,
        icons,
        optionsView,
        headerText,
    } = props;

    const [active, setActive] = useState(currentActive);
    const [openDropdown, setOpenDropdown] = useState(false);

    const onClickIcon = (name) => {
        clickIconHandler(name);
        setActive(name);
        setOpenDropdown(false);
    }

    return (

        <div className="icon-dropdown-container">
            <div 
                className="icon-dropdown-controller" 
                onClick={() => setOpenDropdown(!openDropdown)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer '
                }}
            >
                <img src={icons[currentActive]} width={40}/>
                <i className="material-icons">{`${openDropdown ? 'arrow_drop_up' : 'arrow_drop_down'}`}</i>
            </div>
            <div
                className="icon-dropdown-options" 
                style={{ 
                    display: openDropdown ? 'flex' : 'none',
                    ...(openDropdown && {
                        flexDirection: 'column'
                    })
                }}
            >
                {headerText ? <label>{headerText}</label> : null}
                <div
                    style={{
                        ...((optionsView == 'horizontal-scroll') && { 
                            overflowX: 'scroll',
                        }),
                        ...((optionsView == 'grid') && { 
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }),
                    }}
                >
                    {Object.entries(icons).map(([name, icon], key) => (
                        <img 
                            key={key}
                            src={icon} 
                            width={50}
                            onClick={() => onClickIcon(name)}
                        />
                    ))}
                </div>
                
            </div>
        </div>
    )
}

export default IconDropdown;
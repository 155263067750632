import React, { useContext, useState } from 'react';
import '../styles/PathCreator.scss';
import DatePicker from "react-date-picker";
import Tags from './Tags';
import UserDataContext from '../contexts/UserDataContext';

const DetailsEditor = (props) => {

    const userDataContext = useContext(UserDataContext);

    const { activePath, userPaths } = userDataContext;

    const tagOptions = userDataContext.getPathDetailsTagOptions();
    const selectedTags = userPaths[activePath].details;

    return (
        <div className="path-details-editor">
            <div className="tags-editor-section">
                <h4>Details:</h4>

                <div className="details-section-header">Length</div>
                <Tags 
                    tagList={tagOptions['length']}
                    selectedTags={selectedTags['length']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'length'}
                    max={0}
                    multi={false}
                    allowNewTags={true}
                    newTagMaxLength={80}
                    colorAddIcon={false}
                />

                <div className="details-section-header">Cohorts</div>
                <Tags 
                    tagList={tagOptions['cohort']}
                    selectedTags={selectedTags['cohort']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'cohort'}
                    max={2}
                    multi={true}
                    allowNewTags={true}
                    newTagMaxLength={50}
                    colorAddIcon={false}
                />

                <div className="details-section-header">Companions</div>
                <Tags 
                    tagList={tagOptions['companions']}
                    selectedTags={selectedTags['companions']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'companions'}
                    max={2}
                    multi={true}
                    allowNewTags={true}
                    newTagMaxLength={50}
                    colorAddIcon={false}
                />

                <div className="details-section-header">Pets</div>
                <Tags 
                    tagList={tagOptions['pets']}
                    selectedTags={selectedTags['pets']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'pets'}
                    max={2}
                    multi={true}
                    allowNewTags={true}
                    newTagMaxLength={50}
                    colorAddIcon={false}
                />

                <div className="details-section-header">Spaces</div>
                <Tags 
                    tagList={tagOptions['spaces']}
                    selectedTags={selectedTags['spaces']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'spaces'}
                    max={3}
                    multi={true}
                    allowNewTags={true}
                    newTagMaxLength={50}
                />

                <div className="details-section-header">Stays</div>
                <Tags 
                    tagList={tagOptions['stays']}
                    selectedTags={selectedTags['stays']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'stays'}
                    max={5}
                    multi={true}
                    allowNewTags={true}
                    newTagMaxLength={50}
                    colorAddIcon={false}
                />

                <div className="details-section-header">Budget</div>
                <Tags 
                    tagList={tagOptions['budgets']}
                    selectedTags={selectedTags['budgets']}
                    updateSelectedTags={(type, selectedTags) => userDataContext.updatePathDetailsTags(activePath, type, selectedTags)}
                    type={'budgets'}
                    max={0}
                    multi={false}
                    allowNewTags={false}
                    colorAddIcon={false}
                />

            </div>

        </div>

    )

}

export default DetailsEditor;

import React, { Fragment, useEffect, useState, useContext } from 'react'
import '../styles/LogOutButton.scss';
import '../styles/SideBar.scss';
import firebase from '../firebase';

import AuthContext from '../contexts/AuthContext';
import UserDataContext from '../contexts/UserDataContext';
import ProfileContext from '../contexts/ProfileContext';

const LogOutButton = (props) => {
    const authContext = useContext(AuthContext);
    const userDataContext = useContext(UserDataContext)
    const profileContext = useContext(ProfileContext)

    

    useEffect(() => {
        if(!props.sidebarStateOpen && props.confirmLogout) {
            props.setConfirmLogout(false);
            
        }
    }, [props.sidebarStateOpen])

    const logOut = () => {

        if(props.confirmLogout) {
            firebase.auth().signOut().then(res => {
                authContext.logOut();
                profileContext.signOut();
                userDataContext.signOut();
    
            }).catch(err => {
                //do something else with err
                console.error("FAILED TO LOG OUT: ", err);
            })
        } else {
            props.setConfirmLogout(true);
            
        }
    }

    if(props.confirmLogout) {
        return (
            <div className="confirm-logout-container">
                <div className="confirm-logout">Confirm Logout?</div>
                <div className="auth-confirm-logout-actions">
                    <i className="material-icons" onClick={() => props.setConfirmLogout(false)}>close</i>
                    <i className="material-icons" onClick={logOut}>check</i>
                </div>
            </div>
        )
    } else {
        return (
            <div className="auth-profile-container" onClick={logOut}>
                <i className="material-icons">logout</i>
                <div className="profile-button">Log Out</div>
            </div>
        )
    }
}

export default LogOutButton;

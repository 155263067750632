import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/Tabs.scss';
import passportActive from '../images/path-details-icons/selected_passport.png';
import passportInactive from '../images/path-details-icons/unselected_passport.png';
import libraryActive from '../images/path-details-icons/selected_library.png';
import libraryInactive from '../images/path-details-icons/unselected_library.png';

// INSTRUCTIONS FOR USE:
// Use <Tabs> as a main wrapper tag
// provide each section as a child element
// each section needs a <div> parent wrapper with a label prop (this will be the title label of each tab section)
// each <div label="label"> wrapper should contain its content as a child - provide any content you want (react component, html, etc.)
const Tabs = (props) => {

    const [activeTab, setActiveTab] = useState(props.children[0].props.label)

    const numTabs = props.children.length;

    useEffect(() => {
        if(props.getActiveTab) props.getActiveTab(activeTab);
    }, [])

    useEffect(() => {
        if(props.setCurrentTab) props.setCurrentTab([activeTab])
    }, [activeTab])

    const onClickTabItem = (tab) => {
        setActiveTab(tab);
        if(props.getActiveTab) {
            props.getActiveTab(tab);
        }
    }

    return (
        <div className="tabs">
            <div className="tab-list">
                {props.children.map((child, key) => {
                    const { label, numContent } = child.props;

                    return (
                        <Tab
                            activeTab={activeTab}
                            key={key}
                            label={label}
                            onClick={onClickTabItem}
                            numTabs={numTabs}
                            numContent={numContent}
                        />
                    );

                })}
            </div>
            <div className="tab-content">
                {props.children.map((child, key) => (
                    <div 
                        key={key} 
                        style={{ 
                            height: "100%", 
                            display: `${child.props.label !== activeTab ? 'none' : 'flex'}`,
                            flexDirection: 'column'
                        }}
                    >
                        {child.props.children}
                    </div>
                ))}
            </div>
        </div>
    )

}

const Tab = (props) => {

    const onClick = () => {
        const { label, onClick } = props;
        onClick(label);
    }

    let className = 'tab-list-item';

    if(props.activeTab === props.label) {
        className += ' tab-list-active';
    }
    let iconSelect = null;

    // if (props.label == "Passport") {
    //   if(props.activeTab === props.label) {
    //       iconSelect = passportActive;
    //   }
    //   else {
    //       iconSelect = passportInactive;
    //   }
    // }
    // if (props.label == "Interest Library") {
    //   if(props.activeTab === props.label) {
    //       iconSelect = libraryActive;
    //   }
    //   else {
    //       iconSelect = libraryInactive;
    //   }
    // }

    let widthPercent = `${98 / props.numTabs}%`;

    return (
        <li
            className={className}
            onClick={onClick}
            style={{ width: widthPercent }}
        >
            {
                props.label !== "Info" ? 
                props.label 
                : 
                props.activeTab == "Info" ? 
                <img src={libraryActive} width={30} height={19}/>
                :
                <img src={libraryInactive} width={30} height={19}/>
            }
            {
                props.numContent ? 
                <div className="num-tab-content">{props.numContent}</div>
                :
                null
            }
            {
                (props.label == "Passport" || props.label == "Interest Library") ?
                <img src={iconSelect} height={37} className="path-details-icons"/> 
                : 
                null
            }
        </li>
    )
}

Tabs.propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}



export default Tabs;

import React, { Component, useCallback, useContext, useEffect, } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthPage from './pages/AuthPage';
import Dashboard from './pages/Dashboard';
// import PrivateRoute from './components/PrivateRoute';
import './styles/App.css';
import AuthContext from './contexts/AuthContext';
import { isDeviceiOSMobile } from './utils';

const App = (props) =>  {

  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (isDeviceiOSMobile()) {
      window.location = "https://apps.apple.com/us/app/pathbreaker/id1615717375";
    }
  }, []);

  return (
    <div className="App">
      {/* <PrivateRoute exact path="/" component={Dashboard} />
      <Route path="/auth" component={AuthPage} /> */}
      <Routes>
        <Route 
          path="/" 
          element={
            authContext.userId ? (
              <Dashboard />
            ) : (
              <AuthPage />
            )
          }
        >
          <Route path="paths">
            <Route 
              path=":pathId"
              element={
                authContext.userId ? (
                  <Dashboard />
                ) : (
                  <AuthPage />
                )
              }
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;


import eatColor from '../images/location-icons/eat-active.png';
import exploreColor from '../images/location-icons/explore-active.png';
import sleepColor from '../images/location-icons/sleep-active.png';
import cultureColor from '../images/interest-category-icons/culture.png';
import funColor from '../images/interest-category-icons/fun.png';
import natureColor from '../images/interest-category-icons/nature.png';
import nightlifeColor from '../images/interest-category-icons/nightlife.png';
import recreationColor from '../images/interest-category-icons/recreation.png';
import shoppingColor from '../images/interest-category-icons/shopping.png';
import sightseeingColor from '../images/interest-category-icons/sightseeing.png';
import styleColor from '../images/interest-category-icons/style.png';
import wellnessColor from '../images/interest-category-icons/wellness.png';
import workColor from '../images/interest-category-icons/work.png';
import waterColor from '../images/interest-category-icons/water.png';
import gymColor from '../images/interest-category-icons/gym.png';
import drinksColor from '../images/interest-category-icons/drinks.png';

import bikeActive from '../images/movement-icons/bike-active.png';
import bikeInactive from'../images/movement-icons/bike-inactive.png';
import boatActive from '../images/movement-icons/boat-active.png';
import boatInactive from '../images/movement-icons/boat-inactive.png';
import busActive from '../images/movement-icons/bus-active.png';
import busInactive from '../images/movement-icons/bus-inactive.png';
import camperActive from '../images/movement-icons/camper-active.png';
import camperInactive from '../images/movement-icons/camper-inactive.png';
import carActive from '../images/movement-icons/car-active.png';
import carInactive from '../images/movement-icons/car-inactive.png';
import horsebackActive from '../images/movement-icons/horseback-active.png';
import horsebackInactive from '../images/movement-icons/horseback-inactive.png';
import metroActive from '../images/movement-icons/metro-active.png';
import metroInactive from '../images/movement-icons/metro-inactive.png';
import motorbikeActive from '../images/movement-icons/motorbike-active.png';
import motorbikeInactive from '../images/movement-icons/motorbike-inactive.png';
import planeActive from '../images/movement-icons/plane-active.png';
import planeInactive from '../images/movement-icons/plane-inactive.png';
import sailboatActive from '../images/movement-icons/sailboat-active.png';
import sailboatInactive from '../images/movement-icons/sailboat-inactive.png';
import trainActive from '../images/movement-icons/train-active.png';
import trainInactive from '../images/movement-icons/train-inactive.png';
import walkActive from '../images/movement-icons/walk-active.png';
import walkInactive from '../images/movement-icons/walk-inactive.png';
import taxiActive from '../images/movement-icons/taxi-active.png';
import taxiInactive from '../images/movement-icons/taxi-inactive.png';

export const localSpotIcons = {
    'explore': exploreColor,
    'eat': eatColor,
    'sleep': sleepColor,
    'culture': cultureColor,
    'fun': funColor,
    'nature': natureColor,
    'nightlife': nightlifeColor,
    'recreation': recreationColor,
    'shopping': shoppingColor,
    'sightseeing': sightseeingColor,
    'style': styleColor,
    'wellness': wellnessColor,
    'work': workColor,
    'water': waterColor,
    'gym': gymColor,
    'drinks': drinksColor,
}

export const navIcons = {
    'bike': bikeInactive,
    'boat': boatInactive,
    'bus': busInactive,
    'camper': camperInactive,
    'car': carInactive,
    'horseback': horsebackInactive,
    'metro': metroInactive,
    'motorbikeIcon': motorbikeInactive,
    'plane': planeInactive,
    'sailboat': sailboatInactive,
    'train': trainInactive,
    'walk': walkInactive,
    'taxi': taxiInactive,
}
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import UserDataContext from "../contexts/UserDataContext";
import AuthContext from "../contexts/AuthContext";
import Tabs from "./Tabs";
import LocationEditor from "./LocationEditor";
import NavigationEditor from "./NavigationEditor";
import PathInfo from "./PathInfo";
import MapSearchBar from "./MapSearchBar";
import _ from "lodash";
import "../styles/PathCreator.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useBreakpoints } from "react-breakpoints-hook";
import ClipLoader from 'react-spinners/ClipLoader';

const PathCreator = (props) => {

	const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

  const {
    confirmPathSave,
    setConfirmPathSave,
  } = props;

  // static contextType = UserDataContext;
  const userDataContext = useContext(UserDataContext);
  const authContext = useContext(AuthContext);

  const {
    userPaths,
    activePath,
    activeBreak,
    activePathway,
  } = userDataContext;

  // * STATE VARIABLES
  const [confirmPathDeletion, setConfirmPathDeletion] = useState(false);
  const [backConfirmation, setBackConfirmation] = useState(false);
  const [currentPathInitial, setCurrentPathInitial] = useState({});
  const [activePathNameField, setActivePathNameField] = useState("");
  const [pathIdToDelete, setPathIdToDelete] = useState(null);
  const [pathChanges, setPathChanges] = useState({
    pathName: "",
    locationFields: [],
    navFields: [],
  });
  const [currentTab, setCurrentTab] = useState("");

  const [breakIdToDelete, setBreakIdToDelete] = useState(null);

  const searchBarRef = useRef(null);

  useEffect(() => {
    let activePathInitial = _.cloneDeep(userPaths[activePath]);
    setCurrentPathInitial(activePathInitial);
    setActivePathNameField(activePathInitial.pathName);
  }, []);

  // * when active path changes, reset activeBreakToDelete
  useEffect(() => {
    setBreakIdToDelete(null);
  }, [activePath]);

  const handlePathNameChange = (event) => {
    let paths = Object.assign({}, userPaths);
    let { value, maxLength } = event.target;
    paths[activePath].pathName = value.slice(0, maxLength);
    setActivePathNameField(value.slice(0, maxLength));
    userDataContext.setUserPaths(paths);
  };

  // will update path name if it has been changed and set this as activePath
  const savePath = async () => {
    // * if pathName field is empty, set it back to initial pathName before changes
    if (!activePathNameField) {
      // ! NEW CODE
      let paths = Object.assign({}, userPaths);
      paths[activePath].pathName = currentPathInitial.pathName;
      setActivePathNameField(currentPathInitial.pathName);
      userDataContext.setUserPaths(paths);
    }

    if (authContext.userId) {
      let paths = Object.assign({}, userPaths);

      let breaks = paths[activePath].nodes.map((break_) => {
        return {
          latitude: break_.lat,
          longitude: break_.long,
          address: break_.address,
          place_name: break_.placeName,
          country: break_.country,
          break_index: break_.breakIndex,
          break_type: break_.fields.breakType,
          ...(break_.fields.title && { title: break_.fields.title }),
          ...(break_.fields.description && {
            description: break_.fields.description,
          }),
          ...(break_.fields.images.length && { images: break_.fields.images }),
          ...(break_.id && { id: break_.id }),
          ...(break_.subbreaks &&
            break_.subbreaks.length && {
              subbreaks: break_.subbreaks.map((subbreak, key) => {
                return {
                  longitude: subbreak.long,
                  latitude: subbreak.lat,
                  address: subbreak.address,
                  place_name: subbreak.placeName,
                  country: subbreak.country,
                  subbreak_index: key,
                  break_type: subbreak.fields.breakType,
                  description: subbreak.fields.description,
                  ...(subbreak.images && subbreak.images.length && {
                    images: subbreak.images,
                  }),
                  ...(subbreak.id && { id: subbreak.id }),
                };
              }),
            }),
        };
      });

      let pathways = paths[activePath].routePoints.map((pathway) => {
        return {
          pathway_index: pathway.navIndex,
          label: pathway.label,
          start_lng: pathway.startCoords[0],
          start_lat: pathway.startCoords[1],
          end_lng: pathway.endCoords[0],
          end_lat: pathway.endCoords[1],
          nav_type: pathway.navType,
          description: pathway.fields.description,
          transport_types: pathway.fields.transportTypes,
          ...(pathway.fields.images.length && {
            images: pathway.fields.images,
          }),
          ...(pathway.id && { id: pathway.id }),
          ...(pathway.midpoints &&
            pathway.midpoints.length && {
              waypoints: pathway.midpoints.map((waypoint, key) => {
                return {
                  longitude: waypoint.coordinates[0],
                  latitude: waypoint.coordinates[1],
                  waypoint_index: key,
                  address: waypoint.address,
                };
              }),
            }),
        };
      });

      if (!paths[activePath].pid) {
        // * NEW PATH - POST and save id's returned
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/paths/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: authContext.userId,
            breaks: breaks,
            pathways: pathways,
            path_name: paths[activePath].pathName,
            ...(paths[activePath].tags !== undefined && {
              tags: paths[activePath].tags,
            }),
            details: {
              ...(paths[activePath].startDate && {
                start_date: paths[activePath].startDate.toUTCString(),
              }),
              ...(paths[activePath].endDate && {
                end_date: paths[activePath].endDate.toUTCString(),
              }),
              ongoing: paths[activePath].ongoing,
              future: paths[activePath].future,
              ...(paths[activePath].caption && {
                caption: paths[activePath].caption,
              }),
              ...(paths[activePath].details &&
                paths[activePath].details.length && {
                  length: paths[activePath].details.length[0],
                }),
              ...(paths[activePath].details &&
                paths[activePath].details.budgets.length && {
                  budget: paths[activePath].details.budgets[0],
                }),
              ...(paths[activePath].details &&
                paths[activePath].details.cohort.length && {
                  cohorts: paths[activePath].details.cohort,
                }),
              ...(paths[activePath].details &&
                paths[activePath].details.companions.length && {
                  companions: paths[activePath].details.companions,
                }),
              ...(paths[activePath].details &&
                paths[activePath].details.pets.length && {
                  pets: paths[activePath].details.pets,
                }),
              ...(paths[activePath].details &&
                paths[activePath].details.spaces.length && {
                  spaces: paths[activePath].details.spaces,
                }),
              ...(paths[activePath].details &&
                paths[activePath].details.stays.length && {
                  stays: paths[activePath].details.stays,
                }),
            },
          }),
        })
          .then((res) => res.json())
          .then((savedPath) => {
            console.log("SAVE PATH RESPONSE: ", savedPath);

            paths[activePath].pid = savedPath.id;

            savedPath.breaks.forEach((b, key) => {
              paths[activePath].nodes[key].id = b.id;

              if (b.images && b.images.length) {
                b.images.forEach((img, index) => {
                  paths[activePath].nodes[key].fields.images[index].id = img.id;
                });
              }

              if (b.subbreaks && b.subbreaks.length) {
                b.subbreaks.forEach((sb, index) => {
                  paths[activePath].nodes[key].subbreaks[index].id = sb.id;

                  if (sb.images && sb.images.length) {
                    sb.images.forEach((img, i) => {
                      paths[activePath].nodes[key].subbreaks[index].images[i].id = img.id;
                    });
                  }
                });
              }
            });

            savedPath.pathways.forEach((pw, key) => {
              paths[activePath].routePoints[key].id = pw.id;

              if (pw.images && pw.images.length) {
                pw.images.forEach((img, index) => {
                  paths[activePath].routePoints[key].fields.images[index].id =
                    img.id;
                });
              }

              if (pw.waypoints && pw.waypoints.length) {
                pw.waypoints.forEach((wp, index) => {
                  paths[activePath].routePoints[key].midpoints[index].id =
                    wp.id;
                });
              }
            });
            console.log("SAVED PATH IN CONTEXT: ", paths[activePath]);
            userDataContext.setUserPaths(paths);
          })
          .catch((err) => console.error("FAILED TO POST PATH TO DB: ", err));
      } else {
        // * UPDATE EXISTING PATH - PUT and save id's if they are new
        console.log("PUT PATH - breaks: ", breaks);
        fetch(
          `${process.env.REACT_APP_BACKEND_API_BASE}/paths/${paths[activePath].pid}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              uid: authContext.userId,
              breaks: breaks,
              pathways: pathways,
              path_name: paths[activePath].pathName,
              ...(paths[activePath].tags !== undefined && {
                tags: paths[activePath].tags,
              }),
              details: {
                ...(paths[activePath].startDate && {
                  start_date: paths[activePath].startDate.toUTCString(),
                }),
                ...(paths[activePath].endDate && {
                  end_date: paths[activePath].endDate.toUTCString(),
                }),
                ongoing: paths[activePath].ongoing,
                future: paths[activePath].future,
                ...(paths[activePath].caption && {
                  caption: paths[activePath].caption,
                }),
                ...(paths[activePath].details &&
                  paths[activePath].details.length && {
                    length: paths[activePath].details.length[0],
                  }),
                ...(paths[activePath].details &&
                  paths[activePath].details.budgets.length && {
                    budget: paths[activePath].details.budgets[0],
                  }),
                ...(paths[activePath].details &&
                  paths[activePath].details.cohort.length && {
                    cohorts: paths[activePath].details.cohort,
                  }),
                ...(paths[activePath].details &&
                  paths[activePath].details.companions.length && {
                    companions: paths[activePath].details.companions,
                  }),
                ...(paths[activePath].details &&
                  paths[activePath].details.pets.length && {
                    pets: paths[activePath].details.pets,
                  }),
                ...(paths[activePath].details &&
                  paths[activePath].details.spaces.length && {
                    spaces: paths[activePath].details.spaces,
                  }),
                ...(paths[activePath].details &&
                  paths[activePath].details.stays.length && {
                    stays: paths[activePath].details.stays,
                  }),
              },
            }),
          }
        )
          .then((res) => res.json())
          .then((updatedPath) => {
            console.log("UPDATE PATH RESPONSE: ", updatedPath);

            // * if context breaks/subbreaks have no id, they are new, so store their ids...
            updatedPath.breaks.forEach((b, key) => {
              if (!paths[activePath].nodes[key].id)
                paths[activePath].nodes[key].id = b.id;

              if (b.images && b.images.length) {
                b.images.forEach((img, index) => {
                  if (!paths[activePath].nodes[key].fields.images[index].id)
                    paths[activePath].nodes[key].fields.images[index].id =
                      img.id;
                });
              }

              if (b.subbreaks && b.subbreaks.length) {
                b.subbreaks.forEach((sb, index) => {
                  if (!paths[activePath].nodes[key].subbreaks[index].id)
                    paths[activePath].nodes[key].subbreaks[index].id = sb.id;

                  if (sb.images && sb.images.length) {
                    sb.images.forEach((img, i) => {
                      if (!paths[activePath].nodes[key].subbreaks[index].images[i].id)
                        paths[activePath].nodes[key].subbreaks[index].images[i].id = img.id;
                    });
                  }
                });
              }
            });

            updatedPath.pathways.sort(
              (a, b) => a.pathway_index - b.pathway_index
            );
            // * if context pathways/waypoints have no id, they are new, so store their ids...
            updatedPath.pathways.forEach((pw, key) => {
              if (!paths[activePath].routePoints[key].id)
                paths[activePath].routePoints[key].id = pw.id;

              if (pw.images && pw.images.length) {
                pw.images.forEach((img, index) => {
                  if (!paths[activePath].routePoints[key].fields.images[index].id) {
                    paths[activePath].routePoints[key].fields.images[index].id = img.id;
                  }
                });
              }

              if (pw.waypoints && pw.waypoints.length) {
                pw.waypoints.forEach((wp, index) => {
                  if (!paths[activePath].routePoints[key].midpoints[index].id) {
                    paths[activePath].routePoints[key].midpoints[index].id = wp.id;
                  }
                });
              }
            });

            console.log("UPDATED PATH IN CONTEXT: ", paths[activePath]);
            userDataContext.setUserPaths(paths);
          })
          .catch((err) => console.error("FAILED TO UPDATE PATH IN DB: ", err));
      }
    }
  };

  // save button handler -- removed async and await before savePath() -- check if this works
  const handleSave = async () => {
    await savePath();

    if (confirmPathSave) {
      if (confirmPathSave.nextPane)
        userDataContext.setPBPaneView(confirmPathSave.nextPane);
        if (confirmPathSave.nextSharedPath) {
          userDataContext.setActiveSharedPath(confirmPathSave.nextSharedPath);
        } else {
          userDataContext.setActivePath(confirmPathSave.nextPathId || 0);
        }
        setConfirmPathSave(false);
      } else {
        userDataContext.setActivePath(0);
        userDataContext.setPBPaneView(null);
        // if we are currently on backConfirm screen, and we hit save, close backConfirm screen
        if (backConfirmation) setBackConfirmation(false);
      }
  };

  // back button handler -- takes user to backConfirmation screen if not already active, if active then handles no back no save
  const handleBack = () => {
    if (!(backConfirmation || confirmPathSave)) {
      setBackConfirmation(true);
      return;
    }

    // * OVERWRITE CONTEXT FOR THIS PATH WITH THE INITIAL STATE:
    let paths = Object.assign({}, userPaths);
    paths[activePath] = currentPathInitial;
    userDataContext.setUserPaths(paths);

    if (confirmPathSave) {
      if (confirmPathSave.nextSharedPath) {
        userDataContext.setActiveSharedPath(confirmPathSave.nextSharedPath)
      } else if (confirmPathSave.nextPathId) {
        userDataContext.setActivePath(confirmPathSave.nextPathId);
      } else userDataContext.setActivePath(0);

      if (confirmPathSave.nextPane) userDataContext.setPBPaneView(confirmPathSave.nextPane);

      setConfirmPathSave(false);
    } else if (backConfirmation) {
      userDataContext.setActivePath(0);
      userDataContext.setPBPaneView(null);

      setBackConfirmation(false);
    }
  };

  // if confirmPathDeletion is open, this is a confirm delete, so delete from context and close confirmPath screen
  const deletePath = (event) => {
    if (confirmPathDeletion) {
      userDataContext.deletePath(pathIdToDelete);
      setConfirmPathDeletion(false);
      return;
    } else {
      setConfirmPathDeletion(true);
      setPathIdToDelete(event.currentTarget.attributes[1].value);
    }
  };

  // function to lift state of Location Editor fields up to PathCreator
  const onLocationEditorFieldChange = (pathId, nodeId, fields) => {
    let paths = Object.assign({}, userPaths);
    paths[pathId].nodes[nodeId].fields = fields;
    userDataContext.setUserPaths(paths);

    let changes = pathChanges;
    changes.locationFields[nodeId] = fields;
    setPathChanges(changes);
  };

  const onNavEditorFieldChange = (navIndex, fields) => {
    let paths = Object.assign({}, userPaths);
    paths[activePath].routePoints[navIndex].fields = fields;
    userDataContext.setUserPaths(paths);
  };

  const getActivePathEditorTab = (tab) => {
    userDataContext.setActivePathCreatorTab(tab);
  };

  const setActiveBreak = (breakIndex) => {
    userDataContext.setActiveBreak(breakIndex);
  };

  const setActivePathway = (navIndex) => {
    userDataContext.setActivePathway(navIndex);
  };

  const getNumBreaks = (pathId) => {
    if (userPaths[pathId].nodes) {
      return userPaths[pathId].nodes.length;
    }
    return 0;
  };

  const getNumPathways = (pathId) => {
    if (userPaths[pathId].routePoints) {
      return userPaths[pathId].routePoints.length;
    }
    return 0;
  };

  if (confirmPathDeletion) {
    return (
      <div className="pathcreator-container">
        <h3 className="change-font">
          {" "}
          Are you sure you want to delete your path?{" "}
        </h3>

        <div className="confirm-action-buttons">
          <div onClick={() => setConfirmPathDeletion(false)}>Cancel</div>
          <div onClick={deletePath}>Delete</div>
        </div>
      </div>
    );
  } else if (backConfirmation || confirmPathSave) {
    return (
      <div className="pathcreator-container">
        <h3 className=""> Do you want to save your changes to your path?</h3>

        <div className="confirm-action-buttons">
          <div onClick={handleSave}>Yes</div>
          <div onClick={handleBack}>No</div>
        </div>
      </div>
    );
  } else if (activePath) {
    const path = userPaths[activePath];
    console.log("PATH: ", path, activePathway);
    console.log("PATHWAY: ", path.routePoints);

    return (
      <div className="pathcreator-container">
        <div className="pathcreator-header">

			{
				mobile ? (
					<div className="path-header-action-button" onClick={() => userDataContext.setPBPaneView(null)}>
						<i style={{ fontSize: 28 }} className="material-icons">expand_more</i>
					</div>
				) : (
				<div className="path-header-action-button" onClick={handleBack}>
					<i className="material-icons back-icon">arrow_back_ios</i>
				</div>
				)
			}

          <input
            className="path-name-input"
            type="text"
            placeholder="Enter Path Name"
            value={path.pathName}
            onChange={handlePathNameChange}
            maxLength={120}
          />
          
          <div className="path-header-action-button" onClick={handleSave}>
            <i className="material-icons">done</i>
          </div>

        </div>

        <div className="path-editor-container">
          <Tabs
            setCurrentTab={setCurrentTab}
            getActiveTab={getActivePathEditorTab}
          >
            <div label="Breaks" numContent={getNumBreaks(activePath)}>
              <div className="breaks-header">
                <MapSearchBar
                  {...props}
                  searchBarRef={searchBarRef}
                />
              </div>

              {activeBreak === null ? (
                <div className="breaks-container">
                  <BreakSelectors
                    breaks={path.nodes}
                    activePath={activePath}
                    setActiveBreak={(breakIndex) => setActiveBreak(breakIndex)}
                  />
                </div>
              ) : (
                <div className="break-editor-container">
                  <LocationEditor
                    activePath={activePath}
                    node={path.nodes[activeBreak]}
                    key={activeBreak}
                    fields={path.nodes[activeBreak].fields}
                    onFieldChange={onLocationEditorFieldChange}
                    searchBarRef={searchBarRef}
                  />
                </div>
              )}
            </div>

            <div label="Ways" numContent={getNumPathways(activePath)}>
              {activePathway === null ? (
                <div className="pathways-container">
                  <PathwaySelectors
                    pathways={path.routePoints}
                    activePath={activePath}
                    setActivePathway={(navIndex) => setActivePathway(navIndex)}
                  />
                </div>
              ) : (
                <div className="pathway-editor-container">
                  <NavigationEditor
                    activePath={activePath}
                    node={path.routePoints[activePathway]}
                    navIndex={activePathway}
                    waypoints={userDataContext.getMidpointsData(activePath,activePathway)}
                    onFieldChange={onNavEditorFieldChange}
                  />
                </div>
              )}
            </div>
            <div label="Info">
              <PathInfo activePath={activePath} />
            </div>
          </Tabs>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const BreakSelectors = (props) => {
  const { breaks, activePath, setActiveBreak } = props;

  const [breakIdToDelete, setBreakIdToDelete] = useState(null);
  const [loadingRearrange, setLoadingRearrange] = useState(false);

  const userDataContext = useContext(UserDataContext);

  const deleteNodeFromPath = (node) => {
    if (breakIdToDelete === null) {
      setBreakIdToDelete(node.breakIndex);
    } else {
      userDataContext.deleteNodeFromPath(activePath, node.breakIndex);
      setBreakIdToDelete(null);
    }
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    setLoadingRearrange(true);
    await userDataContext.rerouteOnBreakReorder(activePath, result.source.index, result.destination.index);
    setLoadingRearrange(false);
  };

  if(loadingRearrange) return (
    <div style={{ marginTop: 30, }}>
      <ClipLoader size={40} color={'white'} />
    </div>
  )
  else if (breaks.length > 0) {
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="break-selectors">
          {(provided) => (
            <div
              className="breaks-selector-menu"
              {...provided.droppableProps}
              ref={provided.innerRef}
              onDragOver={onDragOver}
            >
              {breaks.map((node, index) => {
                node.breakIndex = index;
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className="break-selector-container"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <div
                          className={`break-selector${breakIdToDelete == node.breakIndex ? " delete" : ""}`}
                          onClickCapture={
                            breakIdToDelete == node.breakIndex
                              ? () => {}
                              : () => setActiveBreak(node.breakIndex)
                          }
                        >
                          {!breakIdToDelete && (
                            <div id="drag-handle" {...provided.dragHandleProps}>
                              <i className="material-icons">drag_indicator</i>
                            </div>
                          )}

                          <h4
                            className={`break-selector-header${
                              breakIdToDelete == node.breakIndex
                                ? "-delete"
                                : ""
                            }`}
                          >
                            {breakIdToDelete == node.breakIndex
                              ? "Confirm Delete"
                              : node.fields.title || node.placeName}
                          </h4>
                          <div
                            className={`${
                              breakIdToDelete == node.breakIndex
                                ? "break-cancel-delete"
                                : "break-open-icon"
                            }`}
                            onClickCapture={
                              breakIdToDelete == node.breakIndex
                                ? () => setBreakIdToDelete(null)
                                : () => {}
                            }
                          >
                            <i className="material-icons">
                              {breakIdToDelete == node.breakIndex
                                ? "clear"
                                : "arrow_forward_ios"}
                            </i>
                          </div>
                        </div>
                        <div
                          className={`${
                            breakIdToDelete == node.breakIndex
                              ? "break-confirm-delete-icon"
                              : "break-delete-icon"
                          }`}
                          onClickCapture={() => deleteNodeFromPath(node)}
                        >
                          <i className="material-icons">
                            {breakIdToDelete == node.breakIndex
                              ? "done"
                              : "clear"}
                          </i>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  } else {
    return (
      <div className="no-content-note">
        <div className="no-content-note-header">
          It doesn't look like your path has any breaks yet!
        </div>
        <div className="no-content-note-subheader">
          Search for a location or click the map to add your first break!
        </div>
      </div>
    );
  }
};

const PathwaySelectors = (props) => {
  const { pathways, activePath, setActivePathway } = props;

  if (pathways.length > 0) {
    return (
      <div className="pathway-selector-menu">
        {pathways.map((pathway, key) => {
          pathway.index = key;
          return (
            <div className="pathway-selector-container" key={key}>
              <div
                className="pathway-selector"
                onClickCapture={() => setActivePathway(pathway.navIndex)}
              >
                <h4 className="pathway-selector-header">{pathway.label}</h4>
                <i
                  className="material-icons"
                  onClick={(e) => e.stopPropagation()}
                >
                  arrow_forward_ios
                </i>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="no-content-note">
        <div className="no-content-note-header">
          It doesn't look like your path has any ways yet!
        </div>
        <div className="no-content-note-subheader">
          You need 2 or more breaks before you can define your route
        </div>
      </div>
    );
  }
};

export default PathCreator;

import React, { useState, useContext } from "react";
import '../styles/ProfileEditor.scss';

const PassportOverview = (props) => {

    const {
        passport,
        isCurrentUser,
    } = props;

    const passportEmpty = !Object.values(passport).some(val => val.impactTravel && (val.value && (!Array.isArray(val.value) || val.value.length)));

    if(passportEmpty) {
        return isCurrentUser ? (
            <div className="empty-profile-content-message">
                You have no passport fields selected! 
                <br/>
                <label>Hit the pen to fill out your passport!</label>
            </div>
        ) : (
            <div className="empty-profile-content-message">
                No passport fields selected
            </div>
        )
    }
    else return (
        <div className="passport-overview">
            {
                Object.keys(passport).map((category, key) => {
                    if(passport[category].impactTravel && (passport[category].value && (!Array.isArray(passport[category].value) || passport[category].value.length))) {
                        return (
                            <div className="passport-overview-row" key={key}>
                                <b>
                                    {category == "birthday" ? "Age" : category[0].toUpperCase() + category.substring(1)}
                                </b>
                                <span>
                                    {
                                        passport[category].value.constructor === Array ? (
                                            passport[category].value.length == 1 ? (
                                                <span>{passport[category].value[0]}</span>
                                            ) : (
                                                passport[category].value.map((val,i) => {
                                                    if(i < passport[category].value.length -1) val = val.concat(",");
                                                    return (
                                                        <span key={i}>{val}</span>
                                                    )
                                                })
                                            )
                                        ) : category == "birthday" ? (
                                            // Math.abs(new Date().getUTCFullYear() - passport[category].value.getUTCFullYear())
                                            Math.abs((new Date(Date.now() - passport[category].value.getTime())).getUTCFullYear() - 1970)
                                        ) : (
                                            passport[category].value
                                        )
                                    }
                                </span>
                            </div>
                        )
                    }
                })
            }
        </div>
    )

}

export default PassportOverview;
import React, { useContext, useState, useEffect } from "react";
import ProfileContext from "../contexts/ProfileContext";
import UserDataContext from "../contexts/UserDataContext";
import Tags from "./Tags";
import Dropdown from "./Dropdown";
import DatePicker from "react-date-picker";
import { passportOptions, passportQuestions } from "../resources/profileOptions";

import '../styles/ProfileEditor.scss';

const PassportEditor = (props) => {

    const userDataContext = useContext(UserDataContext);
    const profileContext = useContext(ProfileContext);

    const [languages, setLanguages] = useState([])
    const [nations, setNations] = useState([])
    const [sexualities, setSexualities] = useState([])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/languages/`)
            .then(res => res.json())
            .then(languageList => {
                setLanguages(languageList)
            })
            .catch(err => {
                console.error("Failed to load languages: ", err)
            });
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/nations/`)
            .then(res => res.json())
            .then(nationsList => {
                console.log("NATIONS:", nationsList)
                setNations(nationsList)
            })
            .catch(err => {
                console.error("Failed to load nations: ", err)
            });
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/sexualities/`)
            .then(res => res.json())
            .then(sexualitiesList => {
                setSexualities(sexualitiesList)
            })
            .catch(err => {
                console.error("Failed to load sexual orientations: ", err)
            });
    }, []);

    const handleSelectPassportField = (event) => {
        let category = event.currentTarget.attributes[1].value;
        let passport = Object.assign({}, profileContext.getPassport());
        passport[category].impactTravel = !passport[category].impactTravel;
        profileContext.setPassport(passport);
    }

    const passportSelectors = Object.values(passportQuestions).map((question, key) => {

        let selectedClass = ''
        if(profileContext.getPassport()[question.id].impactTravel) selectedClass = ' selected';

        return (
            <div className={`passport-field-selector${selectedClass}`} name={question.id} onClick={handleSelectPassportField}>
                <div className="passport-field-selector-info">

                    <div className="passport-selector-header">
                        {profileContext.getPassport()[question.id].impactTravel ?
                            <i className="material-icons">check_box</i> :
                            <i className="material-icons">check_box_outline_blank</i>
                        }
                        {question.header}
                        
                    </div>

                    {profileContext.getPassport()[question.id].impactTravel &&
                        <PassportField 
                            key={question.key} 
                            listOptions={
                                question.id == passportQuestions.LANGUAGE.id ? languages 
                                :
                                question.id == passportQuestions.NATIONALITY.id ? nations
                                :
                                question.id == passportQuestions.SEXUALITY.id ? sexualities
                                :
                                question.id == passportQuestions.ETHNICITY.id ? passportOptions.ethnicity
                                :
                                question.id == passportQuestions.RELIGION.id ? passportOptions.religion
                                :
                                question.id == passportQuestions.MOBILITY.id ? passportOptions.mobility
                                :
                                question.id == passportQuestions.GENDER.id ? passportOptions.gender
                                :
                                null
                            }
                            type={question.id} 
                            placeholder={question.placeholder || ''} 
                            subheader={question.subheader || ''}
                            value={profileContext.getPassport()[question.id].value}
                        />
                    }
                </div>
            </div>
        )
    });

    return (
        <div className="passport-container">
            {passportSelectors}
        </div>
    )
}

const PassportField = ({ type, placeholder, subheader, key, value, listOptions }) => {

    const profileContext = useContext(ProfileContext)

    const handleSetPassportValue = (category, event) => {
        let passport = Object.assign({}, profileContext.getPassport());
        let { value, maxLength } = event.target;
        if(value.length <= maxLength) passport[category].value = value;
        profileContext.setPassport(passport);
    }

    const handleSetBirthdayValue = (date) => {
        let passport = Object.assign({}, profileContext.getPassport())
        passport.birthday.value = date;
        profileContext.setPassport(passport);
    }

    switch(type) {
        case passportQuestions.BIRTHDAY.id:
            const maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() - 18);
            return (
                <div className="passport-field-container">
                    <p style={{ fontSize: 11, marginBottom: '20px', textAlign: 'left', marginLeft: '10px'}}>{subheader}</p>
                    <div className="passport-field" onClick={(e) => e.stopPropagation()}>
                        <DatePicker
                            onChange={(date) => handleSetBirthdayValue(date)}
                            value={profileContext.getPassport().birthday.value}
                            maxDate={maxDate}
                        />
                    </div>
                </div>
            );
        case passportQuestions.HOMETOWN.id:
            return (
                <div className="passport-field-container" >
                    <p style={{ fontSize: 11, marginBottom: '20px', textAlign: 'left', marginLeft: '10px'}}>{subheader}</p>
                    <div className="passport-field" >
                        <textarea
                            key={key}
                            onClick={(e) => e.stopPropagation()}
                            type="text"
                            defaultValue={value}// profileContext.getPassport()[type].value}
                            onChange={(event) => handleSetPassportValue(type, event)}
                            placeholder={placeholder}
                            maxLength={100}
                        />
                    </div>
                </div>
            );
        case passportQuestions.DIET.id:
            return (
                <div className="passport-field-container" >
                    <p style={{ fontSize: 11, marginBottom: '20px', textAlign: 'left', marginLeft: '10px'}}>{subheader}</p>
                    <div className="passport-field" >
                        <textarea
                            key={key}
                            onClick={(e) => e.stopPropagation()}
                            type="text"
                            defaultValue={value}// profileContext.getPassport()[type].value}
                            onChange={(event) => handleSetPassportValue(type, event)}
                            placeholder={placeholder}
                            maxLength={300}
                        />
                    </div>
                </div>
            );
        case passportQuestions.MEDICAL.id:
        case passportQuestions.IMPAIRMENT.id:
        case passportQuestions.NEURO.id:
        case passportQuestions.MENTAL.id:
            return (
                <div className="passport-field-container" >
                    <div className="passport-field" >
                        <textarea
                            key={key}
                            onClick={(e) => e.stopPropagation()}
                            type="text"
                            defaultValue={value}// profileContext.getPassport()[type].value}
                            onChange={(event) => handleSetPassportValue(type, event)}
                            placeholder={placeholder}
                            maxLength={200}
                        />
                    </div>
                </div>
            );
        case passportQuestions.LANGUAGE.id:
        case passportQuestions.NATIONALITY.id:
        case passportQuestions.SEXUALITY.id:
        case passportQuestions.GENDER.id:
        case passportQuestions.ETHNICITY.id:
        case passportQuestions.RELIGION.id:
        case passportQuestions.MOBILITY.id:
            return (
                <div className="passport-field-container" >
                    {subheader ? <p style={{ fontSize: 11, marginBottom: '20px', textAlign: 'left', marginLeft: '10px'}}>{subheader}</p> : null}
                    <div className="passport-field" onClick={(e) => e.stopPropagation()}>
                        <Dropdown 
                            options={listOptions}
                            multiSelect={true}
                            selectAll={false}
                            placeholder={placeholder}
                            selectedOptions={profileContext.getPassportValue(type)}
                            updateSelected={(selected) => profileContext.updatePassportValue(type, selected)}
                        />
                    </div>
                </div>
            )
    }
}

export default PassportEditor;
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import ImageUpload from './ImageUpload';
import IconButton from './IconButton';
import IconDropdown from './IconDropdown';
import UserDataContext from '../contexts/UserDataContext';
import AuthContext from '../contexts/AuthContext';
import '../styles/PathCreator.scss';
import { useBreakpoints } from "react-breakpoints-hook";

import bikeActive from '../images/movement-icons/bike-active.png';
import bikeInactive from'../images/movement-icons/bike-inactive.png';
import boatActive from '../images/movement-icons/boat-active.png';
import boatInactive from '../images/movement-icons/boat-inactive.png';
import busActive from '../images/movement-icons/bus-active.png';
import busInactive from '../images/movement-icons/bus-inactive.png';
import camperActive from '../images/movement-icons/camper-active.png';
import camperInactive from '../images/movement-icons/camper-inactive.png';
import carActive from '../images/movement-icons/car-active.png';
import carInactive from '../images/movement-icons/car-inactive.png';
import horsebackActive from '../images/movement-icons/horseback-active.png';
import horsebackInactive from '../images/movement-icons/horseback-inactive.png';
import metroActive from '../images/movement-icons/metro-active.png';
import metroInactive from '../images/movement-icons/metro-inactive.png';
import motorbikeActive from '../images/movement-icons/motorbike-active.png';
import motorbikeInactive from '../images/movement-icons/motorbike-inactive.png';
import planeActive from '../images/movement-icons/plane-active.png';
import planeInactive from '../images/movement-icons/plane-inactive.png';
import sailboatActive from '../images/movement-icons/sailboat-active.png';
import sailboatInactive from '../images/movement-icons/sailboat-inactive.png';
import trainActive from '../images/movement-icons/train-active.png';
import trainInactive from '../images/movement-icons/train-inactive.png';
import walkActive from '../images/movement-icons/walk-active.png';
import walkInactive from '../images/movement-icons/walk-inactive.png';
import taxiActive from '../images/movement-icons/taxi-active.png';
import taxiInactive from '../images/movement-icons/taxi-inactive.png';
import AccordionNew from './AccordionNew';
import pathUtils from '../utils/pathUtils';

const movementIcons = {
    car: { active: carActive, inactive: carInactive },
    walk: { active: walkActive, inactive: walkInactive },
    plane: { active: planeActive, inactive: planeInactive },
    train: { active: trainActive, inactive: trainInactive },
    bike: { active: bikeActive, inactive: bikeInactive },
    metro: { active: metroActive, inactive: metroInactive },
    bus: { active: busActive, inactive: busInactive },
    motorbike: { active: motorbikeActive, inactive: motorbikeInactive },
    camper: { active: camperActive, inactive: camperInactive },
    boat: { active: boatActive, inactive: boatInactive },
    sailboat: { active: sailboatActive, inactive: sailboatInactive },
    horseback: { active: horsebackActive, inactive: horsebackInactive },
    taxi: { active: taxiActive, inactive: taxiInactive }
}

const transportIcons = {
    car: carInactive,
    walk: walkInactive,
    plane: planeInactive,
    train: trainInactive,
    bike: bikeInactive,
    metro:  metroInactive,
    bus: busInactive,
    motorbike: motorbikeInactive,
    camper: camperInactive,
    boat: boatInactive,
    sailboat: sailboatInactive,
    horseback: horsebackInactive,
    taxi: taxiInactive
}

const transportTypePriority = {
    plane: 0,
    sailboat: 0,
    train: 1,
    car: 2,
    metro: 2,
    bus: 2,
    motorbike: 2,
    camper: 2,
    boat: 3,
    walk: 3,
    horseback: 3,
    bike: 4
}

const NavigationEditor = (props) => {

    console.log("NAV PROPS: ", props)

    const VIEWS = {
        MAIN: 'main',
        MODE: 'mode-of-transport',
        WAYPOINTS: 'waypoints',
        MEDIA: 'media'
    }

    const userDataContext = useContext(UserDataContext);
    const authContext = useContext(AuthContext);

    const [activeNavType, setActiveNavType] = useState('driving');
    const [fields, setFields] = useState(props.node.fields);

    const captionTextareaRef = useRef(null);

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    const onFieldChange = (e) => {
        let newFields = fields;
        let { name, value, maxLength } = e.target;
        newFields[name] = value.slice(0, maxLength);
        setFields(newFields);
        props.onFieldChange(props.node.navIndex, newFields);
    }

    const updateImageList = (imageArray, node, completeCallback) => {
        let newFields = fields;
        newFields.images = imageArray;
        setFields(newFields);
        props.onFieldChange(props.node.navIndex, newFields);
        completeCallback();
    }

    const onClickMovementIcon = async (type) => {
        let newFields = Object.assign({}, fields);
        Object.entries(newFields.transportTypes).forEach(([transportType, value], key) => {
            if(value) newFields.transportTypes[transportType] = false;
        })
        newFields.transportTypes[type] = true;
        setFields(newFields);

        if(getRouteTypeByTransportType(type) !== activeNavType) {
            rerouteOnTransportTypeChange(getRouteTypeByTransportType(type));
        }
    }

    // * take directions api routing type, and reroute for this specific pathway
    const rerouteOnTransportTypeChange = async (routeType) => {

        if(routeType) {

            setActiveNavType(routeType);
            userDataContext.updateNavType(props.activePath, props.navIndex, routeType);

            let routePoints = userDataContext.getRoutePoints(props.activePath);
            let coordinates = [];

            if(routePoints[props.navIndex].midpoints.length) {
                if (routeType == 'direct') {
                    coordinates.push(props.node.startCoords);
                    Object.keys(routePoints[props.navIndex].midpoints).forEach(midpoint => coordinates.push(routePoints[props.navIndex].midpoints[midpoint].coordinates));
                    coordinates.push(props.node.endCoords)
                    console.log("FLIGHT REROUTE: ", coordinates);
                } else {
                    userDataContext.rerouteThroughMidpoints(routePoints[props.navIndex].midpoints, props.activePath, props.navIndex, routeType)
                    return;
                }

            } else {
                if (routeType == 'direct') {
                    coordinates = [props.node.startCoords, props.node.endCoords];
                } else {
                    let route = await pathUtils.getRoute([props.node.startCoords, props.node.endCoords], routeType);
                    coordinates = route.coordinates;
                }
            }



            routePoints[props.navIndex].coordinates = coordinates;
            userDataContext.updateRoutePoints(props.activePath, routePoints);
            userDataContext.mapNewRoute(props.activePath);

        }
    }

    // * return the directions api type to call for each transport type
    const getRouteTypeByTransportType = (transportType) => {

        if(['plane', 'sailboat'].includes(transportType)) {
            return 'direct';
        } else if(['train', 'bike'].includes(transportType)) {
            return 'cycling';
        } else if(['car', 'taxi', 'metro', 'bus', 'motorbike', 'camper'].includes(transportType)) {
            return 'driving'
        } else if(['walk', 'horseback', 'boat'].includes(transportType)) {
            return 'walking'
        } else {
            return undefined;
        }
    }

    // * get start and end node in which this midpoint is between
    const deleteMidpointFromNav = async (waypointIndex) => {

        let { waypoints } = props;
        waypoints.splice(waypointIndex, 1);
        userDataContext.rerouteThroughMidpoints(waypoints, props.activePath, props.navIndex, activeNavType);
        userDataContext.deleteWaypointFromMap(props.activePath, props.navIndex, waypointIndex);

	}

    const rearrangeWaypoints = (dragNode, dropNode) => {

        // TODO: FIX THIS -- NOT WORKING

        if(dragNode !== dropNode) {
            let paths = Object.assign({}, userDataContext.getUserPaths()); // ! NEW CODE - FROM REFACTOR
            console.log("USER PATHS: ", paths, props)
            let waypointSrc = userDataContext.mapRef.current.getSource(`${props.activePath}-${props.navIndex}-waypoints`);
            let waypoints = waypointSrc._data.features;

            // * remove dragNode from midpoints and insert before
            // * if moving from left to right, will need to insert at dropNode - 1, so set shift
            let shift = 0;
            if (dragNode < dropNode && (dropNode - dragNode) > 1) {
                shift = 1;
                waypoints.forEach(waypoint => {
                    if(waypoint.waypointIndex == dragNode) waypoint.waypointIndex = dropNode - 1;
                    else if (waypoint.waypointIndex > dragNode && waypoint.waypointIndex < dropNode) waypoint.waypointIndex--;
                })
            } else if (dropNode < dragNode) {
                waypoints.forEach(waypoint => {
                    if(waypoint.waypointIndex == dragNode) waypoint.waypointIndex = dropNode;
                    else if (waypoint.waypointIndex >= dropNode && waypoint.waypointIndex < dragNode - 1) waypoint.waypointIndex++;
                })
            }
            waypointSrc.setData({
                "type": "FeatureCollection",
                "features": waypoints
            });
            let [ drag ] = paths[props.activePath].routePoints[props.navIndex].midpoints.splice(dragNode, 1);
            paths[props.activePath].routePoints[props.navIndex].midpoints.splice(dropNode - shift, 0, drag);
            userDataContext.setUserPaths(paths);
            userDataContext.rerouteThroughMidpoints(paths[props.activePath].routePoints[props.navIndex].midpoints, props.activePath, props.navIndex, activeNavType);
        }
    }
 
    return (
        <Fragment>
            <div className="editor-header">
                <i className="material-icons" onClick={() => userDataContext.setActivePathway(null)}>arrow_back_ios</i>
                <h4>{props.node.label}</h4>
            </div>
            <div className="nav-editor">

                <div className="editor-field" style={{ position: 'relative' }}>
                    <div className="editor-field-header">
                        Mode of Transport
                    </div>
                    <IconDropdown 
                        icons={transportIcons}
                        currentActive={Object.keys(fields.transportTypes).find((type, index) => fields.transportTypes[type] == true)}
                        clickIconHandler={onClickMovementIcon}
                        optionsView={mobile ? 'horizontal-scroll' : 'grid'}
                    />
                </div>

                <div className="editor-field column">
                    {/* <div className="nav-editor-caption"> */}
                        <textarea 
                            ref={captionTextareaRef}
                            placeholder="Tell us about your journey..." 
                            value={props.node.fields.description} 
                            name="description" 
                            onChange={onFieldChange} 
                            autoComplete="off"
                            maxLength={2200}
                            onInput={() => {
                                captionTextareaRef.current.style.height = "";
                                captionTextareaRef.current.style.height = captionTextareaRef.current.scrollHeight + "px";
                            }}
                        />
                    {/* </div> */}
                
                    <div className="location-editor-media editor-field-content">
                        <ImageUpload
                            node={props.node}
                            pathId={props.activePath}
                            updateImageList={updateImageList}
                            maxImages={5}
                            imageList={(props.node.fields && props.node.fields.images) ? props.node.fields.images : []}
                            uniqueId={"nav-" + props.navIndex}
                        />
                    </div>
                </div>

                <div className="editor-field column">
                    <div className="editor-field-header">
                        Waypoints
                    </div>
                        
                    {props.waypoints && props.waypoints.length ? (
                        <div className="nav-editor-intermediate-stops">
                            {props.waypoints.map((midpoint, key) => {
                                return (
                                    <span key={key}>
                                        <div className="waypoint-info-container">
                                            <i className="material-icons">gps_fixed</i>
                                            <label key={key}>{midpoint.address}</label>
                                        </div>
                                        <div className="delete-btn" onClick={() => deleteMidpointFromNav(key)}>
                                            <i className="material-icons">close</i>
                                        </div>
                                    </span>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="nav-editor-intermediate-stops">
                            <h6 className="no-content-note-subheader"> Click map or use search bar to reroute navigation through a location</h6>
                        </div>
                    )}
                </div>
                
            </div>
        </Fragment>
    );
}

export default NavigationEditor;

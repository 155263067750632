import React, { useState, useContext } from "react";
import DetailsEditor from './DetailsEditor';
import MomentsEditor from './MomentsEditor';
import TagsEditor from "./TagsEditor";
import DatePicker from "react-date-picker";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Checkbox } from 'semantic-ui-react'
import UserDataContext from '../contexts/UserDataContext';
import '../styles/PathCreator.scss';
import { Fragment } from "react";

const PathInfo = (props) => {

    const { activePath } = props;

    const userDataContext = useContext(UserDataContext);

    const [startDate, setStartDate] = useState(userDataContext.getUserPaths()[activePath].startDate || null);
    const [endDate, setEndDate] = useState(userDataContext.getUserPaths()[activePath].endDate ||null);

    const saveStartDate = (date) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].startDate = date;
        setStartDate(date);
        userDataContext.setUserPaths(paths);
    }

    const saveEndDate = (date) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].endDate = date;
        setEndDate(date);
        userDataContext.setUserPaths(paths);
    }

    const toggleOngoingTravel = (bool) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].ongoing = bool;
        userDataContext.setUserPaths(paths);
    }

    const toggleFutureTravel = (bool) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].future = bool;
        userDataContext.setUserPaths(paths);
    }

    const handleCaptionChange = (e) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        let { value, maxLength } = e.currentTarget;
        paths[activePath].caption = value.slice(0, maxLength);
        userDataContext.setUserPaths(paths);
    }

    const setDates = (dates) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].startDate = dates[0];
        setStartDate(dates[0]);
        setEndDate(dates[1]);
        paths[activePath].endDate = dates[1];
        userDataContext.setUserPaths(paths);
    }

    return (
        <div className="path-info-container">

            {/* <div className="path-info-header">
                Define your Path
            </div> */}

            <div className="path-caption-container">
                <h4>Caption</h4>
                <textarea 
                    placeholder="Give your path a caption..." 
                    name="caption" 
                    onChange={handleCaptionChange} 
                    value={userDataContext.getUserPaths()[activePath].caption ? userDataContext.getUserPaths()[activePath].caption : ''} 
                    autoComplete="off" 
                    maxLength={512}
                />
            </div>

            <div className="start-end-date-container">
                <h4>Dates</h4>
                {userDataContext.getUserPaths()[activePath].future ? null : (
                    <Fragment>
                        
                        {/* <div className="start-end-date-field end">
                            <div>End</div>
                            <DatePicker 
                                onChange={saveEndDate}
                                value={endDate}
                                minDate={startDate}
                            />
                        </div> */}

                        {userDataContext.getUserPaths()[activePath].ongoing ? (
                            <div className="start-end-date-field">
                                <div className="date-section-label">Start</div>
                                <DatePicker 
                                    onChange={saveStartDate}
                                    value={startDate}
                                    maxDate={endDate}
                                />
                            </div>
                        ) : (
                            <div className="start-end-date-field">
                                <DateRangePicker
                                    onChange={setDates}
                                    value={[startDate, endDate]}
                                    minDate={startDate}
                                    maxDate={endDate}
                                />
                            </div>
                            
                        )}

                        <div className="date-toggle-container">
                            Currently Traveling
                            <Checkbox
                                toggle
                                checked={userDataContext.getUserPaths()[activePath].ongoing}
                                onChange={(e, data) => toggleOngoingTravel(data.checked)}
                            />
                        </div>
                    </Fragment>
                )}
                <div className="date-toggle-container">
                    Future Travels?
                    <Checkbox
                        toggle
                        checked={userDataContext.getUserPaths()[activePath].future}
                        onChange={(e, data) => toggleFutureTravel(data.checked)}
                    />
                </div>
            </div>

           

            <DetailsEditor />

            <div className="path-tags-editor">
                <h4>Activities:</h4>
                <TagsEditor 
                    activePath={activePath}
                />
            </div>
            
            

        </div>
    )
}

export default PathInfo;
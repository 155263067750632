const checkUsernameExists = (username) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/username_check/${username}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => resolve(response))
            .catch(err => reject(err));
    })
}

const getUserById = (userId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${userId}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => resolve(response))
            .catch(err => reject(err));
    });
}

const updateUsername = (userId, username) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${userId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: username,
            })
        })
            .then(res => res.json())
            .then(savedUser => resolve(savedUser))
            .catch(err => reject(err))
    })
}

const deleteUserById = async (id) => {

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${id}`, {
            method: 'DELETE'
        })
            .then(res => res.json())
            .then(deletedUser => resolve(deletedUser))
            .catch(err => reject(err));
    })
}

const userApi = {
    checkUsernameExists,
    getUserById,
    updateUsername,
    deleteUserById,
}

export default userApi;


export const passportOptions = {
    ethnicity: [
        'White/Caucasian',
        'Hispanic/Latino',
        'Black/African Descent',
        'East Asian',
        'South Asian',
        'Middle Eastern',
        'Pacific Islander',
        'American Indian',
        'None of these terms best describe me (please specify in feedback survey)',
    ],
    religion: [
        'Agnostic',
        'Atheist',
        'Buddhist',
        'Catholic',
        'Christian',
        'Confucianism',
        'Hindu',
        'Jain',
        'Jewish',
        'Mormon',
        'Muslim',
        'Sikh',
        'Spiritual',
        'Taosim',
        'Zoroastrian',
        'None of these terms best describe me (please specify in feedback survey)',
    ],
    mobility: [
        'Wheelchair',
        'Crutches',
        'Walking Cane',
        'Walker',
        'Seeing Eye Dog',
        'Motorized Scooter',
        'Motorized Wheelchair',
        'White Cane',
    ],
    pets: [
        'Dog',
        'Cat',
        'Bird',
        'Snake',
        'Turtle',
        'Small furry creature',
    ],
    gender: [
        'Man',
        'Woman',
        'Non-binary',
        'Transgender',
        'Cisgender',
        'Questioning or unsure',
        'Agender',
        'Genderqueer or genderfluid',
        'Māhū',
        'Muxe',
        'Two-spirit',
        'Hirja',
        'Gender category/ identity not listed (please specify in feedback survey)',
    ],
    genderYesOrNoOptions: [
        {label: 'Yes', value: 'Yes'},
        {label: 'No', value: 'No'}
    ],
    children: [
        {label: 'Yes', value: 'Yes'},
        {label: 'No', value: 'No'}
    ],
}

export const passportQuestions = {
    BIRTHDAY: {
        key: 0,
        id: 'birthday',
        header: 'Age',
        subheader: 'What is your date of birth?',
    },
    HOMETOWN: {
        key: 1,
        id: 'hometown',
        header: 'Hometown', 
        placeholder: 'Where do you call home?',
        subheader: 'Where do you call home?',
    },
    NATIONALITY: {
        key: 2,
        id: 'nationality',
        header: 'National Identity',
        placeholder: 'Select all that apply',
        subheader: 'Which of the following countries’ national identities do you most closely identify with?'
    },
    LANGUAGE: {
        key: 3,
        id: 'language',
        header: 'Language(s)',
        placeholder: 'What language(s) do you speak?',
    },
    ETHNICITY: {
        key: 4,
        id: 'ethnicity',
        header: 'Ethnicity',
        placeholder: 'Select all that apply',
        subheader: 'Which of the following best describes you? Select all that apply.',
    },
    GENDER: {
        key: 5,
        id: 'gender',
        header: 'Gender Identity',
        placeholder: 'Select all that apply',
        subheader: 'What gender identity best describes you? Select all that apply.'
    },
    SEXUALITY: {
        key: 6,
        id: 'sexuality',
        header: 'Sexual Identity',
        placeholder: 'Select all that apply',
        subheader: 'What sexual identity best describes you? Select all that apply.'
    },
    RELIGION: {
        key: 7,
        id: 'religion',
        header: 'Religion',
        placeholder: 'Select all that apply',
        subheader: 'What religion do you identify with? Select all that apply.'
    },
    DIET: {
        key: 8,
        id: 'diet',
        header: 'Diet & Allergies',
        placeholder: 'What diet(s) impact your travel?',
        subheader: 'Do you follow a diet, have certain foods you cannot consume, or suffer from food allergies?'
    },
    MEDICAL: {
        key: 9,
        id: 'medical',
        header: 'Medical Condition',
        placeholder: 'What medical condition(s) impact how you travel?',
    },
    IMPAIRMENT: {
        key: 10,
        id: 'impairment',
        header: 'Speech or Sense Impairment',
        placeholder: 'What speech or sense impairment(s) impact your travel?',
    },
    NEURO: {
        key: 11,
        id: 'neurodiversity',
        header: 'Neurodiversity',
        placeholder: 'How does neurodiversity impact how you travel?',
    },
    MENTAL: {
        key: 12,
        id: 'mental',
        header: 'Mental Health',
        placeholder: 'How does mental health impact how you travel?',
    },
    MOBILITY: {
        key: 13,
        id: 'mobility',
        header: 'Mobility',
        subheader: "Do you need any of these to get around?",
        placeholder: "What is your mobility status?",
    },
}

export const interestLibTags = {
    culture: [
        "Theatre",
        "Concert",
        "Culinary",
        "Art Museum",
        "History Museum",
        "Science Museum",
        "Ballet",
        "Opera",
        "Art Gallery",
    ],
    nightlife: [
        "Bar",
        "Clubbing",
        "Festival",
        "Dancing",
        "Comedy Club",
        "Karaoke",
        "Pub",
        "Bar Hopping",
        "Casino",
    ],
    recreation: [
        "Backpacking",
        "Birdwatching",
        "Camping",
        "Cycling", 
        "Extreme Sports",
        "Fishing",
        "Golf", 
        "Hiking",
        "Horseback Riding",
        "Mountain Biking",
        "Rock Climbing",
        "Water Sports", 
        "Winter Sports",
        "Skating",
        "Photography / Film",
        "Gambling",
    ],

    wellness: [
        "Meditation",
        "Retreat",
        "Spa",
        "Yoga",
        "Pilates",
    ],
    nature: [
        "Garden",
        "Hiking",
        "National Parks",
        "Safari",
    ],
    sightseeing: [ 
        "Guided Tours",
        "Urban Explorer",
        "Landmarks",
        "Walking Tours",
        "Ghost Tours",
    ],
    shopping: [
        "Flea Market",
        "Luxury Store",
        "Mall",
        "Outdoor Market",
        "Thrift Store",
        "Vintage Store",
    ],
    fun: [
        "Escape Room",
        "Amusement Park",
        "Bowling",
        "Movie Theatre",
        "Playground",
        "Water Park",
    ],
    cuisine: [
        "Mexican",
        "Italian",
        "Thai",
        "Japanese",
        "Chinese",
        "Indian",
        "Spanish",
        "American",
        "Mediterranean",
        "Middle Eastern",
        "Scottish",
        "British",
        "Canadian",
        "Russian",
        "Polish",
        "German",
        "French",
        "Hawaiian",
        "Brazilian",
        "Peruvian",
        "Salvadorian",
        "Cuban",
        "Swedish",
        "Egyptian",
        "Greek",
        "Belgian",
        "Cajun",
        "Portuguese",
        "Turkish",
        "Kenyan",
        "Korean",
        "Algerian",
        "Nigerian",
        "Ethiopian"
    ]
}

export const interestFields = {
    RECREATION: {
        name: 'recreation',
        label: 'Recreation',
    },
    CULTURE: {
        name: 'culture',
        label: 'Culture',
    },
    WELLNESS: {
        name: 'wellness',
        label: 'Wellness',
    },
    NATURE: {
        name: 'nature',
        label: 'Nature',
    },
    SHOPPING: {
        name: 'shopping',
        label: 'Shopping', 
    },
    FUN: {
        name: 'fun',
        label: 'Fun',
    },
    SIGHTS: {
        name: 'sightseeing',
        label: 'Sightseeing',
    },
    NIGHTLIFE: {
        name: 'nightlife',
        label: 'Nightlife',
    }, 
    CUISINE: {
        name: 'cuisine',
        label: 'Cuisine'
    }
}

export const languageList = () => fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/languages/`).then(res => res.json())


// export default profileOptions;

import React, { Fragment, useContext, useState, useEffect, useRef, usePrevious } from 'react';
import ImageUpload from './ImageUpload';
import IconButton from './IconButton';
import IconDropdown from './IconDropdown';
import Tags from './Tags';
import UserDataContext from '../contexts/UserDataContext';
import AuthContext from '../contexts/AuthContext';
import AccordionNew from './AccordionNew';
import { useBreakpoints } from "react-breakpoints-hook";
import '../styles/PathCreator.scss';

import eatActive from '../images/location-icons/eat-active.png';
import eatInactive from '../images/location-icons/eat-inactive.png';
import exploreActive from '../images/location-icons/explore-active.png';
import exploreInactive from '../images/location-icons/explore-inactive.png';
import sleepActive from '../images/location-icons/sleep-active.png';
import sleepInactive from '../images/location-icons/sleep-inactive.png';

import cultureIcon from '../images/interest-category-icons/culture.png';
import funIcon from '../images/interest-category-icons/fun.png';
import natureIcon from '../images/interest-category-icons/nature.png';
import nightlifeIcon from '../images/interest-category-icons/nightlife.png';
import recreationIcon from '../images/interest-category-icons/recreation.png';
import shoppingIcon from '../images/interest-category-icons/shopping.png';
import sightseeingIcon from '../images/interest-category-icons/sightseeing.png';
import styleIcon from '../images/interest-category-icons/style.png';
import wellnessIcon from '../images/interest-category-icons/wellness.png';
import workIcon from '../images/interest-category-icons/work.png';
import waterIcon from '../images/interest-category-icons/water.png';
import gymIcon from '../images/interest-category-icons/gym.png';
import drinksIcon from '../images/interest-category-icons/drinks.png';
import collectionIcon from "../images/map-icons/collection.png";
import addIcon from "../images/white_center_add_path.png";

const breakTypeIcons = {
    explore: exploreInactive,
    eat: eatInactive,
    sleep: sleepInactive,
    culture: cultureIcon,
    fun: funIcon,
    nature: natureIcon,
    nightlife: nightlifeIcon,
    recreation: recreationIcon,
    shopping: shoppingIcon,
    sightseeing: sightseeingIcon,
    style: styleIcon,
    wellness: wellnessIcon,
    work: workIcon,
    water: waterIcon,
    gym: gymIcon,
    drinks: drinksIcon,
}

const LocationEditor = (props) => {

    const VIEWS = {
        MAIN: 'main',
        SUBS: 'subbreaks',
    }

    const userDataContext = useContext(UserDataContext);
    const authContext = useContext(AuthContext);

    const [activeBreakType, setActiveBreakType] = useState(props.fields.breakType || "explore");
    const [fields, setFields] = useState(props.fields);
    const [activeView, setActiveView] = useState(VIEWS.MAIN)

    const breakTextareaRef = useRef(null);

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    useEffect(() => {
        if(activeView == VIEWS.MAIN) {
            let bounds = new userDataContext.mapboxglRef.LngLatBounds();
            bounds.extend([props.node.long, props.node.lat]);
            if(props.node.subbreaks && props.node.subbreaks.length) {
                props.node.subbreaks.forEach(sb => {
                    bounds.extend([sb.long, sb.lat]);
                })
            }
            userDataContext.mapRef.current.fitBounds(bounds, { padding: 100 });
        }
    }, [activeView])

    const updateImageList = (imageArray, node, completeCallback) => {
        let newFields = fields;
        newFields.images = imageArray;
        setFields(newFields);
        if(props.type == "subbreak") {
            let paths = Object.assign({}, userDataContext.getUserPaths());
            paths[props.activePath].nodes[node.breakIndex].subbreaks[node.subbreakIndex].fields = newFields;
            userDataContext.setUserPaths(paths);
        } else if (!props.type) {
            props.onFieldChange(node.pathId, node.breakIndex, newFields);
        } else {
            props.onFieldChange(node.pathId, node.index, newFields);
        }
        completeCallback();
    }

    const onFieldChange = (e) => {
        let newFields = fields;
        let { name, value, maxLength } = e.target;
        
        newFields[name] = value.slice(0, maxLength);
        setFields(newFields);

        if(props.type == "subbreak") {
            props.onFieldChange(props.node.breakIndex, props.node.subbreakIndex, newFields)
        } else if (!props.type) {
            props.onFieldChange(props.node.pathId, props.node.breakIndex, newFields);
        } else {
            props.onFieldChange(props.node.pathId, props.node.index, newFields);
        }
    }
    

    const onClickLocationIcon = async (type) => {

        setActiveBreakType(type);
        let newFields = fields;
        newFields.breakType = type;
        setFields(newFields);

        if(!props.type) {

            let paths = Object.assign({}, userDataContext.getUserPaths());
            paths[props.activePath].nodes[props.node.breakIndex].fields.breakType = type;
            userDataContext.setUserPaths(paths);

            if(!paths[props.activePath].nodes[props.node.breakIndex].subbreaks.length) {
                userDataContext.updateBreakIconOnMap(props.node, type);
            }

        } else if(props.type == 'subbreak') {
            // TODO: connect this to map and context
            userDataContext.updateSubbreakIcon(props.node, type);
            let paths = Object.assign({}, userDataContext.getUserPaths());
            paths[props.activePath].nodes[props.node.breakIndex].subbreaks[props.node.subbreakIndex].fields.breakType = type;
            userDataContext.setUserPaths(paths);
        }
    }

    const onClickLengthTag = (length) => {
        let  paths = Object.assign({}, userDataContext.getUserPaths());
        paths[props.activePath].nodes[props.node.breakIndex].fields.length = length;
        userDataContext.setUserPaths(paths);
    }

    const deleteSubBreak = (subbreak) => {
		userDataContext.deleteSubBreak(props.activePath, subbreak.breakIndex, subbreak.subbreakIndex)
	}

    const addLocalSpotHandler = () => {
        if (props.searchBarRef && props.searchBarRef.current) {
            props.searchBarRef.current.focus();
        }
    }
    

    return (
        <Fragment>
            <div className="editor-header">
                <i className="material-icons" onClick={() => userDataContext.setActiveBreak(null)}>arrow_back_ios</i>
                <h4>{props.node.placeName}</h4>
            </div>
            <div className="location-editor">

                <div className="editor-field column">
                    <div className="location-editor-title location-editor-field" style={{ position: 'relative' }}>
                        <input 
                            placeholder="Title your break..." 
                            name="title" 
                            value={(props.fields && props.fields.title) ? props.fields.title : ''} 
                            onChange={onFieldChange} 
                            autoComplete="off"
                            maxLength={250}
                        />
                        {
                            props.node.subbreaks && props.node.subbreaks.length ? (
                                <img src={collectionIcon} width={40} style={{ marginRight: 10 }}/>
                            ) : (
                                <IconDropdown 
                                    icons={breakTypeIcons}
                                    currentActive={props.fields.breakType}
                                    clickIconHandler={onClickLocationIcon}
                                    optionsView={mobile ? 'horizontal-scroll' : 'grid'}
                                    headerText={"Select Break Activity"}
                                />
                            )
                        }
                        
                    </div>
        
                    <div className="location-editor-caption location-editor-field">
                        <textarea
                            ref={breakTextareaRef}
                            placeholder="What was your experience here? Use local spots to give local recommendations near or at this break..." 
                            name="description" 
                            value={(props.fields && props.fields.description) ? props.fields.description : ''} 
                            onChange={onFieldChange} 
                            autoComplete="off"
                            maxLength={2200}
                            onInput={() => {
                                breakTextareaRef.current.style.height = "";
                                breakTextareaRef.current.style.height = breakTextareaRef.current.scrollHeight + "px";
                            }}
                            style={{
                                overflow: 'hidden'
                            }}
                        />
                    </div>

                    <div className="location-editor-media editor-field-content">
                        <ImageUpload
                            node={props.node}
                            pathId={props.activePath}
                            updateImageList={updateImageList}
                            maxImages={5}
                            imageList={(props.fields && props.fields.images) ? props.fields.images : []}
                            uniqueId={`${props.type || 'break'}-${props.activePath}-${props.node.breakIndex !== undefined ? props.node.breakIndex : props.node.index}${props.type == "subbreak" ? `-${props.node.subbreakIndex}` : ''}`}
                        />
                    </div>
                </div>

                <div className="editor-field column">
                    <div className="section-header">
                        Local Spots
                    </div>
                    <div style={{ padding: '1rem', width: '100%' }}>
                        <div className="add-local-spot-btn" onClick={addLocalSpotHandler}>
                            <img src={addIcon} />
                            Add Local Spot
                        </div>
                        {
                            props.node.subbreaks && props.node.subbreaks.length ? (
                                <AccordionNew
                                    allowDeleteRow={true}
                                    deleteRowHandler={deleteSubBreak}
                                    allowCustomClickHeader={true}
                                    clickHeaderHandler={(subbreak) => userDataContext.mapRef.current.setCenter([subbreak.long, subbreak.lat])}
                                    style='sub-break'
                                >
                                    {
                                        props.node.subbreaks.map((subbreak, key) => {
                                            subbreak.subbreakIndex = key;
                                            subbreak.breakIndex = props.node.breakIndex;
                                            return ( 
                                                <div key={key} label={subbreak.placeName} info={subbreak}>
                                                    <LocalSpotEditor
                                                        key={key}
                                                        index={key}
                                                        activePath={props.activePath}
                                                        break_={props.node}
                                                        subbreak={subbreak}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </AccordionNew>
                            ) : (
                                <div className="no-content-note">
                                    <div className="no-content-note-subheader">Use search bar or map click to add a local spot</div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const LocalSpotEditor = ({ index, activePath, break_, subbreak }) => {

    const userDataContext = useContext(UserDataContext);

    const localspotTextareaRef = useRef(null);

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    const onClickSubbreakLocationIcon = (type, subbreak) => {
        userDataContext.updateSubbreakIcon(subbreak, type);
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].nodes[break_.breakIndex].subbreaks[subbreak.subbreakIndex].fields.breakType = type;
        userDataContext.setUserPaths(paths);
    }

    const onSubbreakFieldChange = (event, index) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        let { value, maxLength } = event.target;
        paths[activePath].nodes[break_.breakIndex].subbreaks[index].fields.description = value.slice(0, maxLength);
        userDataContext.setUserPaths(paths);
    }

    const onUpdateSubbreakImages = (images, subbreak, completeCallback) => {
        let paths = Object.assign({}, userDataContext.getUserPaths());
        paths[activePath].nodes[break_.breakIndex].subbreaks[subbreak.subbreakIndex].images = images;
        userDataContext.setUserPaths(paths);
        completeCallback()
    }

    return (
        <div className="subbreak-editor">
            <div className="editor-field" style={{ position: 'relative' }}>
                <div>
                    Activity:
                </div>
                <div>
                    <IconDropdown 
                        icons={breakTypeIcons}
                        currentActive={subbreak.fields.breakType}
                        clickIconHandler={(type) => onClickSubbreakLocationIcon(type, subbreak)}
                        optionsView={mobile ? 'horizontal-scroll' : 'grid'}
                        headerText={"Select Local Spot Activity"}
                    />
                </div>
            </div>
            <div className="editor-field column">
                <textarea 
                    ref={localspotTextareaRef}
                    placeholder="What did you do here?" 
                    name="description" 
                    rows={5}
                    value={subbreak.fields.description} 
                    onChange={e => onSubbreakFieldChange(e, index)} 
                    autoComplete="off"
                    maxLength={2200}
                    onInput={() => {
                        localspotTextareaRef.current.style.height = "";
                        localspotTextareaRef.current.style.height = localspotTextareaRef.current.scrollHeight + "px";
                    }}
                    style={{
                        overflow: 'hidden'
                    }}
                />
                <div>
                    <ImageUpload
                        node={subbreak}
                        pathId={activePath}
                        updateImageList={onUpdateSubbreakImages}
                        maxImages={5}
                        imageList={subbreak.images ? subbreak.images : []}
                        uniqueId={`subbreak-${activePath}-${break_.breakIndex !== undefined ? break_.breakIndex : break_.index}-${subbreak.subbreakIndex}`}
                    />
                </div>
            </div>

        </div>
    )

}

export default LocationEditor;

import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import '../styles/Dropdown.scss'

const Dropdown = (props) => {

    const { options, selectedOptions, updateSelected, multiSelect, selectAll, placeholder } = props;

    const containerRef = useRef();
    
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    // const [selectedOptions, setSelectedOptions] = useState([]);
    const [currentMaxVisibleItems, setCurrentMaxVisibleItems] = useState(20);
    const [currentMaxVisibleSelected, setCurrentMaxVisibleSelected] = useState(20);

    /**
     * On click outside dropdown, close dropdown if open
     */
    useEffect(() => {
        const checkForOutsideClick = (e) => {
            if(open && containerRef.current && !containerRef.current.contains(e.target)) {
                setOpen(false)
            }
        }

        // * Add event listener to check if click outside dropdown
        document.addEventListener("mousedown", checkForOutsideClick)

        // * Remove event listener on clean up
        return () => {
            document.removeEventListener("mousedown", checkForOutsideClick)
        }
    }, [open])

    /**
     * Open dropdown if there is text in the search input, close if 
     */
    useEffect(() => {
        if(searchValue) {
            if(!open) setOpen(true);
        }
    }, [searchValue])

    /**
     * Handler for Infinite Scroll
     *  - Detects when dropdown list scroll reaches bottom and increases number of current visible items
     */
    const handleScroll = (e) => {
        const reachedBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;

        if(reachedBottom && currentMaxVisibleItems < options.length) {
            if(currentMaxVisibleItems + 20 > options.length) {
                setCurrentMaxVisibleItems(options.length);
            } else {
                setCurrentMaxVisibleItems(currentMaxVisibleItems + 20);
            }
        }
    }

    /**
     * Handler for Horizontal Infinite Scroll
     *  - Detects when dropdown list scroll reaches bottom and increases number of current visible items
     */
    const handleHorizontalScroll = (e) => {
        const reachedEnd = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth;

        if(reachedEnd && currentMaxVisibleSelected < options.length) {
            if(currentMaxVisibleSelected + 20 > options.length) {
                setCurrentMaxVisibleSelected(options.length);
            } else {
                setCurrentMaxVisibleSelected(currentMaxVisibleSelected + 20);
            }
        }
    }

    /**
     *  Handler for toggling whether an option is selected
     */
    const toggleSelectOption = (e, option) => {
        e.stopPropagation();

        let newSelectedOptions = [];
        if(multiSelect) {
            newSelectedOptions = [ ...selectedOptions ];
        }
        
        if(!selectedOptions.includes(option)) {
            newSelectedOptions.push(option);
        } else {
            newSelectedOptions.splice(selectedOptions.indexOf(option), 1);
        }
        setSearchValue('');
        updateSelected(newSelectedOptions);
    }

    /**
     *  Handler for toggling whether or not all options are selected
     */
    const selectAllHandler = (e) => {
        e.stopPropagation();
        if(selectedOptions.length !== options.length) {
            updateSelected(options);
        } else {
            updateSelected([])
        }
    }

    return (
        <div className={`dropdown-container${open ? ' open' : ''}`} ref={containerRef} onClick={() => setOpen(!open)}>

            <div className="dropdown-selected-items" onScroll={handleHorizontalScroll}>
                {selectedOptions.length ? (
                    // selectedOptions.slice(0, currentMaxVisibleSelected > selectedOptions.length ? selectedOptions.length : currentMaxVisibleSelected).map((option, key) => (
                        selectedOptions.map((option, key) => (
                        <label onClick={e => e.stopPropagation()}>
                            {option}
                            <i className="material-icons" onClick={(e) => toggleSelectOption(e, option)}>clear</i>
                        </label>
                    ))
                    ) : (
                    null
                )}
            </div>
            <div className="dropdown-header">
                <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={placeholder || 'Select items'}></input>
                <i className="material-icons">{open ? 'expand_less' : 'expand_more'}</i>
            </div>

            {open && (
                <div className="dropdown-options" onScroll={handleScroll}>
                    {options.length && selectAll ? (
                         <div className={`dropdown-option${selectedOptions.length === options.length ? ' selected' : ''}`} onClick={selectAllHandler}>
                            <i className="material-icons">{selectedOptions.length === options.length ? 'check_circle' : 'radio_button_unchecked'}</i>
                            <div className="dropdown-option-label">Select All</div>
                        </div>
                    ) : null}
                    {options.filter(option => option.toLowerCase().includes(searchValue.toLowerCase())).slice(0, currentMaxVisibleItems).map((option, key) => (
                        <div className={`dropdown-option${selectedOptions.includes(option) ? ' selected' : ''}`} key={key} value={option} onClick={(e) => toggleSelectOption(e, option)}>
                            <i className="material-icons">{selectedOptions.includes(option) ? 'check_circle' : 'radio_button_unchecked'}</i>
                            <div className="dropdown-option-label">{option}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

// Dropdown.propTypes = {
//     options: PropTypes.array.isRequired,
// }

export default Dropdown;
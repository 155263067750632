export const isDeviceiOSMobile = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) ? true : false;
}

export const generateCloudflareImageUrl = async (file) => {

    return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append('file', file, file.name);

        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/imageupload/`, {
            method: "POST",
            body: data,
        })
            .then(res => res.json())
            .then(result => resolve(result))
            .catch(err => reject(err));
            
    });
}
import React, { useState, useContext } from "react";
import { Message } from "semantic-ui-react";

import ProfileContext from '../contexts/ProfileContext';
import AddPath from '../images/white_center_add_path.png';
import AddPathBlueCenter from '../images/add_path_color.png';

import '../styles/Tags.scss';

const Tags = (props) => {

    const { 
        tagList,
        selectedTags,
        updateSelectedTags,
        type,
        maxNew,
        multi,
        allowNewTags,
        newTagMaxLength,
        colorAddIcon,
    } = props;

    const profileContext = useContext(ProfileContext);

    // const [newTagInput, setNewTagInput] = useState(false);
    const [newTagInput, setNewTagInput] = useState('');
    const [inputActive, setInputActive] = useState(false);
    const [numNewTags, setNumNewTags] = useState(0);

    const [warning, setWarning] = useState('');

    const handleTagClick = (interest, active) => {

        if(selectedTags.length > 20) {
            setWarning("Maximum number of tags selected");
            return;
        }

        let newSelected = selectedTags.concat([]);

        // * check if these tags allow for multiple selected
        if(!multi) {
            
            newSelected = [ interest ];
            updateSelectedTags(type, newSelected);
        } else {
            if(active) {
                // * set this tag to selected
                // add to selectedTags for this type
                newSelected.push(interest);
                updateSelectedTags(type, newSelected);
            } else {
                // * set this tag to deselected
                // filter interest out of selectedTags for this type
                newSelected = selectedTags.filter(tag => tag !== interest);
                updateSelectedTags(type, newSelected);
            }
        }
        // TODO: SORT OUT THE IDs FOR THESE SO THEY CAN RECEIVE STYLING 
        // TODO: -- SET TO A VARIABLE IN STATE

    }


    const checkValidInterest = (interest) => {

        if(tagList.includes(interest)) return false;
        else return true;
    }

    const handleTagInputChange = (event) => {
        let { value, maxLength } = event.target;
        if(maxLength) {
            setNewTagInput(value.slice(0, maxLength));
        } else {
            setNewTagInput(value);
        }
    }

    const handleNewTagEnter = (event) => {

        if(newTagInput.trim().length !== 0 && event.key == "Enter") {
            console.log("ENTER: ", newTagInput.trim())

            let newTag = newTagInput.trim().toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');

            if(checkValidInterest(newTag)) {
                let newTags = selectedTags;
                newTags.push(newTag);
                updateSelectedTags(type, newTags);

                setNewTagInput('');
                setInputActive(false);
                setWarning('');
                setNumNewTags(numNewTags+1);
            } else {
                setWarning("Duplicate tag");
            }
        }
    }

    const handleClickAddNew = () => {
        setInputActive(true);
    }

    window.addEventListener('click', (evt) => {
        if (evt.target.id != "new-icon-listen-" + type && evt.target.id != "input-box-listen-" + type) {
            setNewTagInput('');
            setInputActive(false);
        }
    });

    let allTags = [...tagList, ...selectedTags.filter(selected => { return selected && !tagList.includes(selected)})];

    return (

        <div className="tags">

            {
                warning ?
                    <Message
                        negative
                        content={warning}
                        onDismiss={() => setWarning('')}
                    />
                :
                    null
            }

            {
                allTags.map((interest, i) => (
                    <button 
                        key={i}
                        id={interest}
                        name={interest}
                        onClick={(event) => handleTagClick(interest, (!selectedTags.includes(interest)))}
                        className={selectedTags.includes(interest) ? "interestTag " + type + "-selected" : "interestTag " + type}

                    >
                        {interest}
                    </button>
                ))
            }

            {
                !allowNewTags || numNewTags >= maxNew || selectedTags.length > 20 ?
                    null
                :
                inputActive ? (
                    <input 
                        id={"input-box-listen-" + type} 
                        className={"newtag-input " + type} 
                        onChange={handleTagInputChange} 
                        onKeyDown={handleNewTagEnter} 
                        placeholder="New Tag"
                        maxLength={newTagMaxLength}
                    />
                ) : (
                    <button id={"add-path-listen-" + type} className="add-path-button" onClick={() => handleClickAddNew()}>
                        <img className="new-icon" id={"new-icon-listen-" + type} src={colorAddIcon ? AddPathBlueCenter : AddPath} />
                    </button> 
                )
            }
        </div>
    )
}

export default Tags;
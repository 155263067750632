import React, { useContext, useState } from "react";
import ProfileContext from "../contexts/ProfileContext";
import Tags from "./Tags";

import { interestFields, interestLibTags } from "../resources/profileOptions";

import '../styles/ProfileEditor.scss';

const InterestLibraryEditor = (props) => {

    const { inProfileModal } = props;

    const profileContext = useContext(ProfileContext)

    const [ activeSection, setActiveSection ] = useState(null);

    return (
        <div className="interest-lib-container">
            {Object.values(interestFields).map((interest, key) => {
                return (
                    <div className="interest-lib-section-container">
                        <div className="interest-lib-section-header" onClick={() => activeSection == interest.name ? setActiveSection(null) : setActiveSection(interest.name)}>
                            {interest.label}
                            {profileContext.getUserInterestsByCategory(interest.name).length ? (
                                    <label>{profileContext.getUserInterestsByCategory(interest.name).length}</label>
                                ) : (
                                    null
                                )
                            }
                            <i className="material-icons">{`expand_${activeSection == interest.name ? 'less' : 'more'}`}</i>
                        </div>
                        <div className="interest-lib-section-content" style={{ display: activeSection == interest.name ? 'block' : 'none'}}>
                            <Tags
                                tagList={interestLibTags[interest.name]}
                                selectedTags={profileContext.getUserInterestsByCategory(interest.name)}
                                updateSelectedTags={(category, selected) => profileContext.updateUserInterestsByCategory(category, selected)}
                                type={interest.name}
                                maxNew={5}
                                multi={true}
                                allowNewTags={true}
                                newTagMaxLength={80}
                                colorAddIcon={inProfileModal ? true : false}
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    )

}

export default InterestLibraryEditor;
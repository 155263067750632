import React, { useState, useContext, useEffect } from "react";
import "../styles/UserViewer.scss";
import ScaleLoader from 'react-spinners/ScaleLoader';
import Tabs from "./Tabs";
import pathUtils from "../utils/pathUtils";
import PassportOverview from "./PassportOverview";
import profileUtils from "../utils/profileUtils";
import InterestsOverview from "./InterestsOverview";
import UserDataContext from "../contexts/UserDataContext";
import TravelerStats from "./TravelerStats";

const paneType = {
    EDITOR: "editor",
    VIEWER: "viewer",
    PROFILE: "profile", // current user profile editor
    PATH: "paths",
    USER: "user-screen", // shared user profile page
    SETTINGS: "settings",
    NONE: null,
};

const UserViewer = (props) => {

    const {
        user,
    } = props;

    const userDataContext = useContext(UserDataContext);

    const [formattedPaths, setFormattedPaths] = useState(null);

    useEffect(() => {
        if (user) {
            const formatPaths = async (paths) => {
                const formatted = await Promise.all(paths.map(path => pathUtils.formatPathFromDb(path)));
                setFormattedPaths(formatted);
            }
            formatPaths(user.paths);
        }
    }, [user]);

    const onClickPath = async (pathIndex) => {
        userDataContext.setActiveSharedPath(formattedPaths[pathIndex]);
    }

    const handleBack = () => {
        if (userDataContext.activeSharedPath) {
            userDataContext.setPBPaneView(paneType.VIEWER);
        } else {
            userDataContext.setPBPaneView(paneType.NONE);
        }
        userDataContext.setActiveSharedUser(null);
    }

    if (!user) {
        return <ScaleLoader color={'white'} />
    }
    return (
        <div className="user-viewer">
            
            <div className="user-viewer-header">
                <div className="action-btn btn" onClick={handleBack}>
                    <i className="material-icons">arrow_back</i>
                </div>
                <h2>{`@${user.username}`}</h2>
                <div className="action-btn" />
            </div>

            <div className="user-viewer-content-scroll">

                <img src={(user.profile_picture && user.profile_picture.image_url) ? user.profile_picture.image_url : 'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true'} />

                <div className="user-summary-container">

                    <h4>{user.name}</h4>

                    <h5>{user.bio}</h5>

                    <div style={{ width: '100%'}}>
                        <TravelerStats userId={user.id}/>
                    </div>
                </div>
                <div className="user-content-tabs-container">
                    <Tabs>
                        <div label="Paths" numContent={user.paths.length}>
                            <div>
                                {formattedPaths ? (
                                    formattedPaths.map((path, index) => (
                                        <div className="path-selector" key={index} onClick={() => onClickPath(index)}>
                                            <h5>{path.pathName}</h5>
                                            <label>{`${path.nodes.length} break${path.nodes.length === 1 ? '' : 's'}`}</label>
                                        </div>
                                    ))
                                ) : (
                                    <ScaleLoader color={'white'} />
                                )}
                            </div>
                        </div>
                        <div label="Profile">
                            <div>
                                <div className="user-viewer-profile-section">
                                    <label>Passport</label>
                                    <PassportOverview 
                                        passport={profileUtils.formatPassportFromDb(user.passport)}
                                        isCurrentUser={false}
                                    />
                                </div>
                                <div className="user-viewer-profile-section">
                                    <label>Interests</label>
                                    <InterestsOverview
                                        interests={profileUtils.formatInterestsFromDb(user.interests)}
                                        isCurrentUser={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default UserViewer;
import React, { useState, useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import ProfileContext from "../contexts/ProfileContext";
import userApi from "../utils/userApi";
import ClipLoader from 'react-spinners/ClipLoader';

const UsernamePrompt = (props) => {

    const profileContext = useContext(ProfileContext);
    const { userId } = useContext(AuthContext)

    const [username, setUsername] = useState('');
    const [usernameClash, setUsernameClash] = useState(false);
    const [whitespaceDetected, setWhitespaceDetected] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmitUsername = async () => {

        if (username) {
            setLoading(true);
            setUsernameClash(false);

            if (username.trim().indexOf(' ') >= 0) {
                setUsername('');
                setWhitespaceDetected(true);
                setLoading(false);
                return;
            }

            const usernameExists = await userApi.checkUsernameExists(username.trim());

            if (await usernameExists.exists) {
                setUsername('');
                setUsernameClash(true);
                setLoading(false);
                return;
            } else {
                userApi.updateUsername(userId, username.trim())
                    .then(res => {
                        profileContext.setUsername(username.trim());
                        setUsername('');
                        setLoading(false);
                    })
                    .catch(err => {
                        console.log("FAILED TO SAVE USERNAME: ", err);
                    })
            }

        }
    }

    return (
        <div>
            <span>Create a username</span>
            <input
                value={username}
                onChange={e => setUsername(e.target.value)}
                placeholder="Enter username"
                autoCapitalize="none"
            />
            {(usernameClash || whitespaceDetected) && (
                <label>{usernameClash ? 'username already exists' : whitespaceDetected ? 'no spaces allowed in username' : ''}</label>
            )}
            <button onClick={onSubmitUsername}>
                {loading ? (
                    <ClipLoader
                        color={'white'}
                        size={15}
                    />
                ) : (
                    <label>Save</label>
                )}
            </button>
        </div>
    )
}

export default UsernamePrompt;
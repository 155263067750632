import React, { useState, useContext, useEffect, } from "react";
import UserDataContext from '../contexts/UserDataContext';
import ProfileContext from '../contexts/ProfileContext';
import AuthContext from '../contexts/AuthContext';
import InterestLibraryEditor from "./InterestLibraryEditor";
import PassportEditor from "./PassportEditor";
import PassportOverview from "./PassportOverview";
import InterestsOverview from "./InterestsOverview";
import TravelerStats from "./TravelerStats";
import { toast, ToastContainer } from "react-toastify";
import '../styles/ProfileEditor.scss';
import '../styles/InterestGroupTags.css';
import { Fragment } from "react";
import { useRef } from "react";
import userApi from "../utils/userApi";
import { generateCloudflareImageUrl } from "../utils";
import ClipLoader from 'react-spinners/ClipLoader';

const VIEWS = {
    MAIN: 'main',
    PASSPORT: 'passport',
    INTERESTS: 'interest-lib',
}

const ProfileEditor = (props) => {

    const authContext = useContext(AuthContext)
    const profileContext = useContext(ProfileContext);
    const userDataContext = useContext(UserDataContext);
    const [profileDisplay, setProfileDisplay] = useState(VIEWS.MAIN)

    const uploadImageToCloudinary = async (imageFile) => {

        const data = new FormData();
        data.append("file", imageFile);
        data.append("upload_preset", "pb-media-upload-preset");
        data.append("cloud_name", "pathbreaker");

        // fetch(process.env.CLOUDINARY_UPLOAD_API_URL, {
        return fetch('https://api.cloudinary.com/v1_1/pathbreaker/image/upload', {
            method: "POST",
            body: data
        })
        .then(res => res.json())
        .then(parsed => parsed.secure_url)
        .catch(err => {
            console.error("FAILED TO UPLOAD IMAGE TO CLOUDINARY: ", err);
        })
    }

    const handleSave = async () => {

        const usernameExists = await userApi.checkUsernameExists(profileContext.username);

        if (await usernameExists.exists && await usernameExists.user_id !== authContext.userId) {
            console.log("USERNAME TAKEN")
            toast.error("Username is already taken, please try another.");
            return;
        } else {
            profileContext.saveToDB();
            userDataContext.setPBPaneView(null);
        }

    }

    let display = null;

    switch(profileDisplay) {
        case VIEWS.MAIN:
            display = (
                <Fragment>
                    <div className="profile-editor-header profile-main-header">
                        <div className="profile-header">
                            <div className="back">
                                {/* <i className="material-icons" onClick={() => userDataContext.setPBPaneView(null)}>arrow_back_ios</i> */}
                            </div>
                            <div className="profile-header-title">
                                Profile
                            </div>
                            <div className="profile-header-button" onClick={handleSave}>
                                <i className="material-icons">done</i>
                            </div>
                        </div>
                    </div>

                    <div className="profile-editor">

                        <UserDetailsOverview 
                            name={profileContext.name}
                            username={profileContext.username}
                            bio={profileContext.bio}
                            profilePicture={profileContext.profilePicture}
                        />

                        <div className="profile-sections">
                            <div className="profile-section">
                                <div className="profile-section-header">
                                    Traveler Stats:
                                </div>
                                <div className="profile-section-content">
                                    <TravelerStats userId={authContext.userId} />
                                </div>
                            </div>
                            
                            <div className="profile-section">
                                <div className="profile-section-header">
                                    Passport:
                                    <i className="material-icons" onClick={() => setProfileDisplay(VIEWS.PASSPORT)}>edit</i>
                                </div>
                                <PassportOverview
                                    passport={profileContext.passport}
                                    isCurrentUser={true}
                                />
                            </div>
                            
                            <div className="profile-section">
                                <div className="profile-section-header">Interests:</div>
                                <InterestsOverview
                                    interests={profileContext.interestLibrary}
                                    setProfileDisplay={setProfileDisplay}
                                    isCurrentUser={true}
                                />
                            </div>
                            
                        </div>

                    </div>
                    <ToastContainer
                        position="top-left"
                        autoClose={4000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{
                            position: "absolute",
                            left: 110,
                        }}
                    />
                </Fragment>
            );
            break;
        case VIEWS.PASSPORT:
            display = (
                <Fragment>
                    <div className="profile-editor-header">
                        <div className="profile-header">
                            <div className="profile-header-back-icon">
                                <i className="material-icons" onClick={() => setProfileDisplay(VIEWS.MAIN)}>arrow_back_ios</i>
                            </div>
                            <div className="profile-header-title">
                                Passport
                            </div>
                        </div>
                        <div className="profile-subheader">
                            {/* A space for you to highlight how your identity, lifestyle, and circumstance impact how you travel */}
                            Do any of the following categories impact how you travel? Don’t worry - no fields are mandatory!
                        </div>
                    </div>
                    <div className="profile-editor">
                        {/* <Passport /> */}
                        <PassportEditor />
                    </div>
                </Fragment>
            );
            break;
        case VIEWS.INTERESTS:
            display = (
                <Fragment>
                    <div className="profile-editor-header">
                        <div className="profile-header">
                            <div className="profile-header-back-icon">
                                <i className="material-icons" onClick={() => setProfileDisplay(VIEWS.MAIN)}>arrow_back_ios</i>
                            </div>
                            <div className="profile-header-title">
                            Interest Library
                            </div>
                        </div>
                        <div className="profile-subheader">
                        Tell us about the things you enjoy doing or aspire to do while traveling
                        </div>
                    </div>
                    <div className="profile-editor">
                        <InterestLibraryEditor inProfileModal={false} />
                    </div>
                </Fragment>
            );
            break;
    }


    return (
        <div className="profile-editor-container">
            {display}
        </div>
    );
}

const UserDetailsOverview = (props) => {

    const { name, username, bio, profilePicture } = props;

    const profileContext = useContext(ProfileContext);
    const authContext = useContext(AuthContext);
    
    const bioTextareaRef = useRef(null);

    const [imageLoading, setImageLoading] = useState(false);

    useEffect(() => {
        if(imageLoading) setImageLoading(false);
    }, [profileContext.profilePicture])

    const updateName = (e) => {
        let { value, maxLength } = e.target;
        profileContext.setName(value.slice(0, maxLength));
    }

    const updateUsername = (e) => {
        let { value, maxLength } = e.target;
        profileContext.setUsername(value.slice(0, maxLength));
    }

    const updateBio = (e) => {
        let { value, maxLength } = e.target;
        profileContext.setBio(value.slice(0, maxLength));
    }

    const changeProfilePicture = async (e) => {
        e.preventDefault();
        setImageLoading(true)
        const result = await generateCloudflareImageUrl(e.target.files[0])

        if (await result.success) {
            profileContext.setProfilePicture(await result.image_url);
        } else {
            setImageLoading(false);
            toast.error("Failed to upload image. Try again later")
        }
    }

    return (
        <div className="profile-user-details-container">
            <div className="profile-picture-container">
                <label htmlFor="profile-picture-upload" className="profile-picture fas">
                    <div className="img-wrap" >
                        {imageLoading ? (
                            <div className="loading-container">
                                <ClipLoader size={18} color={'#21585C'}/>
                            </div>
                        ) : (
                            <img htmlFor="profile-picture-upload" src={profilePicture}/>
                        )}
                        
                    </div>
                    <input id="profile-picture-upload" type="file" style={{display: 'none', visibility: 'none'}} onChange={changeProfilePicture}/> 
                </label>
            </div>
            <div className="profile-user-details">
                <div className="profile-user-view-container">
                    <div className="profile-username">
                        <label>Username:</label>
                        <input 
                            type="text" 
                            placeholder="Username" 
                            value={username} 
                            onChange={updateUsername}
                            maxLength={80}
                        />
                    </div>
                    <div className="profile-name">
                        <label>Name:</label>
                        <input 
                            type="text" 
                            placeholder="Name" 
                            value={name} 
                            onChange={updateName}
                            maxLength={100}
                        />
                    </div>
                    <div className="profile-bio">
                        <label>Bio:</label>
                        <textarea
                            ref={bioTextareaRef}
                            type="text"
                            rows="3"
                            // onInput={() => {
                            //     bioTextareaRef.current.style.height = "";
                            //     bioTextareaRef.current.style.height = bioTextareaRef.current.scrollHeight + "px";
                            // }}
                            placeholder="Add a bio to your profile"
                            value={bio}
                            onChange={updateBio}
                            maxLength={256}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileEditor;

const buildPathShareLink = (pathId) => {
    const webAppLink = `https%3A%2F%2Fpathbreaker%2Eapp%2Fpaths%2F${pathId}`;
    const deepLink = `https%3A%2F%2Fpathbreaker%2Epage%2Elink%2Fpaths%3Fpid%3D${pathId}`;
    const iosBundleId = 'com.pathbreaker.app';
    const iosAppStoreId = '1615717375';
    const link = `https://pathbreaker.page.link?link=${deepLink}&efr=1&ofl=${webAppLink}&ibi=${iosBundleId}&isi=${iosAppStoreId}`;
    
    return link;
}

const dynamicLinks = {
    buildPathShareLink
}

export default dynamicLinks;
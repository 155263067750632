import React, { useState, useContext, useEffect, } from "react";
import { toast } from "react-toastify";
import AuthContext from "../contexts/AuthContext";
import ProfileContext from "../contexts/ProfileContext";
import UserDataContext from "../contexts/UserDataContext";
import firebase from '../firebase';
import "../styles/Settings.scss";
import userApi from "../utils/userApi";

const menuItems = {
    MENU: 'menu',
    ACCOUNT_INFO: 'account-info',
    CHANGE_PASSWORD: 'change-password',
    DELETE_ACCOUNT: 'delete-account',
    TERMS: 'terms-conditions',
}

const Settings = (props) => {

    const userDataContext = useContext(UserDataContext);

    const [activeItem, setActiveItem] = useState(menuItems.MENU);

    const sectionData = [
        {
            title: 'Account',
            icon: 'account_circle',
            menuItems: [
                {
                    id: 'account-info',
                    label: 'Account Info',
                    onClick: () => {
                        setActiveItem(menuItems.ACCOUNT_INFO);
                    }
                },
                {
                    id: 'change-password',
                    label: 'Change Password',
                    onClick: () => {
                        setActiveItem(menuItems.CHANGE_PASSWORD);
                    }
                },
                {
                    id: 'delete-account',
                    label: 'Delete Account',
                    onClick: () => {
                        setActiveItem(menuItems.DELETE_ACCOUNT);
                    }
                }
            ]
        },
        {
            title: 'Help',
            icon: 'help_outline',
            menuItems: [
                {
                    id: 'terms-conditions',
                    label: 'Acceptable Use & Privacy Policy',
                    onClick: () => {
                        window.open('https://pathbreakerapp.com/terms-conditions/')
                    }
                }
            ]
        }
    ]

    const exitSettings = () => {
        userDataContext.setPBPaneView(null);
    }

    switch(activeItem) {
        case menuItems.MENU:
            return (
                <div className="settings-container">
                    
                    <div className="settings-header">
                        <div onClick={exitSettings}>
                            <i className="material-icons">arrow_back_ios</i>
                        </div>
                        <h2>Settings</h2>
                        <span />
                    </div>
        
                    <div className="settings-menu">
                        {sectionData.map((section, key) => (
                            <div className="settings-section" key={key}>
                                <div className="settings-section-header">
                                    <i className="material-icons">{section.icon}</i>
                                    <label>{section.title}</label>
                                </div>
                                {section.menuItems.map((item, id) => (
                                    <div className="settings-menu-item" key={id} onClick={item.onClick}>
                                        <label>{item.label}</label>
                                        <i className="material-icons">arrow_forward_ios</i>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            );
        case menuItems.DELETE_ACCOUNT:
            return (
                <div className="settings-container">
                    
                    <div className="settings-header">
                        <div onClick={() => setActiveItem(menuItems.MENU)}>
                            <i className="material-icons">arrow_back_ios</i>
                        </div>
                        <h2>Delete Account</h2>
                        <span />
                    </div>

                    <DeleteAccount />
                </div>
            )
        case menuItems.ACCOUNT_INFO:
            return (
                <div className="settings-container">
                    
                    <div className="settings-header">
                        <div onClick={() => setActiveItem(menuItems.MENU)}>
                            <i className="material-icons">arrow_back_ios</i>
                        </div>
                        <h2>Account Info</h2>
                        <span />
                    </div>

                    <AccountInfo />
                </div>
            )
        case menuItems.CHANGE_PASSWORD:
            return (
                <div className="settings-container">
                    
                    <div className="settings-header">
                        <div onClick={() => setActiveItem(menuItems.MENU)}>
                            <i className="material-icons">arrow_back_ios</i>
                        </div>
                        <h2>Recover Password</h2>
                        <span />
                    </div>

                    <RecoverPassword />
                </div>
            )
    }
}

const DeleteAccount = (props) => {

    const authContext = useContext(AuthContext);
    const userDataContext = useContext(UserDataContext);
    const profileContext = useContext(ProfileContext);

    const [confirmDelete, setConfirmDelete] = useState(false);

    // * DELETE ACCOUNT
    // delete from firebase
    // delete all data from database
    // then log out user
    const handleDeleteAccount = () => {
        firebase.auth().currentUser.delete()
            .then(res => {
                userApi.deleteUserById(authContext.userId)
                    .then(deletedUser => {
                        profileContext.signOut();
                        userDataContext.signOut();
                        authContext.logOut();
                    })
                    .catch(err => console.error("DELETED FROM FIREBASE BUT FAILED TO DELETE FROM DATABASE: ", err))
            })
            .catch(err => {
                console.log("FAILED TO DELETE FROM FIREBASE: ", err);
            })
    }

    if (!confirmDelete) return (
        <div className="delete-account-container">
            <i className="material-icons">warning</i>

            <label>Deleting your account will:</label>
            <div>
                <span> •  Log you out</span>
                <span> •  Permanently delete all of your profile data and paths</span>
            </div>

            <button onClick={() => setConfirmDelete(true)}>Delete Account</button>
        </div>
    )
    else return (
        <div className="delete-account-container">
            <i className="material-icons">warning</i>

            <label>Are you sure?</label>
            <div>
                <span>Deleting your account will permanently remove all of your information from our database. This cannot be undone.</span>
            </div>

            <button onClick={handleDeleteAccount}>Delete Account</button>

            <button className="cancel-btn" onClick={() => setConfirmDelete(false)}>Cancel</button>
        </div>
    )
}

const AccountInfo = (props) => {

    const {
        accountCreationDate
    } = useContext(ProfileContext);

    const {
        email
    } = useContext(AuthContext);

    const data = [
        {
            label: 'Email Address',
            value: email
        },
        {
            label: 'Account Created',
            value: new Date(accountCreationDate).toDateString()
        }
    ]

    return (
        <div className="account-info-container">
            {data.map((item, key) => (
                <span key={key}>
                    <label>{item.label}</label>
                    <p>{item.value}</p>
                </span>
            ))}
        </div>
    )
}

const RecoverPassword = (props) => {

    const [email, setEmail] = useState('');

    const sendRecoveryEmail = () => {

        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                toast.success(`Recovery email sent to ${email}`);
                setEmail('')
            })
            .catch((err) => console.error("Failed to send recovery email: ", err));;
    }

    return (
        <div className="recover-password-container">
            <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter your email address"
                autoCapitalize="none"
            />
            <button
                disabled={email.length ? false : true}
                onClick={sendRecoveryEmail}
            >
                Email me a recovery link
            </button>
        </div>
    )
}

export default Settings;
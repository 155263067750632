import React, { useState } from "react";

const VIEWS = {
    MAIN: 'main',
    PASSPORT: 'passport',
    INTERESTS: 'interest-lib',
}

const InterestsOverview = (props) => {

    const { 
        interests,
        setProfileDisplay,
        isCurrentUser
    } = props;

    const InterestCategoryTag = ({category, interests}) => {

        const [expanded, setExpanded] = useState(false);

        return !expanded ? (
            <div className={`interest-tag multi ${category}-selected`} onClick={() => setExpanded(true)}>
                {category[0].toUpperCase() + category.substring(1)}
                <div className="category-tag-count">{interests.length}</div>
            </div>
        ) : (
            interests.map(interest => (
                <div className={`interest-tag ${category}-selected`} onClick={() => setExpanded(false)}>
                    {interest}
                </div>
            ))
        )
    }

    const hasInterests = Object.values(interests).some(interestList => interestList.length > 0);

    return (
        <div className="interest-tags-overview">
            {
                Object.keys(interests).map(category => {
                    if(interests[category].length > 1) {
                        return <InterestCategoryTag category={category} interests={interests[category]} />
                    } else if(interests[category].length == 1) {
                        return (
                            <div className={`interest-tag ${category}-selected`}>
                                {interests[category][0]}
                            </div>
                        )
                    }
                })
            }
            {(!hasInterests && !isCurrentUser) ? (
                <div className="empty-profile-content-message">
                    No interests selected
                </div>
            ) : null}
            {setProfileDisplay ? (
                <div className="interest-tag add" onClick={() => setProfileDisplay(VIEWS.INTERESTS)}>
                    <i className="material-icons">add</i>
                    Add interest
                </div>
            ) : null}
            
        </div>
        
    )
}

export default InterestsOverview;
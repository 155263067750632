import React, { Component, createContext, useContext, useEffect, useState, useRef } from 'react';
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import AuthContext from './AuthContext';
import _ from 'lodash';
import ProfileContext from './ProfileContext';
import { useBreakpoints } from "react-breakpoints-hook";
import pathApi from '../utils/pathApi';
import pathUtils from '../utils/pathUtils';

const UserDataContext = createContext();

/**
 *
 * UNFINISHED
 *
 * CONTEXT FOR STORING USER DATA
 * THIS WILL BE USED BY FRONTEND TO UPDATE USER DATA, SUCH AS ADDING/DELETING PATHS
 * THIS CONTEXT WILL ALSO BE WHAT MAKES THE API CALLS TO UPDATE THE BACKEND STORAGE
 *
 */

export const UserDataStore = (props) => {

    // static contextType = AuthContext;
    const authContext = useContext(AuthContext);
    const profileContext = useContext(ProfileContext)

    const [ userPaths, setUserPaths ] = useState({});
    const [ favoritePaths, setFavoritePaths ] = useState([]);

    const [ activePath, setActivePath ] = useState(0);
    const [ activeSharedPath, setActiveSharedPath ] = useState(null);
    const [ activeSharedUser, setActiveSharedUser ] = useState(null);
    const [ activeBreak, setActiveBreak ] = useState(null);
    const [ activePathway, setActivePathway ] = useState(null);
    const [ pbPaneView, setPBPaneView ] = useState(null);
    const [ mapboxglRef, setMapboxglRef ] = useState(null);
    const [ initialRenderComplete, setInitialRenderComplete ] = useState(false);

    const mapRef = useRef(null);

    const currActiveBreak = useRef()
    currActiveBreak.current = activeBreak;

    const currActivePathway = useRef()
    currActivePathway.current = activePathway;

    const currPBPaneView = useRef();
    currPBPaneView.current = pbPaneView;

    const [ detailsTags, setDetailsTags ] = useState({
        cohort: [
            "Solo",
            "Couple",
            "School",
            "Family",
            "Friends",
            "Professional"
        ],
        companions: [
            "Young Child(ren)",
            "Teen(s)",
            "Baby/ Babies",
            "Young Adult(s)",
            "Elderly Person/ People",
        ],
        pets: [
            "Large Dog(s)",
            "Small Dog(s)",
            "Cat(s)",
            "Bird(s)",
            "Turtle(s)",
        ],
        spaces: [
            "Urban",
            "Suburban",
            "Countryside",
            "Coastal",
            "Nature",
        ],
        stays: [
            "Resort",
            "Hotel",
            "Hostel",
            "Yurt",
            "Camper",
            "Campsite",
            "Private Rental",
            "Homestay",
        ],
        budgets: [
            "$", "$$", "$$$", "$$$$", "$$$$$"
        ],
        length: [
            "Layover",
            "Day Trip",
            "Single Stop",
            "Multi Destination"
        ]
    });

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    // * if activePath changes:
    // * new activePath == 0 --> reset active break, pathway, pbPaneView
    useEffect(() => {
        if(activePath == 0) {
            setActiveBreak(null);
            setActivePathway(null);
            if(pbPaneView !== 'profile') setPBPaneView(null);
        } else {
            if(activeBreak === null) {
                mapRef.current.setLayoutProperty('active-subbreaks', 'visibility', 'none');
            }
            if(userPaths[activePath].nodes.length > 1) {
                let bounds = new mapboxglRef.LngLatBounds();
                userPaths[activePath].nodes.forEach(node => {
                    bounds.extend([node.long, node.lat]);
                })
                mapRef.current.fitBounds(bounds, { padding: 100 });
            }
        }
    }, [activePath])

    useEffect(() => {
        if(!activeSharedPath) {
            setActiveBreak(null);
            setActivePathway(null);

            if (initialRenderComplete) removeSharedPathFromMap();

        } else {
            console.log("NEW ACTIVE SHARED PATH SET: ", activeSharedPath);
            if (initialRenderComplete) {
                showSharedPathOnMap();
            }
            setPBPaneView('viewer');
        }
    }, [activeSharedPath])

    useEffect(() => {
        if (initialRenderComplete && activeSharedPath) {
            showSharedPathOnMap();
        }
    }, [initialRenderComplete])

    // * if activeBreak changes
    useEffect(() => {

        const currPath = activeSharedPath ? activeSharedPath : activePath ? userPaths[activePath] : null;
        console.log("CURR PATH: ", currPath);

        if (activeBreak !== null) {
            mapRef.current.setFilter('active-subbreaks', ['==', ['get', 'breakIndex'], activeBreak]); // !! ENSURE THAT ACTIVE BREAK IS SET AS FULL BREAK NOT JUST INDEX
            mapRef.current.setLayoutProperty('active-subbreaks', 'visibility', 'visible');

            const currBreak = currPath.nodes[activeBreak]

            if(currBreak.subbreaks && currBreak.subbreaks.length) {
                let bounds = new mapboxglRef.LngLatBounds();
                bounds.extend([currBreak.long, currBreak.lat]);
                currBreak.subbreaks.forEach(sb => {
                    bounds.extend([sb.long, sb.lat]);
                })
                mapRef.current.fitBounds(bounds, { padding: 100 });
            } else {
                const { long, lat } = currBreak;
                mapRef.current.flyTo({
                    center: [long, lat],
                    zoom: 12
                });
            }

        } else {

            if (currPath) {
                // * zoom to have full path in view -- this will be the set bounds method eventually
                if(currPath.nodes.length) {
                    if(currPath.nodes.length > 1) {
                        let bounds = new mapboxglRef.LngLatBounds();
                        currPath.nodes.forEach(node => {
                            bounds.extend([node.long, node.lat]);
                        })
                        mapRef.current.fitBounds(bounds, { padding: 100 });
                    } else {
                        let { long, lat } = currPath.nodes[0]
                        mapRef.current.flyTo({
                            center: [long, lat],
                            zoom: 8
                        });
                    }
                }
                mapRef.current.setLayoutProperty('active-subbreaks', 'visibility', 'none');
            }
        }

    }, [activeBreak]);

    useEffect(() => {

        const currPath = activeSharedPath ? activeSharedPath : activePath ? userPaths[activePath] : null;
        console.log("CURR PATH: ", currPath);

        if (activePathway !== null) {
            let bounds = new mapboxglRef.LngLatBounds();
            const currPathway = currPath.routePoints[activePathway];
            bounds.extend(currPathway.startCoords);
            bounds.extend(currPathway.endCoords);
            mapRef.current.fitBounds(bounds, { padding: 100 });
        } else {

            if (currPath) {
                if(currPath.nodes.length > 1) {
                    let bounds = new mapboxglRef.LngLatBounds();
                    currPath.nodes.forEach(node => {
                        bounds.extend([node.long, node.lat]);
                    })
                    mapRef.current.fitBounds(bounds, { padding: 100 });
                }
            }
        }

    }, [activePathway])

    // * if pbPaneView changes, resize map
    useEffect(() => {
        if(pbPaneView == null && mapRef.current) {
            setTimeout(() => {
                mapRef.current.resize();
            }, 300)
        }
        // if(pbPaneView == 'profile' && map) {

        // }
    }, [pbPaneView])

    const getUserPaths = () => {
        return userPaths;
    }

    const getActivePath = () => {
        return activePath;
    }

    const getActiveBreak = () => {
        return activeBreak;
    }

    const getActivePathway = () => {
        return activePathway;
    }

    const getPBPaneView = () => {
        return pbPaneView
    }

    // return pathId associated with pathName
    const getPathNameById = (pathId) => {
        return userPaths[pathId] ? userPaths[pathId].pathName : '';
    }

    // * generate new pathId based on the users current highest pathId
    const generateNewPathId = () => {
        if(!Object.keys(userPaths).length) {
            return 1
        } else {
            let lastPathId = parseInt(Object.keys(userPaths).slice(-1)[0]);
            return lastPathId + 1;
        }
    }

    // MAY NOT NEED
    // Creates new path called name in user data store and updates backend storage
    const initNewPath = (pathId, pathName) => {
        // let { userPaths } = state;
        let paths = Object.assign({}, userPaths);
        paths[pathId] = {
            pathName: pathName,
            nodes: [],
            routePoints: [],
            secrets: [],
            details: {
                cohort: [],
                companions: [],
                pets: [],
                spaces: [],
                stays: [],
                budgets: [],
                length: []
            },
            tags: {
                recreation: [],
                culture: [],
                wellness: [],
                nature: [],
                shopping: [],
                fun: [],
                sightseeing: [],
                nightlife: [],
                cuisine: [],
            }
        }
        setUserPaths(paths);
        console.log("USER DATA STORE -> New Path Created: ", pathId);
    }

    const initPathwayVariables = (pathway, startBreak = null, endBreak = null) => {

        pathway.midpoints = [];
        pathway.navType = "driving";
        pathway.fields = {
            description: "",
            images: [],
            transportTypes: {
                car: true,
                walk: false,
                plane: false,
                train: false,
                bike: false,
                metro: false,
                bus: false,
                motorbike: false,
                camper: false,
                boat: false,
                sailboat: false,
                horseback: false,
            },
            navOptOut: false,
        };

        if(startBreak && endBreak) {
            pathway.pathId = startBreak.pathId;
            pathway.startCoords = [startBreak.long, startBreak.lat];
            pathway.endCoords = [endBreak.long, endBreak.lat];
            pathway.label = `${startBreak.placeName} to ${endBreak.placeName}`;
        }

        return pathway;
    }

    // * Deletes path associated with name
    const deletePath = (pathId) => {

        // * delete entry from path indicators
        console.log("PATH INDICATORS: ", mapRef.current.getSource('path-indicators')._data.features)
        let newPathIndicators = mapRef.current.getSource('path-indicators')._data.features.filter(feature => feature.properties.pathId !== pathId);
        mapRef.current.getSource('path-indicators').setData({
            'type': 'FeatureCollection',
            'features': newPathIndicators
        })

        if(activePath == pathId) {
            setActivePath(0);
            setPBPaneView(null);
        }

        // * delete path from db
        if(userPaths[pathId].pid) {
            fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/paths/${userPaths[pathId].pid}`, {
                method: "DELETE"
            })
            .then(res => res.json())
            .then(deletedPath => {
                console.log("DELETED PATH: ", deletedPath);
            })
            .catch(err => {
                console.error("FAILED TO DELETE PATH FROM DB: ", err)
            })
        }
      
        // * delete path from context
        let paths = Object.assign({}, userPaths);
        delete paths[pathId];
        setUserPaths(paths);

        console.log("USER DATA STORE -> Path Deleted: ", pathId);
    }

    const addNodeToPath = async (pathId, node) => {

        const popup = document.getElementsByClassName('mapboxgl-popup');
        if ( popup.length ) {
            popup[0].remove();
        }

        let pathName = getPathNameById(pathId);

        if(!pathId) {
            pathId = generateNewPathId();
            pathName = `NEW PATH ${pathId}`;
        }

        let activePathSource = mapRef.current.getSource('active-path-points');
        let features = activePathSource._data.features;

        let nodeIndexInPath = features.length || 0;

        let newNode = {
            "type": "Feature",
            // 'pathId': pathId,
            // 'breakIndex': nodeIndexInPath, 
            'properties': {
                'break-type': 'explore',
                'pathId': pathId,
                'breakIndex': nodeIndexInPath
            },
            "geometry": {
                "type": "Point",
                "coordinates": [ node.long, node.lat ]
            }
        }

        features.push(newNode);

        activePathSource.setData({
            "type": "FeatureCollection",
            "features": features
        });        

        // add pathId to, and initialize fields for, node before adding it to context
        node.pathId = pathId;
        node.breakIndex = nodeIndexInPath;
        node.fields = {
            title: "",
            description: "",
            images: [],
            breakType: "explore",
        };
        node.subbreaks = [];
        

        // let { userPaths } = this.state;
        let paths = Object.assign({}, userPaths);
        if(!(paths[pathId])) {
            paths[pathId] = {
                pathName: pathName,
                nodes: [],
                routePoints: [],
                secrets: [],
                details: {
                    cohort: [],
                    companions: [],
                    pets: [],
                    spaces: [],
                    stays: [],
                    budgets: [],
                    length: []
                },
                tags: {
                    recreation: [],
                    culture: [],
                    wellness: [],
                    nature: [],
                    shopping: [],
                    fun: [],
                    sightseeing: [],
                    nightlife: [],
                    cuisine: [],
                }
            };
        }
        paths[pathId].nodes.push(node)
        setUserPaths(paths);
        setActivePath(pathId);
        // ! Below may be a deprecated method that can be achieved via hook (and maybe useEffect)
        setPBPaneView('editor');

        // loop through map features and add coords for each node in activePath
        // if there is more than one node, take the coords for the last two, and getRoute between them
        let pathNodeCoords = [];
        activePathSource._data.features.forEach(feature => {
            pathNodeCoords.push(feature.geometry.coordinates);
        })

        if(pathNodeCoords.length > 1) {
            pathNodeCoords = pathNodeCoords.slice(-2);
            let routeCoords = await pathUtils.getRoute(pathNodeCoords, 'driving');
            
            addRouteToMap(routeCoords, pathId.toString());
        }

        // ! this may be a deprecated method to sort below with a hook -- clashes with current hook
        setActiveBreak(node.breakIndex);
        
    }

    // delete the nodeIndex-th node in path named pathName
    const deleteNodeFromPath = async (pathId, nodeIndex) => {

        let paths = Object.assign({}, userPaths);
        paths[pathId].nodes.splice(nodeIndex, 1);
        // setUserPaths(paths);
        setUserPaths({...userPaths, pathId: paths[pathId]});

        // * get new list of all point features without deleted node
        // * decrement index of nodes in path after deleted node
        // * update source with new feature data

        let points = mapRef.current.getSource('active-path-points')._data.features;
        let newPoints = [];
        points.forEach(point => {
            if(point.properties.breakIndex !== nodeIndex) {
                if(point.properties.breakIndex > nodeIndex) {
                    point.properties.breakIndex--;
                };
                newPoints.push(point);
            }
        })
        mapRef.current.getSource('active-path-points').setData({
            "type": "FeatureCollection",
            "features": newPoints
        });


        // * delete subbreaks for this node
        let newSubbreaks = []
        mapRef.current.getSource('active-subbreaks')._data.features.forEach(subbreak => {
            if(!(subbreak.properties.pathId == pathId && subbreak.properties.breakIndex == nodeIndex)) {
                if(subbreak.properties.breakIndex > nodeIndex) subbreak.properties.breakIndex--;
                newSubbreaks.push(subbreak);
            }
        });
        mapRef.current.getSource('active-subbreaks').setData({
            "type": "FeatureCollection",
            "features": newSubbreaks
        });

        // * edit routePoints to delete this node
        // * if deletion caused a new pathway, calculate this reroute and add to routePoints
        if(nodeIndex === 0) {
            paths[pathId].routePoints.shift();
            paths[pathId].routePoints.forEach(pathway => pathway.navIndex--);
        } else if (nodeIndex === paths[pathId].routePoints.length) {
            paths[pathId].routePoints.pop();
        } else {
            // userPaths[pathId].routePoints.splice(nodeIndex-1, 2);
            let surroundingNodeCoords = []
            surroundingNodeCoords.push(newPoints[nodeIndex-1].geometry.coordinates);
            surroundingNodeCoords.push(newPoints[nodeIndex].geometry.coordinates);
            let reroute = await pathUtils.getRoute(surroundingNodeCoords, 'driving');
            reroute = initPathwayVariables(reroute, paths[pathId].nodes[nodeIndex -1], paths[pathId].nodes[nodeIndex]);
            paths[pathId].routePoints.splice(nodeIndex-1, 2);
            paths[pathId].routePoints.splice(nodeIndex-1, 0, reroute);
        }

        setUserPaths(paths);
        mapNewRoute(pathId);

        console.log("COMPLETED DELETE NODE, userPaths: ", userPaths);

    }

    const addRouteToMap = (routeCoords, pathId) => {

        routeCoords = initPathwayVariables(routeCoords);

        // * if exists, add to this layer
        console.log("ADD PATHWAY: ", userPaths[pathId])
        let navIndex = userPaths[pathId].routePoints.length;
        routeCoords.navIndex = navIndex;
        routeCoords.pathId = pathId;
        routeCoords.startCoords = [userPaths[pathId].nodes[navIndex].long, userPaths[pathId].nodes[navIndex].lat];
        routeCoords.endCoords = [userPaths[pathId].nodes[navIndex+1].long, userPaths[pathId].nodes[navIndex+1].lat];
        routeCoords.label = `${userPaths[pathId].nodes[navIndex].placeName} to ${userPaths[pathId].nodes[navIndex+1].placeName}`;

        let paths = Object.assign({}, userPaths);
        paths[pathId].routePoints.push(routeCoords);
        setUserPaths(paths);

        let route = [];
        paths[pathId].routePoints.forEach(intermediatePath => { 
            route.push(...intermediatePath.coordinates);
        });

        let data = {
            'type': 'Feature',
            'properties': {},
            'geometry': {
                coordinates: route,
                type: "LineString"
            }
        }

        mapRef.current.getSource('active-path-lines').setData(data);

    }

    // * creates and returns DOM Node for Search Popup Content
    const addSearchPopupToMap = (searchResultPoint) => {

        const oldPopup = document.getElementsByClassName('mapboxgl-popup');
        if ( oldPopup.length ) {
            oldPopup[0].remove();
        }

        let popupElement = window.document.createElement('div');
        popupElement.className = 'search-popup-container';
        popupElement.innerHTML = `
            <div class='search-popup-header'>
                <i class="material-icons">place</i>
                <h3>${searchResultPoint.placeName}</h3>
                <span>${searchResultPoint.address}</span>
            </div>
            `;

        let popupActions = window.document.createElement('div');
        popupActions.className = 'search-popup-actions';

        let addNodeBtn = window.document.createElement('div');
        addNodeBtn.innerHTML = "<button className='search-popup-add-node'>Create Break</button>";
        addNodeBtn.addEventListener('click', (e) => {
            addNodeToPath(activePath, searchResultPoint);
        });
        popupActions.appendChild(addNodeBtn);

        // * need to access activeBreak via useRef so this works for adding searchPopup on search (rather than click)
        if(currActiveBreak.current !== null) {
            let addSubbreakBtn = window.document.createElement('div');
            addSubbreakBtn.innerHTML = "<button className='search-popup-add-node'>Add Local Spot</button>";
            addSubbreakBtn.addEventListener('click', (e) => {
                addSubBreak(searchResultPoint, activePath, currActiveBreak.current);
            });
            popupActions.appendChild(addSubbreakBtn);
        } 
        console.log("Current Pathway: ", currActivePathway.current, activePathway)
        if (currActivePathway.current) {
            let addWaypointButton = window.document.createElement('div');
            addWaypointButton.innerHTML = "<button className='search-popup-add-node'>Add Waypoint</button>";
            addWaypointButton.addEventListener('click', (e) => {
                addWaypointToMap(activePath, currActivePathway.current, [ searchResultPoint.long, searchResultPoint.lat ]);
            });
            popupActions.appendChild(addWaypointButton);
        }
        
        popupElement.appendChild(popupActions);

        let popup = new mapboxgl.Popup({ offset: [0, 0], closeOnClick: false, closeButton: true })
            .setLngLat([ searchResultPoint.long, searchResultPoint.lat ])
            .setDOMContent(popupElement);

        if(mobile) {
            popup.on('close', (e) => {
                if(activePath) setPBPaneView('editor');
            })
        }

        popup.addTo(mapRef.current);
    }

    const addWaypointToMap = async (pathId, pathwayIndex, coordinates) => {

        const popup = document.getElementsByClassName('mapboxgl-popup');
        if ( popup.length ) {
            popup[0].remove();
        }

        if(userPaths[pathId].routePoints[pathwayIndex].midpoints.length < 5) {
            let waypointData = await getInfoFromCoordinates(coordinates);
            userPaths[pathId].routePoints[pathwayIndex].midpoints.push(waypointData);
            rerouteThroughMidpoints(userPaths[pathId].routePoints[pathwayIndex].midpoints, pathId, pathwayIndex, getActiveNavType(pathId, pathwayIndex))

            let newWaypoints = [ ...mapRef.current.getSource('active-path-waypoints')._data.features, {
                "type": "Feature",
                'pathId': pathId,
                'navIndex': pathwayIndex,
                'waypointIndex': userPaths[pathId].routePoints[pathwayIndex].midpoints.length - 1,
                "geometry": {
                    "type": "Point",
                    "coordinates": [ coordinates[0], coordinates[1] ]
                }
            }];

            mapRef.current.getSource('active-path-waypoints').setData({
                "type": "FeatureCollection",
                "features": newWaypoints
            });
        }
    }


    // * returns path identified by pathId
    // ! may not be needed in implementation -- they can just access userPaths from context then call the check in the code
    const getPathById = (pathId) => {

        if(pathId && userPaths[pathId]) {
            return userPaths[pathId];
        }
        return {};
    }

    // * updates specific path identified by pathId
    // ! may not be needed in implementation -- they can just access userPaths from context then update in code
    const updatePathById = (pathId, path) => {
        // let { userPaths } = this.state;
        let paths = Object.assign({}, userPaths);
        paths[pathId] = path;
        setUserPaths(paths);

    }

    // * returns iterable containing each pathway for a given path
    const getPathwaysByPathId = (pathId) => {

        let pathways = []
        if(userPaths[pathId] && userPaths[pathId].nodes.length > 1) {
            for(let i = 0; i < userPaths[pathId].nodes.length -1; i++) {
                pathways.push({
                    label: `${userPaths[pathId].nodes[i].placeName} to ${userPaths[pathId].nodes[i+1].placeName}`,
                    navIndex: i,
                    pathId: userPaths[pathId].nodes[i].pathId,
                    startCoords: [userPaths[pathId].nodes[i].long, userPaths[pathId].nodes[i].lat],
                    endCoords: [userPaths[pathId].nodes[i+1].long, userPaths[pathId].nodes[i+1].lat],
                    fields: userPaths[pathId].routePoints[i].fields
                    // TODO: add distance stats here
                })
            }
        }
        return pathways;
    }

    // used to set active path creator tab so when pathways tab is active, we can add waypoints to a pathway
    // !! DELIBERATELY NOT FIXED -- check if this breaks anything
    const setActivePathCreatorTab = (tab) => {
        if(tab == "Breaks") {
            setActivePathway(null)
        } else if (tab == "Pathway") {
            setActiveBreak(null);
        } else {
            setActiveBreak(null);
            setActivePathway(null);
        }

    }
    // !! DELIBERATELY NOT FIXED -- check if this breaks anything
    const getActivePathCreatorTab = () => {
        // return state.activePathCreatorTab;
    }

    const saveMapRef = (map, mapboxgl) => {
        mapRef.current = map;
        setMapboxglRef(mapboxgl);
    }

    const getMapRef = () => {
        return {
            map: mapRef.current,
            mapboxgl: mapboxglRef
        }
    }


    // * upon click path in pathcreator pane, fly to coordinates of start of path
    const flyToPath = (pathId) => {

        if(userPaths[pathId] && userPaths[pathId].nodes && userPaths[pathId].nodes.length) {

            let coordinates = [ userPaths[pathId].nodes[0].long, userPaths[pathId].nodes[0].lat ]

            mapRef.current.setCenter(coordinates);
            mapRef.current.zoomTo(10);
        }
    }

    const flyToNode = (coordinates) => {
        mapRef.current.setCenter(coordinates);
        mapRef.current.zoomTo(9);
    }

    // * Get routePoints attached to a pathId
    const getRoutePoints = (pathId) => {
        return userPaths[pathId].routePoints;
    }


    // * updateRoutePoints for a specific pathId
    const updateRoutePoints = (pathId, routePoints) => {

        let paths = Object.assign({}, userPaths);
        paths[pathId].routePoints = routePoints;
        setUserPaths(paths);
    }

    // * take start coords, end coords, and array of midpoints, return routing line
    // * routepoints for this pathId and navIndex = {coordinates, midpoints}
    const rerouteThroughMidpoints = async (newMidpoints, pathId, navIndex, navType) => {

        let start = userPaths[pathId].routePoints[navIndex].coordinates[0];
        let [ end ] = userPaths[pathId].routePoints[navIndex].coordinates.slice(-1);
        let midRoute, newRouteCoords = [];

        if(newMidpoints.length) {
            // get route from A to first midpoint
            midRoute = await pathUtils.getRoute([start, newMidpoints[0].coordinates], navType);
            newRouteCoords.push.apply(newRouteCoords, midRoute.coordinates);

            // if there is more than one midpoint, get routes between intermediate midpoints
            if(newMidpoints.length > 1) {
                for(let i = 0; i < newMidpoints.length - 1; i++) {
                    midRoute = await pathUtils.getRoute([newMidpoints[i].coordinates, newMidpoints[i+1].coordinates], navType);
                    newRouteCoords.push.apply(newRouteCoords, midRoute.coordinates);
                }
            }

            // get route from last midpoint to B
            midRoute = await pathUtils.getRoute([newMidpoints.slice(-1)[0].coordinates, end], navType);
            newRouteCoords.push.apply(newRouteCoords, midRoute.coordinates);
        } else {
            midRoute = await pathUtils.getRoute([start, end], navType);
            newRouteCoords.push.apply(newRouteCoords, midRoute.coordinates);
        }

        // let { userPaths } = this.state;
        let paths = Object.assign({}, userPaths);
        paths[pathId].routePoints[navIndex].coordinates = newRouteCoords;
        setUserPaths(paths);
        mapNewRoute(pathId);
    }

    const deleteWaypointFromMap = (pathId, navIndex, waypointIndex) => {

        let waypointSource = mapRef.current.getSource('active-path-waypoints');

        let newWaypoints = [];
        waypointSource._data.features.forEach(waypoint => {
            if(!(waypoint.pathId == pathId && waypoint.navIndex == navIndex && waypoint.waypointIndex == waypointIndex)) {
                if(waypoint.pathId == pathId && waypoint.navIndex == navIndex && waypoint.waypointIndex > waypointIndex) {
                    waypoint.waypointIndex--;
                }
                newWaypoints.push(waypoint);
            }
        });

        waypointSource.setData({
            "type": "FeatureCollection",
            "features": newWaypoints
        });
    }

    const updateMapWaypoints = (pathways) => {

        let newWaypoints = []
        pathways.forEach((pathway, index) => {
            pathway.midpoints.forEach((waypoint, id) => {
                newWaypoints.push({
                    "type": "Feature",
                    'pathId': pathway.pathId,
                    'navIndex': index,
                    'waypointIndex': id,
                    "geometry": {
                        "type": "Point",
                        "coordinates": waypoint.coordinates
                    }
                });
            });
        });

        mapRef.current.getSource('active-path-waypoints').setData({
            "type": "FeatureCollection",
            "features": newWaypoints
        });
    }

    // @param dragIndex -- original position of break to move
    // @param dropIndex -- new position of break to move
    const rerouteOnBreakReorder = async (pathId, dragIndex, dropIndex) => {

        if(dragIndex !== dropIndex) {

            // * make copy of original breaks to reference when making changes to pathways so that we can update new order in state more quickly
            let paths = Object.assign({}, userPaths);
            let breaks = [...paths[pathId].nodes];

            let [ breakToMove ] = paths[pathId].nodes.splice(dragIndex, 1);
            paths[pathId].nodes.splice(dropIndex, 0, breakToMove);
            setUserPaths(paths);

            // * calc shift -- if we are moving a break to an earlier position, we have already added a net total of 1 pathway before dragIndex
            // *            -- hence we need shift to compensate for where we remove old/add new drag site pathways
            let shift = 0;
            if(dragIndex > dropIndex) shift = 1;

            let preDropToDropPathway = null, dropToPostDropPathway = null;
            // * if not moving to the start, there will be a new pathway between preDrop and drop
            if(dropIndex !== 0) {
                preDropToDropPathway = await pathUtils.getRoute([ [breaks[dropIndex-shift].long, breaks[dropIndex-shift].lat], [breaks[dragIndex].long, breaks[dragIndex].lat] ], 'driving');
                preDropToDropPathway = initPathwayVariables(preDropToDropPathway, breaks[dropIndex-shift], breaks[dragIndex]);
            }
            // * if not moving to the end, there will be a new pathway between drop and postDrop
            if(dropIndex !== breaks.length - 1) {
                dropToPostDropPathway = await pathUtils.getRoute([ [breaks[dragIndex].long, breaks[dragIndex].lat], [breaks[dropIndex - shift + 1].long, breaks[dropIndex - shift + 1].lat] ], 'driving');
                dropToPostDropPathway = initPathwayVariables(dropToPostDropPathway, breaks[dragIndex], breaks[dropIndex - shift + 1]);
            }
            // * FIX DROP SITE:
            // * if we are moving to start of breaks, insert dropToPostDrop into start of pathways
            if(!preDropToDropPathway && dropToPostDropPathway) {
                paths[pathId].routePoints.unshift(dropToPostDropPathway);
            }
            // * if we are moving to end of breaks, insert preDropToDrop into end of pathways
            else if(preDropToDropPathway && !dropToPostDropPathway) {
                paths[pathId].routePoints.push(preDropToDropPathway)
            }
            // * if we are moving to middle of breaks, insert both preDropToDrop and dropToPostDrop
            else if(preDropToDropPathway && dropToPostDropPathway) {
                paths[pathId].routePoints.splice(dropIndex - shift, 1);
                paths[pathId].routePoints.splice(dropIndex - shift, 0, preDropToDropPathway, dropToPostDropPathway);
            }

            // * FIX DRAG SITE:
            // * if dragging from start, remove first pathway
            if(dragIndex == 0) {
                paths[pathId].routePoints.shift();
            }
            // * if dragging from end, remove last pathway
            else if(dragIndex == breaks.length - 1) {
                paths[pathId].routePoints.pop();
            }
            // * else we are dragging from middle, so must remove pathways either side of drag break
            else {

                paths[pathId].routePoints.splice(dragIndex - 1 + shift, 2);
                let dragGapPathway = await pathUtils.getRoute([ [breaks[dragIndex-1].long, breaks[dragIndex-1].lat], [breaks[dragIndex+1].long, breaks[dragIndex+1].lat] ], "driving");
                dragGapPathway = initPathwayVariables(dragGapPathway, breaks[dragIndex-1], breaks[dragIndex+1]);
                console.log("DGP", dragGapPathway);
                paths[pathId].routePoints.splice(dragIndex - 1 + shift, 0, dragGapPathway);
            }

            paths[pathId].routePoints.forEach((pathway, index) => {
                pathway.navIndex = index;
            });

            updateMapWaypoints(paths[pathId].routePoints)
            paths = Object.assign({}, paths);
            setUserPaths(paths);
            mapNewRoute(pathId);
        }
    }

    // * builds new route assuming routePoints has been updated
    // * only call after routePoints has just been changed
    // * if routePoints entry is now empty, remove line
    const mapNewRoute = (pathId) => {

        // if there is no routePoints, don't map a line
        if(userPaths[pathId].routePoints.length) {

            let newRoute = [];
            userPaths[pathId].routePoints.forEach((intermediatePath, index) => {
                newRoute.push(...intermediatePath.coordinates);
                intermediatePath.navIndex = index;
                intermediatePath.index = index;
            });
            let data = {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    coordinates: newRoute,
                    type: "LineString"
                }
            }

            
            mapRef.current.getSource('active-path-lines').setData(data);
        } else {
            mapRef.current.getSource('active-path-lines').setData({
                'type': 'FeatureCollection',
                'features': []
            });
        }
    }

    // * return midpoints for a given pathId and navIndex (midpoints attached to a specific pathway)
    const getMidpointsData = (pathId, navIndex) => {

        return ( userPaths[pathId].routePoints[navIndex] && userPaths[pathId].routePoints[navIndex].midpoints ) ? userPaths[pathId].routePoints[navIndex].midpoints : [];
    }

    // * take [long, lat] array and call reverse geocoding api to return place information
    const getInfoFromCoordinates = ( coordinates ) => {
        let long = coordinates[0];
        let lat = coordinates[1];

        let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

        return new Promise((resolve, reject) => {
            let req = new XMLHttpRequest();
            req.responseType = 'json';
            req.open('GET', url, true);
            req.onload  = () => {

                let coordinateInfo = {
                    coordinates: req.response.query,
                }

                if(!req.response.features.length) {
                    coordinateInfo.waterLabel = "Point in Water";
                } else {

                    let data = {}

                    req.response.features.forEach((feature, key) => {

                        let placeType = feature.place_type[0];
                        if(!key) data.address = feature.place_name;
                        if(placeType == 'address') data[placeType] = feature.place_name;
                        else data[placeType] = feature.text;
                    })

                    data.placeName = data.poi || 
                        data.neighborhood || 
                        data.place || 
                        data.locality || 
                        data.district || 
                        data.region || 
                        data.country;

                    coordinateInfo = {
                        ...coordinateInfo,
                        ...data
                    }
                    
                }
                console.log("COORDINATE INFO: ", coordinateInfo)
                resolve(coordinateInfo);
            };
            req.onerror = () => {
                reject({
                    status: this.status,
                    statusText: req.statusText
                });
            };
            req.send();
        });

    }

    // * updates current nav type for a specific nav index
    const updateNavType = (pathId, navIndex, navType) => {
        let paths = Object.assign({}, userPaths);
        paths[pathId].routePoints[navIndex].navType = navType;
        setUserPaths(paths);
    }

    const getActiveNavType = (pathId, navIndex) => {

        return userPaths[pathId].routePoints[navIndex].navType || 'driving';

    }

    const updateBreakIconOnMap = (node, breakType) => {

        let points = mapRef.current.getSource('active-path-points')._data.features.filter(feature => feature.properties.breakIndex !== node.breakIndex)

        let newNode = {
            "type": "Feature",
            'properties': {
                'break-type': breakType,
                'pathId': node.pathId,
                'breakIndex': node.breakIndex, 
            },
            "geometry": {
                "type": "Point",
                "coordinates": [ node.long, node.lat ]
            }
        }

        points.push(newNode);

        mapRef.current.getSource('active-path-points').setData({
            "type": "FeatureCollection",
            "features": points
        });

    }

    const updateSubbreakIcon = (subbreak, breakType) => {
        console.log("UPDATE SUBBREAK ICON: ", subbreak);
        console.log("UPDATE SUBBREAK - current features: ", mapRef.current.getSource('active-subbreaks')._data.features)
        let subbreaks = mapRef.current.getSource('active-subbreaks')._data.features.filter(feature => (feature.properties.breakIndex !== subbreak.breakIndex || feature.properties.subbreakIndex !== subbreak.subbreakIndex));
        let newSubbreak = {
            "type": "Feature",
            'properties': {
                'break-type': breakType,
                'pathId': activePath,
                'breakIndex': subbreak.breakIndex,
                'subbreakIndex': subbreak.subbreakIndex
            },
            "geometry": {
                "type": "Point",
                "coordinates": [ subbreak.long, subbreak.lat ]
            }
        }

        subbreaks.push(newSubbreak);

        mapRef.current.getSource('active-subbreaks').setData({
            "type": "FeatureCollection",
            "features": subbreaks
        });
    }

    const createNewSecret = (pathId) => {
        
        // let { userPaths } = this.state;
        let paths = Object.assign({}, userPaths);

        paths[pathId].secrets.push({
            title: '',
            description: '',
            images: []
        });

        setUserPaths(paths);
    }

    const getSecretsByPathId = (pathId) => {
        if(pathId && userPaths[pathId]) return userPaths[pathId].secrets;
        else return [];
    }

    const addSubBreak = (subbreak, pathId, breakIndex) => {

        const popup = document.getElementsByClassName('mapboxgl-popup');
        if ( popup.length ) {
            popup[0].remove();
        }

        subbreak.fields = {
            title: '',
            description: '',
            images: [],
            breakType: "explore"
        }
 
        // let { userPaths } = this.state;
        let paths = Object.assign({}, userPaths);

        if(!paths[pathId].nodes[breakIndex].subbreaks) {
            paths[pathId].nodes[breakIndex].subbreaks = []
        }

        paths[pathId].nodes[breakIndex].subbreaks.push(subbreak);

        setUserPaths(paths);

        let subbreakSource = mapRef.current.getSource('active-subbreaks');
        let subbreaks = subbreakSource._data.features;

        let subbreakIndex = subbreaks.filter(feature => feature.properties.breakIndex == breakIndex).length;

        let newNode = {
            "type": "Feature",
            'properties': {
                'break-type': 'explore',
                'pathId': pathId,
                'breakIndex': breakIndex,
                'subbreakIndex': subbreakIndex
            },
            "geometry": {
                "type": "Point",
                "coordinates": [ subbreak.long, subbreak.lat ]
            }
        }

        subbreaks.push(newNode);

        subbreakSource.setData({
            "type": "FeatureCollection",
            "features": subbreaks
        });

        // if first subbreak, change break icon on map to be collection
        if(paths[pathId].nodes[breakIndex].subbreaks.length == 1) {
            updateBreakIconOnMap(userPaths[pathId].nodes[breakIndex], 'collection');
        }

        if(mobile) setPBPaneView('editor');

    }

    const getSubBreaks = (pathId, breakIndex) => {
        console.log("GET SUBBREAKS: ", pathId, breakIndex);
        return userPaths[pathId].nodes[breakIndex].subbreaks || [];
    }

    const deleteSubBreak = (pathId, breakIndex, subbreakIndex) => {

        let paths = Object.assign({}, userPaths);

        paths[pathId].nodes[breakIndex].subbreaks.splice(subbreakIndex, 1);

        setUserPaths(paths);

        let newSubbreaks = mapRef.current.getSource("active-subbreaks")._data.features.filter(feature => !(feature.properties.breakIndex == breakIndex && feature.properties.subbreakIndex == subbreakIndex));

        mapRef.current.getSource("active-subbreaks").setData({
            'type': 'FeatureCollection',
            'features': newSubbreaks
        });

        if(!paths[pathId].nodes[breakIndex].subbreaks.length) {
            updateBreakIconOnMap(userPaths[pathId].nodes[breakIndex], userPaths[pathId].nodes[breakIndex].fields.breakType)
        }
    }

    const getPathTags = (pathId) => {
        let paths = Object.assign({}, userPaths);
        if(!paths[pathId].tags) {
            paths[pathId].tags = {
                recreation: [],
                culture: [],
                wellness: [],
                nature: [],
                shopping: [],
                fun: [],
                sightseeing: [],
                nightlife: [],
                cuisine: [],
            }
            setUserPaths(paths);
        }

        return paths[pathId].tags;
    }

    const showSharedPathOnMap = () => {
        if (activeSharedPath) {

            const pointSource = mapRef.current.getSource("active-path-points");
            const lineSource = mapRef.current.getSource("active-path-lines");
            const subbreakSource = mapRef.current.getSource("active-subbreaks");
            const waypointSource = mapRef.current.getSource("active-path-waypoints");

            lineSource.setData({
                type: "Feature",
                properties: {},
                geometry: {
                    coordinates: [],
                    type: "LineString",
                },
            });
            if (pointSource._data.features.length)
                pointSource.setData({ type: "FeatureCollection", features: [] });
            if (subbreakSource._data.features.length)
                subbreakSource.setData({ type: "FeatureCollection", features: [] });
            if (waypointSource._data.features.length)
                waypointSource.setData({ type: "FeatureCollection", features: [] });
            
            if (activeSharedPath.nodes.length) {
                let activePointFeatures = [], subbreaks = [];

                activeSharedPath.nodes.forEach((node) => {
                    activePointFeatures.push({
                        type: "Feature",
                        properties: {
                            "break-type": node.subbreaks.length ? "collection" : node.fields.breakType,
                            breakIndex: node.breakIndex,
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [node.long, node.lat],
                        },
                    });

                    if (node.subbreaks && node.subbreaks.length) {
                        node.subbreaks.forEach((subbreak, index) => {
                            subbreak.subbreakIndex = index;
                            subbreaks.push({
                                type: "Feature",
                                properties: {
                                    "break-type": subbreak.fields.breakType,
                                    breakIndex: node.breakIndex,
                                    subbreakIndex: index,
                                },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subbreak.long, subbreak.lat],
                                },
                            });
                        });
                    }
                })

                pointSource.setData({
                    type: "FeatureCollection",
                    features: activePointFeatures,
                });
                subbreakSource.setData({
                    type: "FeatureCollection",
                    features: subbreaks,
                });

                if (activeSharedPath.nodes.length > 1) {
                    let route = [], waypoints = [];
                    let bounds = new mapboxgl.LngLatBounds();

                    activeSharedPath.nodes.forEach(node => {
                        bounds.extend([node.long, node.lat]);
                    })

                    activeSharedPath.routePoints.forEach((pathway, index) => {
                        route.push(...pathway.coordinates);
                        pathway.midpoints.forEach((waypoint, waypointIndex) => {
                            waypoints.push({
                                type: "Feature",
                                navIndex: index,
                                waypointIndex: waypointIndex,
                                geometry: {
                                    type: "Point",
                                    coordinates: [
                                        waypoint.coordinates[0],
                                        waypoint.coordinates[1],
                                    ],
                                },
                            });
                        });
                    });

                    lineSource.setData({
                        type: "Feature",
                        properties: {},
                        geometry: {
                            coordinates: route,
                            type: "LineString",
                        },
                    });
                    waypointSource.setData({
                        type: "FeatureCollection",
                        features: waypoints,
                    });

                    mapRef.current.fitBounds(bounds, { padding: 100 });
                } else {
                    mapRef.current.flyTo({
                        center: [activeSharedPath.nodes[0].long, activeSharedPath.nodes[0].lat],
                        zoom: 8
                    });
                }

                mapRef.current.setLayoutProperty("active-path-points", "visibility", "visible");
                mapRef.current.setLayoutProperty("active-subbreaks", "visibility", "none");
                mapRef.current.setLayoutProperty("active-path-lines", "visibility", "visible");
                mapRef.current.setLayoutProperty("active-path-waypoints", "visibility", "visible");
                mapRef.current.setLayoutProperty("path-indicators", "visibility", "none");
            }
        }
    }

    const removeSharedPathFromMap = () => {

        mapRef.current.setLayoutProperty("active-path-points", "visibility", "none");
        mapRef.current.setLayoutProperty("active-path-lines", "visibility", "none");
        mapRef.current.setLayoutProperty("active-path-waypoints", "visibility", "none");
        mapRef.current.setLayoutProperty("active-subbreaks", "visibility", "none");

        mapRef.current.getSource("active-path-points").setData({
            type: "FeatureCollection",
            features: [],
        });
        mapRef.current.getSource("active-path-lines").setData({
            type: "Feature",
            // properties: {},
            geometry: {
                coordinates: [],
                type: "LineString",
            },
        });
        mapRef.current.getSource("active-path-waypoints").setData({
            type: "FeatureCollection",
            features: [],
        });
        mapRef.current.getSource("active-subbreaks").setData({
            type: "FeatureCollection",
            features: [],
        });

        mapRef.current.setLayoutProperty("path-indicators", "visibility", "visible");

    }

    const updatePathTags = (pathId, tags) => {
        // let { userPaths } = this.state;

        let paths = Object.assign({}, userPaths);
        paths[pathId].tags = tags;
        setUserPaths(paths);
    }

    const getSelectedPathTagsByCategory = (pathId, category) => {
        let paths = Object.assign({}, userPaths);
        return paths[pathId].tags[category];
    }

    const updatePathTagsByCategory = (pathId, category, selectedTags) => {
        // let { userPaths } = this.state;
        let paths = Object.assign({}, userPaths);

        if(!paths[pathId].tags) {
            paths[pathId].tags = {
                recreation: [],
                culture: [],
                wellness: [],
                nature: [],
                shopping: [],
                fun: [],
                sightseeing: [],
                nightlife: [],
                cuisine: [],
            }
        }

        paths[pathId].tags[category] = selectedTags;

        // let tagOptions = profileContext.getInterestLibOptions();

        // selectedTags.forEach(tag => {
        //     if(!tagOptions[category].includes(tag)) tagOptions[category].push(tag);
        // })

        setUserPaths(paths);
        // profileContext.setInterestLibOptions(tagOptions);
    }

    const getPathDetailsTagOptions = () => {
        return detailsTags;
    }

    const getSelectedPathDetailsTags = (pathId) => {
        return userPaths[pathId].details;
    }

    const updatePathDetailsTags = (pathId, category, selectedTags) => {
        let paths = Object.assign({}, userPaths);
        let tags = detailsTags;

        selectedTags.forEach(tag => {
            if(!tags[category].includes(tag)) {
                tags[category].push(tag);
            }
        });

        if(!paths[pathId].details) {
            paths[pathId].details = {
                cohort: [],
                companions: [],
                pets: [],
                spaces: [],
                stays: [],
                budgets: [],
                length: []
            }  
        }
        paths[pathId].details[category] = selectedTags
        setUserPaths(paths);
        setDetailsTags(tags);
    }

    const resizeMap = () => {
        if(mapRef.current && mapRef.current.resize) {
            mapRef.current.resize();
            // let newMap = Object.assign({}, map);
            // setMap(newMap);
        }
    }

    const setPathsLayerActive = (active) => {

        if(active) {
            // * FIRST - reset all path layers (points - breaks, lines - ways, subbreaks, waypoints)
            const pointSource = mapRef.current.getSource("active-path-points");
            const lineSource = mapRef.current.getSource("active-path-lines");
            const subbreakSource = mapRef.current.getSource("active-subbreaks");
            const waypointSource = mapRef.current.getSource("active-path-waypoints");
    
            lineSource.setData({
                type: "Feature",
                properties: {},
                geometry: {
                    coordinates: [],
                    type: "LineString",
                },
            });

            if (pointSource._data.features.length)
                pointSource.setData({ type: "FeatureCollection", features: [] });
            if (subbreakSource._data.features.length)
                subbreakSource.setData({ type: "FeatureCollection", features: [] });
            if (waypointSource._data.features.length)
                waypointSource.setData({ type: "FeatureCollection", features: [] });
            
            if (userPaths[activePath].nodes.length) {
                const bounds = new mapboxglRef.LngLatBounds();
                let activePointFeatures = [], subbreaks = [];
                
                userPaths[activePath].nodes.forEach((node) => {

                    bounds.extend([node.long, node.lat]);
                    activePointFeatures.push({
                        type: "Feature",
                        properties: {
                            "break-type": node.subbreaks.length ? "collection" : node.fields.breakType,
                            pathId: activePath,
                            breakIndex: node.breakIndex,
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [node.long, node.lat],
                        },
                    });

                    if (node.subbreaks && node.subbreaks.length) {
                        node.subbreaks.forEach((subbreak, index) => {
                            subbreak.subbreakIndex = index; // TODO: check if needed
                            subbreaks.push({
                                type: "Feature",
                                properties: {
                                    "break-type": subbreak.fields.breakType,
                                    pathId: node.pathId,
                                    breakIndex: node.breakIndex,
                                    subbreakIndex: index,
                                },
                                geometry: {
                                    type: "Point",
                                    coordinates: [subbreak.long, subbreak.lat],
                                },
                            });
                        });
                    }
                });
                mapRef.current.fitBounds(bounds, { padding: 100 });

                pointSource.setData({
                    type: "FeatureCollection",
                    features: activePointFeatures,
                });
                subbreakSource.setData({
                    type: "FeatureCollection",
                    features: subbreaks,
                });

                 // * if new active route has multiple nodes, set the path lines (and waypoints)
                if (userPaths[activePath].nodes.length > 1) {

                    let route = [],
                        waypoints = [];
                    
                    userPaths[activePath].routePoints.forEach((pathway, index) => {
                        route.push(...pathway.coordinates);
                        pathway.midpoints.forEach((waypoint, waypointIndex) => {
                            waypoints.push({
                                type: "Feature",
                                pathId: activePath,
                                navIndex: index,
                                waypointIndex: waypointIndex,
                                geometry: {
                                    type: "Point",
                                    coordinates: [
                                        waypoint.coordinates[0],
                                        waypoint.coordinates[1],
                                    ],
                                },
                            });
                        });
                    });

                    lineSource.setData({
                        type: "Feature",
                        properties: {},
                        geometry: {
                            coordinates: route,
                            type: "LineString",
                        },
                    });
                    waypointSource.setData({
                        type: "FeatureCollection",
                        features: waypoints,
                    });
                } 

            } else {
                mapRef.current.flyTo({
                    center: [-10, 20],
                    zoom: 2,
                });
            }

            mapRef.current.setLayoutProperty("active-path-points", "visibility", "visible");
            mapRef.current.setLayoutProperty("active-path-lines", "visibility", "visible");
            mapRef.current.setLayoutProperty(
                "active-path-waypoints",
                "visibility",
                "visible"
            );
        
        } else {
            mapRef.current.setLayoutProperty("active-path-points", "visibility", "none");
            mapRef.current.setLayoutProperty("active-path-lines", "visibility", "none");
            mapRef.current.setLayoutProperty("active-path-waypoints", "visibility", "none");
            mapRef.current.setLayoutProperty("active-subbreaks", "visibility", "none");
            mapRef.current.getSource("active-path-points").setData({
                type: "FeatureCollection",
                features: [],
            });
            mapRef.current.getSource("active-path-lines").setData({
                type: "Feature",
                geometry: {
                    coordinates: [],
                    type: "LineString",
                },
            });
            mapRef.current.getSource("active-path-waypoints").setData({
                type: "FeatureCollection",
                features: [],
            });
            mapRef.current.getSource("active-subbreaks").setData({
                type: "FeatureCollection",
                features: [],
            });
        }
    }

    const setPathIndicatorsLayerActive = async (active) => {
        if(active) {
            let pathIndicatorsFeatures = [];
            const paths = await pathApi.getPathsByUserId(authContext.userId);
            await paths.forEach((path, key) => {
                if (path.breaks.length) {
                    pathIndicatorsFeatures.push({
                        type: "Feature",
                        properties: {
                            pathId: key+1,
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [path.breaks[0].longitude, path.breaks[0].latitude],
                        },
                    });
                }
            });
            console.log("PATH INDICATORS FEATURES: ", pathIndicatorsFeatures);
            mapRef.current.getSource("path-indicators").setData({
                type: "FeatureCollection",
                features: pathIndicatorsFeatures,
            });
            mapRef.current.setLayoutProperty("path-indicators", "visibility", "visible");
        } else {
            mapRef.current.setLayoutProperty("path-indicators", "visibility", "none");
        }
    }

    const setMapPathsDisplay = (activePath) => {
        if (!activePath) {
            // * no activePath, so set path indicators layer + hide activePath layers
            console.log("CHANGED TO NO ACTIVE PATH");
            if(mapRef.current) {
                setPathsLayerActive(false)
                setPathIndicatorsLayerActive(true)
            }
        } else {
            // * new activePath has been set so hide pathIndicators layer and set activePath layers
            console.log("CHANGED ACTIVE PATH TO PATHID ", activePath);
            if(mapRef.current) {
                setPathIndicatorsLayerActive(false);
                setPathsLayerActive(true);
            }
        }
    };

    const generateCloudinaryImageUrl = async (imageFile) => {

        const data = new FormData();
        data.append("file", imageFile);
        data.append("upload_preset", "pb-media-upload-preset");
        data.append("cloud_name", "pathbreaker");

        // fetch(process.env.CLOUDINARY_UPLOAD_API_URL, {
        return fetch('https://api.cloudinary.com/v1_1/pathbreaker/image/upload', {
            method: "POST",
            body: data
        })
        .then(res => res.json())
        .then(parsed => parsed.secure_url)
        .catch(err => {
            // TODO: HANDLE THIS ERROR
            console.error("FAILED TO UPLOAD IMAGE TO CLOUDINARY: ", err);
        })
    }

    const savePathsToDB = () => {

        if(authContext.userId) {}
    }

    const loadPathDataFromDB = async (dbPaths, favorites) => {
        console.log("LOAD PATH DATA - PATHS: ", dbPaths);

        if(dbPaths.length) {
            let paths = Object.assign({}, userPaths);
            const formattedPaths = await Promise.all(dbPaths.map((path, key) => pathUtils.formatPathFromDb(path, key+1)));
            formattedPaths.forEach((path, key) => {
                paths[key+1] = path;
            })
            console.log("LOADED PATHS: ", paths);
            setUserPaths(paths);
        }

        if (favorites.length) {
            const formattedFaves = await Promise.all(favorites.map((path, key) => pathUtils.formatPathFromDb(path)));
            setFavoritePaths(formattedFaves);
        }
    }

    const signOut = () => {

        setUserPaths({})
        setActivePath(0)
        setActiveBreak(null)
        setActivePathway(null)
        setPBPaneView(null)

    }

    return (
        <UserDataContext.Provider value={{
            userPaths,
            getUserPaths,
            setUserPaths,
            favoritePaths,
            setFavoritePaths,
            activePath,
            getActivePath,
            setActivePath,
            activeSharedPath,
            setActiveSharedPath,
            activeSharedUser,
            setActiveSharedUser,
            activeBreak,
            getActiveBreak,
            setActiveBreak,
            activePathway,
            getActivePathway,
            setActivePathway,
            getPBPaneView,
            setPBPaneView,
            mapboxglRef,
            setMapboxglRef,
            initialRenderComplete,
            setInitialRenderComplete,

            mapRef,
            saveMapRef,
            getMapRef,
            

            initNewPath,
            generateNewPathId,
            getPathNameById,
            initPathwayVariables,
            deletePath,
            addNodeToPath,
            deleteNodeFromPath,
            
            addRouteToMap,
            // updateAndSavePath,

            getPathById,
            updatePathById,
            
            addSearchPopupToMap,
            
            flyToPath,
            flyToNode,
            getRoutePoints,
            updateRoutePoints,
            getMidpointsData,
            getPathwaysByPathId,

            setActivePathCreatorTab,
            getActivePathCreatorTab,

            getInfoFromCoordinates,
            rerouteThroughMidpoints,
            deleteWaypointFromMap,
            mapNewRoute,
            rerouteOnBreakReorder,
            updateNavType,
            getActiveNavType,
            updateBreakIconOnMap,
            updateSubbreakIcon,
            createNewSecret,
            getSecretsByPathId,
            addSubBreak,
            getSubBreaks,
            deleteSubBreak,
            addWaypointToMap,
            setPathsLayerActive,
            setPathIndicatorsLayerActive,
            setMapPathsDisplay,

            getPathTags,
            updatePathTags,
            getSelectedPathTagsByCategory,
            updatePathTagsByCategory,

            getPathDetailsTagOptions,
            getSelectedPathDetailsTags,
            updatePathDetailsTags,
            resizeMap,
            generateCloudinaryImageUrl,
            loadPathDataFromDB,
            signOut,
        }}>
            {props.children}
        </UserDataContext.Provider>
    )
}

export default UserDataContext;

import React, { useState, useContext, useEffect } from 'react';
import UserDataContext from '../contexts/UserDataContext';
import { SRLWrapper } from "simple-react-lightbox";
import '../styles/PathViewer.scss';
import { Fragment } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import _ from 'lodash';
import { useBreakpoints } from 'react-breakpoints-hook';

import bikeIcon from '../images/movement-icons/bike-active.png';
import boatIcon from '../images/movement-icons/boat-active.png';
import busIcon from '../images/movement-icons/bus-active.png';
import camperIcon from '../images/movement-icons/camper-active.png';
import carIcon from '../images/movement-icons/car-active.png';
import horsebackIcon from '../images/movement-icons/horseback-active.png';
import metroIcon from '../images/movement-icons/metro-active.png';
import motorbikeIcon from '../images/movement-icons/motorbike-active.png';
import planeIcon from '../images/movement-icons/plane-active.png';
import sailboatIcon from '../images/movement-icons/sailboat-active.png';
import trainIcon from '../images/movement-icons/train-active.png';
import walkIcon from '../images/movement-icons/walk-active.png';
import taxiIcon from '../images/movement-icons/taxi-active.png';

import eatIcon from '../images/location-icons/eat-inactive.png';
import exploreIcon from '../images/location-icons/explore-inactive.png';
import sleepIcon from '../images/location-icons/sleep-inactive.png';
import cultureIcon from '../images/interest-category-icons/culture.png'
import funIcon from '../images/interest-category-icons/fun.png'
import natureIcon from '../images/interest-category-icons/nature.png'
import nightlifeIcon from '../images/interest-category-icons/nightlife.png'
import recreationIcon from '../images/interest-category-icons/recreation.png'
import shoppingIcon from '../images/interest-category-icons/shopping.png'
import sightseeingIcon from '../images/interest-category-icons/sightseeing.png'
import styleIcon from '../images/interest-category-icons/style.png'
import wellnessIcon from '../images/interest-category-icons/wellness.png'
import futurePlanTag from '../images/future-plan-tag.png';
import userApi from '../utils/userApi';
import pathApi from '../utils/pathApi';
import dynamicLinks from '../utils/dynamicLinks';
import AuthContext from '../contexts/AuthContext';
import { toast, ToastContainer } from "react-toastify";
import { heicToPng } from '../utils';
import { ImageSource } from 'mapbox-gl';

const VIEWS = {
    OVERVIEW: 'overview',
    BREAK: 'break',
    PATHWAY: 'pathway'
}

const PATH_TYPES = {
    USER: 'user',
    SHARED: 'shared',
}

const BREAKICONS = {
    'explore': exploreIcon,
    'eat': eatIcon,
    'sleep': sleepIcon,
    'culture': cultureIcon,
    'fun': funIcon,
    'nature': natureIcon,
    'nightlife': nightlifeIcon,
    'recreation': recreationIcon,
    'shopping': shoppingIcon,
    'sightseeing': sightseeingIcon,
    'style': styleIcon,
    'wellness': wellnessIcon
}

const NAVICONS = {
    'bike': bikeIcon,
    'boat': boatIcon,
    'bus': busIcon,
    'camper': camperIcon,
    'car': carIcon,
    'horseback': horsebackIcon,
    'metro': metroIcon,
    'motorbikeIcon': motorbikeIcon,
    'plane': planeIcon,
    'sailboat': sailboatIcon,
    'train': trainIcon,
    'walk': walkIcon,
    'taxi': taxiIcon,
}

const PathViewer = (props) => {

    const {
        pathType, // 'user' OR 'shared'
        path,
    } = props;

    const [pathJourney, setPathJourney] = useState(null);
    const [activeView, setActiveView] = useState(VIEWS.OVERVIEW);
    const [sharedPathUser, setSharedPathUser] = useState(null);

    const userDataContext = useContext(UserDataContext);
    const {
        userId
    } = useContext(AuthContext);

    const {
        activeBreak,
        activePathway,
    } = userDataContext;

    const {
        pathName,
        caption,
        nodes,
        routePoints,
        details,
        tags,
        startDate,
        endDate,
        future,
        ongoing,
        secrets
    } = path;
    
    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    useEffect(() => {
        console.log("PATH CHANGED: ", path);
    }, [path])

    useEffect(() => {

        if (pathType === PATH_TYPES.SHARED) {

            const fetchUser = async (userId) => {
                const user = await userApi.getUserById(userId);
                setSharedPathUser(await user)
            }

            if (path.userId) {
                fetchUser(path.userId)
            }

        }
    }, []);

    useEffect(() => {
        if (nodes && nodes.length) {
            console.log("CALLED BUILD JOURNEY");
            buildJourney(nodes, routePoints);
        }
    }, [nodes])

    useEffect(() => {
        console.log("ACTIVE BREAK CHANGE: ", activeBreak);
        if(activeBreak !== null) {
            setActiveView(VIEWS.BREAK)
        } else {
            setActiveView(VIEWS.OVERVIEW);
        }
    }, [activeBreak]);

    useEffect(() => {
        console.log("ACTIVE PAtHWAY CHANGE: ", activePathway);
        if(activePathway !== null) {
            setActiveView(VIEWS.PATHWAY)
        } else {
            setActiveView(VIEWS.OVERVIEW);
        }
    }, [activePathway]);

    const buildJourney = async (breaks, pathways) => {
        let journey = [];
        for(let i = 0; i < breaks.length -1; i++) {

            let thumbnail = null;
            if (breaks[i].fields.images && breaks[i].fields.images.length) {
                thumbnail = breaks[i].fields.images[0].url;
            } else {
                thumbnail = BREAKICONS[breaks[i].fields.breakType];
            }

            journey.push({
                ...breaks[i],
                type: 'break',
                thumbnail: thumbnail,
            });
            let transport = Object.keys(pathways[i].fields.transportTypes).find((key => pathways[i].fields.transportTypes[key] === true));
            journey.push({
                ...pathways[i],
                type: 'pathway',
                transport: transport ? transport : 'car',
                navIcon: transport ? NAVICONS[transport] : NAVICONS['car'],
                from: breaks[i].placeName,
                to: breaks[i+1].placeName,
            })
        }
        let lastBreak = breaks[breaks.length - 1];
        let lastBreakHasImages = lastBreak.fields.images && lastBreak.fields.images.length ? true : false;
        journey.push({
            ...breaks[breaks.length - 1],
            type: 'break',
            thumbnail: lastBreakHasImages ? lastBreak.fields.images[0].url :  BREAKICONS[lastBreak.fields.breakType],
        });
        setPathJourney(journey);
    }

    const createBreakSelector = (break_, index) => {
        return (
            <div key={index} className="viewer-break-selector" onClick={() => userDataContext.setActiveBreak(break_.breakIndex)}>
                <div className="viewer-break-selector-image">
                    <img src={break_.thumbnail} />
                </div>
                <div className="viewer-break-selector-details">
                    {break_.fields.title ? (
                        <>
                            <div className="viewer-break-selector-title">
                                {break_.fields.title}
                            </div>
                            <div className="viewer-break-selector-data">
                                <i className="material-icons">place</i>
                                {break_.address}
                            </div>
                            {
                                break_.subbreaks && break_.subbreaks.length ? (
                                    <div className="viewer-break-selector-data">
                                        <i className="material-icons">push_pin</i>
                                        {`${break_.subbreaks.length} local spot${break_.subbreaks.length > 1 ? 's' : ''} marked`}
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </>
                    ) : (
                        <>
                            <div className="viewer-break-selector-title">
                                {break_.placeName}
                            </div>
                            <div className="viewer-break-selector-data">
                                <i className="material-icons">place</i>
                                {break_.address}
                            </div>
                            {
                                break_.subbreaks && break_.subbreaks.length ? (
                                    <div className="viewer-break-selector-data">
                                        <i className="material-icons">push_pin</i>
                                        {`${break_.subbreaks.length} local spot${break_.subbreaks.length > 1 ? 's' : ''} marked`}
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </>
                    )}
                </div>
                <div className="viewer-break-selector-open-icon">
                    <i className="material-icons">arrow_forward_ios</i>
                </div>
            </div>
        )
    }

    const createPathwaySelector = (pathway, index) => {
        return (
            <div key={index} className="viewer-break-selector" onClick={() => userDataContext.setActivePathway(pathway.navIndex)}>
                <div className="viewer-break-selector-image">
                    <img src={pathway.navIcon} />
                </div>

                <div className="viewer-break-selector-details">
                    <div className="viewer-break-selector-title">
                        {`By ${pathway.transport}`}
                    </div>
                    {
                        pathway.midpoints.length ? (
                            <div className="viewer-break-selector-data">
                                <i className="material-icons">navigation</i>
                                {`${pathway.midpoints.length} waypoint${pathway.midpoints.length > 1 ? 's' : ''}`}
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>

                <div className="viewer-break-selector-open-icon">
                    <i className="material-icons">arrow_forward_ios</i>
                </div>
            </div>
        )
    }

    const handleBackMobile = ()=>{
		userDataContext.setPBPaneView('paths');
    }

    const handleBack = () => {
        if (pathType === PATH_TYPES.USER) {
            userDataContext.setActivePath(0);
        } else if (pathType === PATH_TYPES.SHARED) {
            if (userDataContext.activeSharedUser) {
                userDataContext.setPBPaneView('user-screen');
            } else {
                userDataContext.setPBPaneView(null);
            }
            userDataContext.setActiveSharedPath(null);
        }
    }

    const hidePane = () => {
        userDataContext.setPBPaneView(null);
    }

    const clickSharedUserProfile = () => {
        userDataContext.setActiveSharedUser(sharedPathUser);
        userDataContext.setPBPaneView('user-screen');
    }

    const handleToggleFavorite = async () => {

        const favorites = [...userDataContext.favoritePaths];
        const favoritePathIdList = favorites.map(fave => fave.pid);

        if (favoritePathIdList.includes(path.pid)) {
            console.log("REMOViNG FROM FAVES")
            const updatedUser = await pathApi.removePathFromFavorites(userId, path.pid);
            if (await updatedUser.favorite_paths) {
                favorites.splice(favoritePathIdList.indexOf(path.pid), 1);
                userDataContext.setFavoritePaths(favorites);
                toast.success("Path removed from favorites");
            } else {
                toast.error("Failed to remove path from favorites");
                console.log("FAILED TO DELETE FROM FAVORITES")
            }
        } else {
            const updatedUser = await pathApi.addPathToFavorites(userId, path.pid);
            if (await updatedUser.favorite_paths) {
                favorites.push(path);
                userDataContext.setFavoritePaths(favorites);
                toast.success("Path added to favorites");
            } else {
                toast.error("Failed to add path to favorites");
                console.log("FAILED TO ADD TO FAVORITES");
            }
        }
    }

    const handleCopyShareLink = () => {
        if(path.pid) {
            const link = dynamicLinks.buildPathShareLink(path.pid)
            navigator.clipboard.writeText(link);
            toast.success("Copied link to clipboard!")

        } else {
            console.log("PATH : ", Object.assign({}, path));
            pathApi.savePathToDb(userId, path)
                .then(savedPath => {
                    const { id } = savedPath;

                    const link = dynamicLinks.buildPathShareLink(id)
                    navigator.clipboard.writeText(link);
                    toast.success("Copied link to clipboard!")

                    const { activePath, userPaths, setUserPaths } = userDataContext;

                    let paths = Object.assign({}, userPaths);
                    paths[activePath] = savedPath;
                    setUserPaths(paths);
                })
                .catch(err => {
                    console.log("FAILED TO SAVE PATH: ", err);
                    toast.error("Failed to copy link");
                })
        }
    }

    switch(activeView) {
        case VIEWS.OVERVIEW:
            let totalDetails = 0;
            Object.entries(details).forEach(([cat, values]) => totalDetails = totalDetails + values.length);

            let allTags = [];
            Object.entries(tags).forEach(([category, listOfTags], key) => {
                listOfTags.forEach(tag => allTags.push({ category, tag }));
            });

            return (
                <div className="path-viewer">
                    <div className="path-viewer-top-line">
                        <div className="path-viewer-back-btn" onClick={mobile ? handleBackMobile : handleBack}>
                            <i className="material-icons">arrow_back</i>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {future ? <label>future plan</label>: null}
                            {
                                pathType === PATH_TYPES.USER ? (
                                    <Fragment>
                                        <div className="path-viewer-share-button" onClick={handleCopyShareLink}>
                                            <i className="material-icons">share</i>
                                            share
                                        </div>

                                        { mobile ? (
                                            <div className="path-viewer-back-btn" onClick={hidePane}>
                                                <i className="material-icons">expand_more</i>
                                            </div>
                                            ) : null
                                        }
                                    </Fragment>
                                   

                                ) : pathType === PATH_TYPES.SHARED && sharedPathUser ? (
                                    <span>

                                        {userId !== sharedPathUser.id ? (
                                            <div className="path-viewer-top-right-btn" onClick={() => handleToggleFavorite()}>
                                                <i 
                                                    className="material-icons"
                                                    style={{
                                                        color: userDataContext.favoritePaths.map(fave => fave.pid).includes(path.pid)
                                                        ? 'yellow'
                                                        : 'white'
                                                    }}
                                                >star</i>
                                            </div>
                                        ) : null}
                                        <div className="path-viewer-top-right-btn user-profile-btn" onClick={() => clickSharedUserProfile()}>
                                            <img
                                                src={
                                                    sharedPathUser.profile_picture ? 
                                                    sharedPathUser.profile_picture.image_url : 
                                                    'https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true'
                                                }
                                            />
                                            {`@${sharedPathUser.username}`}
                                        </div>
                                    </span>
                                    
                                ) : null
                            }

                            
                        </div>
                    </div>
                    
                        
                    <div className="path-viewer-header">
                        <h3>{pathName}</h3>
                        {(!future && (startDate && (ongoing || endDate))) ? (
                            <span>{`${startDate ? startDate.toDateString() : ''} - ${(!ongoing && endDate) ? endDate.toDateString() : ''}`}</span>
                        ) : null }
                    </div>
                    <div className="path-viewer-content">
                        {caption ? (
                            <div className="path-viewer-caption">
                                {caption}
                            </div>
                        ) : null}
                        {allTags.length || totalDetails ? (
                            <div className="path-viewer-overview">
                                <div className="path-viewer-overview-header">Overview:</div>
                                {allTags.length ? (
                                    <TagViewer tags={allTags} />
                                    ) : null
                                }
                                {totalDetails ? (
                                    <DetailsViewer details={details} /> 
                                    ) : null
                                }
                            </div>
                        ) : null}
                        
                        <div className="path-viewer-breaks-container">
                            <div className="path-viewer-breaks-header">
                                <div>Your Path:</div>
                            </div>
                            {nodes && nodes.length ? (
                                pathJourney ? (
                                    pathJourney.map((section, index) => {
                                        return section.type === "break" ? (
                                            createBreakSelector(section, index)
                                        ) : (
                                            createPathwaySelector(section, index)
                                        )
                                    })
                                ) : (
                                    <ScaleLoader color={'white'} />
                                )
                            ) : (
                                <div className="no-breaks-display">
                                    It doesn't look like you have added any breaks to this path!
                                </div>
                            )}
                        </div>
                    </div>
                    <ToastContainer
                        position="top-left"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        draggable
                        style={{
                            position: "absolute",
                            left: 5,
                            top: 0,
                            width: 390,
                        }}
                    />
                </div>
            )
        case VIEWS.BREAK:
            return <BreakViewer
                        activeBreak={activeBreak} 
                        pathName={pathName}
                    />
        case VIEWS.PATHWAY:
            return <PathwayViewer
                        activePathway={activePathway}
                        pathName={pathName}
                    />
    }
    
}

const BreakViewer = (props) => {

    const {
        activeBreak,
        pathName
    } = props;

    const userDataContext = useContext(UserDataContext);

    const { userPaths, activePath, activeSharedPath } = userDataContext;

    const [openSubbreak, setOpenSubbreak] = useState(null);

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    useEffect(() => {
        console.log("OPEN SUBBREAK ID: ", openSubbreak);
    }, [openSubbreak])

    const currPath = activeSharedPath ? activeSharedPath : activePath ? userPaths[activePath] : null;
    const currBreak = (currPath !== null && activeBreak !== null) ? currPath.nodes[activeBreak] : null;

    return (
        <div className="path-viewer">
            {
                currBreak ? (
                    <>  
                        { mobile ? (
                            <div className="path-viewer-top-line">
                                <div />

                                <div className="path-viewer-back-btn" onClick={() => userDataContext.setPBPaneView(null)}>
                                    <i className="material-icons">expand_more</i>
                                </div>
                            </div>
                            ) 
                            : null
                        }

                        <div className="path-viewer-header" style={{ ...(!mobile && { marginTop: 20 }) }}>
                            <h3>{pathName}</h3>
                        </div>

                        <div className="path-section-viewer-header">

                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <i className="material-icons" onClick={() => userDataContext.setActiveBreak(null)}>arrow_back_ios</i>
                                <div className="path-viewer-section-subheader">
                                    {currBreak.fields.title}
                                </div>
                            </div>
                            

                            <div className="path-viewer-section align-left">

                                <h4>{currBreak.placeName}</h4>
                                
                                <div className="path-viewer-section-subdata">
                                    <i className="material-icons">place</i>
                                    {currBreak.address}
                                </div>
                                
                            </div>
                            
                        </div>

                        <div className="break-viewer-content-scroll">
                            {currBreak.fields.description ? (
                                <div className="path-section-viewer-description">
                                    {currBreak.fields.description}
                                </div>
                            ) : (
                                null
                            )}
                            {currBreak.fields.images && currBreak.fields.images.length ? (
                                <Fragment>
                                    <ImageViewer images={currBreak.fields.images} />
                                </Fragment>
                            ) : null}
                            {
                                currBreak.subbreaks && currBreak.subbreaks.length ? (
                                    <div className="path-viewer-subsection">
                                        <div className="path-viewer-section-subheader">
                                            Local Spots
                                        </div>

                                        {currBreak.subbreaks.map((subbreak, key) => (
                                            <div 
                                                key={key}
                                                className={`subbreak-viewer ${openSubbreak == key ? 'open' : ''} `}
                                                onClick={() => {
                                                    openSubbreak == key ? setOpenSubbreak(null) : setOpenSubbreak(key);
                                                    userDataContext.mapRef.current.flyTo({
                                                        center: [subbreak.long, subbreak.lat],
                                                        zoom: 18
                                                    });
                                                }}
                                            >
                                                <div className="subbreak-viewer-header">
                                                    <i className="material-icons">push_pin</i>
                                                    {subbreak.placeName}
                                                </div>

                                                <div className="subbreak-viewer-content" style={{ display: openSubbreak == key ? 'block' : 'none'}}>
                                                    <div className="subbreak-viewer-description">{subbreak.fields.description}</div>
                                                    <div className="subbreak-viewer-images">
                                                        <SRLWrapper>
                                                            {subbreak.images ? subbreak.images.map((image, key) => <img onClick={e => e.stopPropagation()} src={image.url} /> ) : null}
                                                        </SRLWrapper>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    null
                                )
                            }   
                        </div>

                        

                        
                    </>
                ) : (
                    <ScaleLoader color={"#fff"} loading={activeBreak == null} />
                )
            }
        </div>
    )

}

const PathwayViewer = (props) => {

    const { 
        activePathway,
        setActiveView,
        pathName
    } = props;

    const userDataContext = useContext(UserDataContext);

    const { userPaths, activePath, activeSharedPath } = userDataContext;

    const [openWaypoint, setOpenWaypoint] = useState(null);

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    // * TO DISPLAY:
    // * From 
    // * To
    // * Description
    // * Images
    // * Transport Type
    // * DISTANCE???
    // * Waypoints

    const currPath = activeSharedPath ? activeSharedPath : activePath ? userPaths[activePath] : null;
    const currPathway = (currPath !== null && activePathway !== null) ? currPath.routePoints[activePathway] : null;
    const navIcon = currPathway ? Object.keys(currPathway.fields.transportTypes).find((type, index) => currPathway.fields.transportTypes[type] === true) : null;
    const fromToArray = currPathway ? currPathway.label.split(" to ") : null;

    return (
        <div className="path-viewer">
            {
                currPathway ? (
                    <>

                        { mobile ? (
                            <div className="path-viewer-top-line">
                                <div />

                                <div className="path-viewer-back-btn" onClick={() => userDataContext.setPBPaneView(null)}>
                                    <i className="material-icons">expand_more</i>
                                </div>
                            </div>
                            ) 
                            : null
                        }

                        <div className="path-viewer-header" style={{ ...(!mobile && { marginTop: 20 }) }}>
                            <h3>{pathName}</h3>
                        </div>

                        <div className="path-section-viewer-header">
                            <i className="material-icons" onClick={() => {
                                userDataContext.setActivePathway(null)
                            }}>arrow_back_ios</i>

                            <div className="path-viewer-section">
                                <div className="path-viewer-section-subheader from">
                                    {fromToArray[0]}
                                </div>

                                <div className="pathway-viewer-transport">
                                    <div className="left-dashed"></div>
                                    <img className="path-viewer-nav-icon" src={NAVICONS[navIcon]} />
                                    <div className="right-dashed"></div>
                                </div>

                                <div className="path-viewer-section-subheader to">
                                    {fromToArray[1]}
                                </div>
                            </div>

                            {
                                currPathway.fields.description ? (
                                    <div className="path-section-viewer-description">
                                        {currPathway.fields.description}
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>

                        {
                            currPathway.fields.images && currPathway.fields.images.length ? (
                                <Fragment>
                                    <ImageViewer images={currPathway.fields.images} />
                                </Fragment>
                            ) : ( 
                                null 
                            )
                        }

                        {
                            currPathway.midpoints.length ? (
                                <div className="path-viewer-subsection">
                                    <div className="path-viewer-section-subheader"><b>Waypoints</b></div>
                                    {currPathway.midpoints.map((waypoint, i) => {
                                        return (
                                            <div className="waypoint-viewer" key={i}>
                                                <i className="material-icons">gps_fixed</i>
                                                {waypoint.address}
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                null
                            )
                        }
                    </>
                ) : (
                    <ScaleLoader color={"#fff"} loading={activePathway == null} />
                )
            }
        </div>
    )

}

const TagViewer = (props) => {

    const { tags } = props;

    const [viewAll, setViewAll] = useState(false);

    if(tags) {
        return (
            <div className="path-viewer-tags">
                <div className="path-viewer-section-header">
                    <div className="path-viewer-section-header-title">
                        Tags:
                    </div>
                    <div className="path-viewer-section-header-view-more" onClick={() => setViewAll(!viewAll)}>
                        {viewAll ? "Hide" : "View All"}
                    </div>
                </div>
                <div className="path-viewer-tags-container" style={{ height: (viewAll || !tags.length) ? "auto" : "38px" }}>
                    {tags.map((tag, i) => (
                        <div key={i} className={`path-viewer-tag ${tag.category}-selected`}>{tag.tag}</div>
                    ))}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

const DetailsViewer = ({ details }) => {

    const [viewAll, setViewAll] = useState(false);

    if(details) {
        return (
            <div className="path-viewer-details">
                <div className="path-viewer-section-header">
                    <div className="path-viewer-section-header-title">
                        Details:
                    </div>
                    <div className="path-viewer-section-header-view-more" onClick={() => setViewAll(!viewAll)}>
                        {viewAll ? "Hide" : "View All"}
                    </div>
                </div>
                <div className="path-viewer-details-container" style={{ height: viewAll ? "auto" : "40px"}}>
                    {Object.entries(details).map(([category, tags], key) => {
                        if(tags.length) {
                            return (
                                <div key={key} className="path-viewer-details-entry">
                                    <div className="path-viewer-details-entry-category">{category.charAt(0).toUpperCase() + category.slice(1)}</div>
                                    <div className="path-viewer-details-entry-tags">
                                        {tags.map((tag, i) => (
                                            <div key={100*key + i} className={`path-viewer-tag ${category}-selected`}>{tag}</div>
                                        ))}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    } else {
        return null;
    }


}

const ImageViewer = ({ images }) => {

    let imageView = [];

    images.forEach((img, key) => {
        imageView.push(
            <img key={key} src={img.url} />
        )
    })

    return (
        <SRLWrapper>
            <div className="image-viewer">
                {imageView}
            </div>
        </SRLWrapper>
    );
}

export default PathViewer;
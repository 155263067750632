import React, { Component, useContext, useState, useRef, useEffect, } from "react";
import UserDataContext from "../contexts/UserDataContext";
import PathCreator from "../components/PathCreator";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Carousel } from "react-responsive-carousel";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../styles/Map.scss";
import exploreIcon from "../images/map-icons/explore.png";
import exploreColor from "../images/map-icons/explore-color.png";
import exploreDefault from "../images/explore-color.png";
import eatIcon from "../images/map-icons/eat.png";
import eatColor from "../images/map-icons/eat-color.png";
import sleepIcon from "../images/map-icons/sleep.png";
import sleepColor from "../images/map-icons/sleep-color.png";
import pbIcon from "../images/location-icons/PB-icon-circle.png";
// import SearchIcon from "../images/color-search-icon.png";
import secretIcon from "../images/location-icons/secret-inactive.png";
import waypointIcon from "../images/waypoint-icon.png";
import cultureIcon from '../images/map-icons/culture.png'
import funIcon from '../images/map-icons/fun.png'
import natureIcon from '../images/map-icons/nature.png'
import nightlifeIcon from '../images/map-icons/nightlife.png'
import recreationIcon from '../images/map-icons/recreation.png'
import shoppingIcon from '../images/map-icons/shopping.png'
import sightseeingIcon from '../images/map-icons/sightseeing.png'
import styleIcon from '../images/map-icons/style.png'
import wellnessIcon from '../images/map-icons/wellness.png'
import waterIcon from '../images/map-icons/water.png'
import gymIcon from '../images/map-icons/gym.png'
import workIcon from '../images/map-icons/work.png'
import drinksIcon from '../images/map-icons/drinks.png'

import cultureColor from '../images/map-icons/culture-color.png'
import funColor from '../images/map-icons/fun-color.png'
import natureColor from '../images/map-icons/nature-color.png'
import nightlifeColor from '../images/map-icons/nightlife-color.png'
import recreationColor from '../images/map-icons/recreation-color.png'
import shoppingColor from '../images/map-icons/shopping-color.png'
import sightseeingColor from '../images/map-icons/sightseeing-color.png'
import styleColor from '../images/map-icons/style-color.png'
import wellnessColor from '../images/map-icons/wellness-color.png'
import waterColor from '../images/map-icons/water-color.png'
import gymColor from '../images/map-icons/gym-color.png'
import workColor from '../images/map-icons/work-color.png'
import drinksColor from '../images/map-icons/drinks-color.png'

import collectionIcon from "../images/map-icons/collection.png";

import { PopupButton } from '@typeform/embed-react'
import Modal from 'react-modal';
import FeedbackForm from "./FeedbackForm";
import travelerStatsApi from "../utils/travelerStatsApi";
import AuthContext from "../contexts/AuthContext";

// mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const mapCountryFillColors = [
    "#F39F88", // * pink
    "#6496DF", // * blue
    "#DA6060", // * red
    "#21585C", // * dark blue
    "#ED773E", // * orange
    "#468341", // * green
]

const MapComponent = (props) => {

    const {
        activePath,
        showScratchMap = false,
    } = props;

    const userDataContext = useContext(UserDataContext);

    const { userId } = useContext(AuthContext)

    const [language, setLanguage] = useState("");
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
    const [visitedCountryISOs, setVisitedCountryISOs] = useState(null);

    const mapContainer = useRef(null);
    const map = useRef(null);
    const searchBar = useRef(null);

    useEffect(() => {
        loadMap();
    }, [])

    useEffect(() => {
        userDataContext.setMapPathsDisplay(activePath);
    }, [activePath])

    useEffect(() => {
        if (showScratchMap && userDataContext.initialRenderComplete && userDataContext.userPaths) {
          fetchCountriesVisited(userId)
        }
    }, [userDataContext.userPaths])

    useEffect(() => {
        if (visitedCountryISOs && visitedCountryISOs.length) {
            const countryFillLayers = map.current.getStyle().layers.filter(layer => layer.id.includes("country-boundaries-fill"));
            countryFillLayers.forEach(layer => map.current.removeLayer(layer.id));

            visitedCountryISOs.forEach((iso, i) => {
                const randIndex = Math.floor(Math.random()*6);
                const fillColor = mapCountryFillColors[randIndex];

                map.current.addLayer({
                  'id': `country-boundaries-fill-${i.toString()}`,
                  'type': 'fill',
                  'source': 'country-boundaries',
                  'source-layer': 'country_boundaries',
                  'filter': [
                    'match',
                    ['get', 'iso_3166_1'],
                    [ iso ],
                    true,
                    false
                  ],
                  'maxzoom': 3,
                  'paint': {
                    "fill-color": fillColor,
                    "fill-opacity": 0.9
                  }
                }, "settlement-minor-label")
            })
        }
    }, [visitedCountryISOs])

    const fetchCountriesVisited = async (uid) => {
        const isoCodes = await travelerStatsApi.getCountryISOCodes();
        const visitedISOCodes = [];
        travelerStatsApi.getCountriesVisited(uid)
            .then(res => {
                if (res.success) {
                    res.countries.forEach(country => {
                        if (isoCodes[country]) visitedISOCodes.push(isoCodes[country])
                    })
                    setVisitedCountryISOs(visitedISOCodes)
                }
            })
            .catch(err => {
                console.log("FAILED TO GET COUNTRIES VISITED: ", err);
            })
    }

    const loadMap = async () => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/pathbreakerteam/ckqlb48gh4h0v18qzw7maqzk6",
            center: [-10, 20],
            zoom: 2,
            minZoom: 1,
        });

        map.current.resize();

        map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

        [
            { icon: "explore-icon", url: exploreIcon },
            { icon: "eat-icon", url: eatIcon },
            { icon: "sleep-icon", url: sleepIcon },
            { icon: "explore-color", url: exploreColor },
            { icon: "eat-color", url: eatColor },
            { icon: "sleep-color", url: sleepColor },
      
            { icon: "culture-icon", url: cultureIcon },
            { icon: "fun-icon", url: funIcon },
            { icon: "nature-icon", url: natureIcon },
            { icon: "nightlife-icon", url: nightlifeIcon },
            { icon: "recreation-icon", url: recreationIcon },
            { icon: "shopping-icon", url: shoppingIcon },
            { icon: "sightseeing-icon", url: sightseeingIcon },
            { icon: "style-icon", url: styleIcon },
            { icon: "wellness-icon", url: wellnessIcon },
            { icon: "work-icon", url: workIcon },
            { icon: "water-icon", url: waterIcon },
            { icon: "gym-icon", url: gymIcon },
            { icon: "drinks-icon", url: drinksIcon },
      
            { icon: "culture-color", url: cultureColor },
            { icon: "fun-color", url: funColor },
            { icon: "nature-color", url: natureColor },
            { icon: "nightlife-color", url: nightlifeColor },
            { icon: "recreation-color", url: recreationColor },
            { icon: "shopping-color", url: shoppingColor },
            { icon: "sightseeing-color", url: sightseeingColor },
            { icon: "style-color", url: styleColor },
            { icon: "wellness-color", url: wellnessColor },
            { icon: "work-color", url: workColor },
            { icon: "water-color", url: waterColor },
            { icon: "gym-color", url: gymColor },
            { icon: "drinks-color", url: drinksColor },
      
            { icon: "collection-icon", url: collectionIcon },
      
            { icon: "secret-icon", url: secretIcon },
            { icon: "waypoint", url: waypointIcon },
            { icon: "explore-default", url: exploreDefault },
            { icon: "pb-icon", url: pbIcon },
        ].forEach((obj) => {
            map.current.loadImage(obj.url, async (error, image) => {
                if (error) throw error;
                map.current.addImage(obj.icon, image);
            });
        });

        map.current.on("load", () => {

            map.current.addSource("active-path-points", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            map.current.addLayer({
                id: "active-path-points",
                type: "symbol",
                source: "active-path-points", // reference the data source
                layout: {
                    "icon-image": [
                        "match",
                        ["get", "break-type"],
                        "explore", "explore-icon",
                        "eat", "eat-icon",
                        "sleep", "sleep-icon",
                        "culture", "culture-icon",
                        "fun", "fun-icon",
                        "nature", "nature-icon",
                        "nightlife", "nightlife-icon",
                        "recreation", "recreation-icon",
                        "shopping", "shopping-icon",
                        "sightseeing", "sightseeing-icon",
                        "style", "style-icon",
                        "wellness", "wellness-icon",
                        "water", "water-icon",
                        "work", "work-icon",
                        "gym", "gym-icon",
                        "drinks", "drinks-icon",
                        "secret", "secret-icon",
                        "collection", "collection-icon",
                        "explore-default", // default
                    ],
                    "icon-size": 0.5,
                    "icon-allow-overlap": true,
                },
            });

            map.current.addSource("active-path-lines", {
                type: "geojson",
                data: {
                    type: "Feature",
                },
                lineMetrics: true,
            });

            map.current.addLayer(
                {
                    id: "active-path-lines",
                    type: "line",
                    source: "active-path-lines",
                    layout: {
                        "line-join": "round",
                        "line-cap": "round",
                    },
                    paint: {
                        "line-color": "#21585c",
                        "line-width": 6,
                        "line-opacity": 1,
                    },
                },
                "active-path-points"
            );
      
            map.current.addSource("active-path-waypoints", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });
      
            map.current.addLayer({
                id: "active-path-waypoints",
                type: "symbol",
                source: "active-path-waypoints", // reference the data source
                layout: {
                    "icon-image": "waypoint",
                    "icon-size": 0.4,
                },
            });
      
            map.current.addSource("active-subbreaks", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });

            map.current.addLayer({
                id: "active-subbreaks",
                type: "symbol",
                source: "active-subbreaks", // reference the data source
                layout: {
                    "icon-image": [
                        "match",
                        ["get", "break-type"],
                        "explore", "explore-color",
                        "eat", "eat-color",
                        "sleep", "sleep-color",
                        "culture", "culture-color",
                        "fun", "fun-color",
                        "nature", "nature-color",
                        "nightlife", "nightlife-color",
                        "recreation", "recreation-color",
                        "shopping", "shopping-color",
                        "sightseeing", "sightseeing-color",
                        "style", "style-color",
                        "wellness", "wellness-color",
                        "water", "water-color",
                        "work", "work-color",
                        "gym", "gym-color",
                        "drinks", "drinks-color",
                        "explore-color", // default
                    ],
                    "icon-size": 0.5,
                },
            });

            map.current.addSource("path-indicators", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [],
                },
            });
      
            map.current.addLayer({
                id: "path-indicators",
                type: "symbol",
                source: "path-indicators", // reference the data source
                layout: {
                    "icon-image": "pb-icon",
                    "icon-size": 0.08,
                },
            });

            map.current.addSource("country-boundaries", {
                type: 'vector',
                url: 'mapbox://mapbox.country-boundaries-v1'
            });

        });

        map.current.on("click", "path-indicators", (e) => {
            e.clickOnLayer = true;
            userDataContext.setActivePath(parseInt(e.features[0].properties.pathId));
            if (userDataContext.getPBPaneView() == null)
            userDataContext.setPBPaneView("viewer");
        });
    
        map.current.on("click", "active-path-points", (e) => {
            e.clickOnLayer = true;
            let { pathId, breakIndex } = e.features[0].properties;
            userDataContext.setPBPaneView("viewer");
            userDataContext.setActiveBreak(breakIndex);
        });
    
        map.current.on("click", "active-subbreaks", (e) => {
            e.clickOnLayer = true;
            map.current.flyTo({
                center: [e.lngLat.lng, e.lngLat.lat],
                zoom: 18
            });
        })

        map.current.on("click", async (event) => {
            if (event.clickOnLayer) return;
      
            let clickInfo = await userDataContext.getInfoFromCoordinates([
                event.lngLat.lng,
                event.lngLat.lat,
            ]);
            userDataContext.addSearchPopupToMap({
                placeName: `${clickInfo.waterLabel || clickInfo.placeName}`,
                address: `${clickInfo.waterLabel ? "Off Land Location" : clickInfo.address}`,
                country: `${clickInfo.waterLabel ? "" : clickInfo.country}`,
                long: clickInfo.coordinates[0],
                lat: clickInfo.coordinates[1],
            });
        });

        map.current.on("mouseenter", "path-indicators", () => {
            map.current.getCanvas().style.cursor = "pointer";
        });
        map.current.on("mouseleave", "path-indicators", () => {
            map.current.getCanvas().style.cursor = "";
        });
        map.current.on("mouseenter", "active-path-points", () => {
            map.current.getCanvas().style.cursor = "pointer";
        });
        map.current.on("mouseleave", "active-path-points", () => {
            map.current.getCanvas().style.cursor = "";
        });
        map.current.on("mouseenter", "active-subbreaks", () => {
            map.current.getCanvas().style.cursor = "pointer";
        });
        map.current.on("mouseleave", "active-subbreaks", () => {
            map.current.getCanvas().style.cursor = "";
        });

        
        map.current.once('idle', () => {
            userDataContext.saveMapRef(map.current, mapboxgl);
            if(!userDataContext.activePath && !userDataContext.initialRenderComplete) {
                fetchCountriesVisited(userId)
                userDataContext.setPathIndicatorsLayerActive(true);
                userDataContext.setInitialRenderComplete(true);
            }
        })

    }

    const toggleLanguage = () => {
        let lang;
        if (language == "_en") {
          lang = "";
        } else if (language == "") {
          lang = "_en";
        }

        map.current.getStyle().layers.forEach((layer) => {
            if (layer.id.indexOf("-label") > 0) {
                map.current.setLayoutProperty(layer.id, "text-field", [
                    "get",
                    `name${lang}`,
                ]);
            }
        });
        setLanguage(lang);
    };

    const recenterMap = () => {
        map.current.setPitch(0);
        map.current.setBearing(0);
        map.current.flyTo({
            center: [-10, 20],
            zoom: 2,
        });
    };

    return (
        <div id="map" className="map-container" ref={mapContainer}>
            <div className="map-ui-container">
                <div className="map-ui-left-pane">
                </div>
                <div className="map-ui-right-pane">
                    <div className="map-action-buttons">
                        <div
                          className="action-button"
                          onClick={() => setFeedbackModalOpen(true)}
                          style={{
                            color: "#21585C",
                            borderRadius: "25px",
                            fontSize: 12,
                            fontFamily: "SportingGrotesque",
                            fontWeight: "bold",
                            width: "fit-content",
                            padding: "0 1rem",
                          }}
                        >
                            Give Feedback!
                        </div>
                        <div className="action-button" onClick={toggleLanguage} >
                          <i className="material-icons">translate</i>
                        </div>
                        <div className="action-button" onClick={recenterMap}>
                          <i className="material-icons">language</i>
                        </div>
                    </div>
                </div>

                <Modal 
                    isOpen={feedbackModalOpen}
                    onRequestClose={() => setFeedbackModalOpen(false)}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            padding: '1.5rem',
                            overflow: 'hidden',
                            height: 'fit-content',
                        },
                        overlay: {
                            zIndex: 1000,
                            backgroundColor: "rgba(33, 88, 92, 0.8)",
                        },
                    }}
                >
                    <FeedbackForm
                        firestoreCollectionId={'web-app-feedback'}
                        header={'Give Feedback!'}
                        subheader={'How has your experience been using the Pathbreaker Web Application?'}
                        onSubmit={() => setFeedbackModalOpen(false)}
                    />
                </Modal>
            </div>
        </div>
    );
}

export default MapComponent;

import React, { Component, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import UserDataContext from '../contexts/UserDataContext';
import '../styles/PathCreator.scss';
import { SRLWrapper } from "simple-react-lightbox";
import ScaleLoader from 'react-spinners/ScaleLoader';
import { generateCloudflareImageUrl } from '../utils';

// component does not need a state right now
// imageList is fed in as a prop from PathCreator pane (or wherever)
// the state of the imageList is lifted through the updateImageList method
// in PathCreator, this handler will lift the state of the images to be held in PC,
// which then updates the imageList prop that is being fed in
// if ImageUpload is instantiated from another class, a custom updateImageList method will need to be fed into ImageUpload to list the state
// so that the images displayed are driven by the imageList prop

const ImageUpload = (props) => {

    const {
        imageList,
        maxImages,
        updateImageList,
        node,
        pathId,
        uniqueId
    } = props;

    const [loading, setLoading] = useState(false);

    // take target image, then push to the file array for state, and updateImageList for this node
    const uploadFiles = async (e) => {
        setLoading(true);

        let images = imageList.concat([]);

        let loopCeiling = ((maxImages - images.length) > e.target.files.length) ? e.target.files.length : maxImages - images.length;

        let imageUrls = await Promise.all(Array.from(e.target.files).slice(0, loopCeiling).map(file => generateCloudflareImageUrl(file)))
        imageUrls = imageUrls.reduce((result, img) => {
            return img.success ? result.concat({ url: img.image_url[0] }) : result;
        }, [])
        images = images.concat(imageUrls);
        updateImageList(images, node, () => setLoading(false));
    }

    const deleteImage = (e) => {
        console.log("DELETE IMAGE: ", parseInt(e.target.attributes[1].value));
        let images = imageList.concat([]);
        images.splice(parseInt(e.target.attributes[1].value), 1);
        updateImageList(images, node, () => setLoading(false));
    }

    let addIcon = (<i className="material-icons">add</i>);
    let deleteIcon = (<i className="material-icons">clear</i>);

    return (

        <div className="image-upload-container">
            <SRLWrapper>
                <div className="images-container">
                    {imageList && imageList.map((image, index) => (
                        <div className="image-container" key={index}>
                            <span className="delete-image" value={index} >{<i className="material-icons" onClick={deleteImage} value={index}>clear</i>}</span>
                            <img src={image.url} className="image-upload-square" />
                        </div>
                        
                    ))}
                </div>
            </SRLWrapper>
            
            {imageList && imageList.length < 5 ?
                loading ? (
                    <div 
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 70,
                            height: 70
                        }}
                    >
                        <ScaleLoader color={"#fff"} loading={loading} size={12} />
                    </div>
                ) : (

                    <div className="image-upload-square image-upload-btn">
                        <label className="image-upload-icon" htmlFor={`image-upload-${pathId}-${uniqueId}`}>
                            {addIcon}
                        </label>
                        <input type="file" accept="image/*" id={`image-upload-${pathId}-${uniqueId}`} style={{display: 'none', visibility: 'none'}} onChange={uploadFiles} multiple />
                    </div> 

                ) : null
            }
        </div>
    )
}

// "node" needs to provide access to a way for this ImageUpload instance to identify itself and where it is going to save its images to
// this could change from "node" to some other form of identifier -- once finalized, add to propTypes
ImageUpload.propTypes = {
    updateImageList: PropTypes.func.isRequired,
    imageList: PropTypes.array.isRequired,
}

export default ImageUpload;

import firebase from 'firebase';
import { getFirestore } from 'firebase/firestore';
// import { OAuthProvider, getAuth, signUpWithPopup } from 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "pathbreaker-ba166.firebaseapp.com",
    projectId: "pathbreaker-ba166",
    storageBucket: "pathbreaker-ba166.appspot.com",
    messagingSenderId: "382866239914",
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: "G-X4T25MGQMG"
};

const app = firebase.initializeApp(firebaseConfig);

export default firebase;
// export const auth = firebase.auth();
// export const firestore = firebase.firestore();
// export const storage = firebase.storage;


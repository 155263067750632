import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserDataStore } from './contexts/UserDataContext';
import { ProfileStore } from './contexts/ProfileContext';
import { AuthStore } from './contexts/AuthContext';
import SimpleReactLightbox from "simple-react-lightbox";
import TagManager from 'react-gtm-module'
import './styles/index.css';
import 'semantic-ui-css/semantic.min.css';

// -------- GOOGLE TAG MANAGER --------
const tagManagerArgs = {
  gtmId: 'GTM-TDLZ5SN'
}

TagManager.initialize(tagManagerArgs);

// -------- END GOOGLE TAG MANAGER --------

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthStore>
        <ProfileStore>
          <UserDataStore>
              <SimpleReactLightbox>
                <App />
              </SimpleReactLightbox>
          </UserDataStore>
        </ProfileStore>
      </AuthStore>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

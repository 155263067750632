import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/Accordion.scss';


// INSTRUCTIONS FOR USE:
// Use <AccordionNew> as a wrapper tag
// provide each section as a child element
// each section needs a <div> parent wrapper with a label prop (this will be the title label of each section)
// each <div label="label"> wrapper should contain its content as a child - provide any content you want (react component, html, etc.)
const AccordionNew = (props) => {

    // props.children[0].props.label
    const [openSection, setOpenSection] = useState('');
    const [numSections, setNumSections] = useState(0);
    // for multiple open at a time...
    // const [openSections, setOpenSections] = useState({})

    const onClickSection = (label) => {

        if(!props.disableContent) {
            if(label == openSection) {
                setOpenSection('');
            } else {
                setOpenSection(label);
            }
        }

        // for multiple open at a time...
        // const isOpen = !!openSections[label]
        // openSections[label] = !isOpen
        // setOpenSections[openSections]

    }

    const onDragStart = (event, item) => {
        event.dataTransfer.setData("info", JSON.stringify(item));
        event.dataTransfer.dropEffect = "move";
        console.log("DRAG START: ", event, item);
    }

    const onDragOver = (event) => {
        event.preventDefault();
    }

    const onDrop = (event, info) => {
        let data = JSON.parse(event.dataTransfer.getData("info"));
        props.onDrop(data, info);
    }

    useEffect(() => {
        if(props.children && props.children[0] && props.children.length > 0 && props.allowCustomClickHeader) {
            props.clickHeaderHandler(props.children[0].props.info);
        }
    }, []);

    // useEffect(() => {
    //     if(props.children && props.children.length > numSections) {
    //         console.log("NEW ACCORDION ADDITION: ", props.children);
    //         setNumSections(props.children.length);
    //         let [ newSection ] = props.children.splice(-1);
    //         console.log(newSection)
    //         setOpenSection(newSection.props.label); 
    //     }
    // }, [props.children.length])

    return (
        <div className="accordion-container"
            onDragOver={props.draggable ? (event) => onDragOver(event) : null}
        >
            {React.Children.map(props.children, (child) => {
                if(child) {
                    return (
                        <AccordionSection
                            isOpen={openSection === child.props.label}
                            label={child.props.label}
                            info={child.props.info}
                            onClick={onClickSection}
                            allowDeleteRow={props.allowDeleteRow}
                            deleteRowHandler={props.allowDeleteRow ? props.deleteRowHandler : undefined}
                            allowCustomClickHeader={props.allowCustomClickHeader}
                            clickHeaderHandler={props.allowCustomClickHeader ? props.clickHeaderHandler : undefined}
                            disableContent={props.disableContent}
                            classAddition={props.classAddition}
                            draggable={props.draggable || false}
                            onDragStart={props.draggable ? onDragStart : () => {}}
                            onDrop={props.draggable ? onDrop : () => {}}
                            style={props.style || ''}
                            iconLabel={props.iconLabel || null}
                        >
                            {!props.disableContent && child.props.children}
                        </AccordionSection>
                    )
                }
                
            })}
        </div>
    );
}

const AccordionSection = (props) => {

    const onClick = () => {
        props.onClick(props.label);
        if(props.allowCustomClickHeader) {
            props.clickHeaderHandler(props.info);
        }
    }

    const deleteRow = () => {
        props.deleteRowHandler(props.info);
    }

    let className = `accordion-section ${props.style}`;
    if(props.isOpen) {
        className += ' accordion-section-open'
    }
    let labelClassname = 'accordion-section-label';
    if (props.classAddition) {
      labelClassname += props.classAddition;
    }

    let deleteIcon = (<i className="material-icons">clear</i>);

    return (
        <div className={className} 
            key={props.info.index} 
            draggable={props.draggable} 
            onDragStart={props.draggable ? (event) => props.onDragStart(event, props.info) : null}
            onDrop={props.draggable ? (event) => props.onDrop(event, props.info) : null}
        >
            <div className={`accordion-section-header`} key={props.info.index}>
                <div className={labelClassname} onClick={onClick} key={props.info.index}>
                    <div>
                        {props.iconLabel && <img src={props.iconLabel} style={{ marginRight: '0.5em'}}width={30} height={30} />}
                        {props.label}
                    </div>
                    {!props.disableContent &&
                        <div className="accordion-icon">
                            {!props.isOpen && <span>&#9650;</span>}
                            {props.isOpen && <span>&#9660;</span>}
                        </div>
                    }
                </div>
                {props.allowDeleteRow &&
                    <div className="accordion-delete-icon" onClick={deleteRow}>
                        {deleteIcon}
                    </div>
                }

            </div>

            {!props.disableContent &&
                <div className="accordion-content" style={{display: `${props.isOpen ? '' : 'none'}`}}>
                    {props.children}
                </div>
            }
        </div>
    );
}

AccordionNew.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
}

AccordionSection.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default AccordionNew;

import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";

import PBLogoSmall from "../images/pb-logo-latest-white.png";
import PBLogoLarge from "../images/pathbreaker-logo-latest-white.png";
import CreatePathIcon from "../images/white_center_add_path.png";
import MyPathsIcon from "../images/path_logo_color.png";
import UserDataContext from "../contexts/UserDataContext";
import ProfileContext from "../contexts/ProfileContext";
import AuthContext from "../contexts/AuthContext";

import CreatePathActiveIcon from "../images/color_center_add_path.png";
import CreatePathIconMobile from "../images/mono_color_add_path.png";
import MyPathsActiveIcon from "../images/path_logo_color.png";
import MyPathsIconMobile from "../images/path_logo_mono_color.png";

import PathCreator from "../components/PathCreator";
import PathViewer from "../components/PathViewer";
import ProfileEditor from "../components/ProfileEditor";
import MyPaths from "./MyPaths";
import Settings from "./Settings";
import { useBreakpoints } from "react-breakpoints-hook";
import ScaleLoader from 'react-spinners/ScaleLoader';

import "../styles/SideBar.scss";
import "../styles/General.scss";
import "../styles/Bottombar.scss";
import LogOutButton from "./LogOutButton";
import { Icon } from "semantic-ui-react";
import _ from "lodash";
import UserViewer from "./UserViewer";
import pathApi from "../utils/pathApi";
import dynamicLinks from "../utils/dynamicLinks";

import { toast } from "react-toastify";

const SideBar = (props) => {
    const { activePath } = props;

    const userDataContext = useContext(UserDataContext);
    const profileContext = useContext(ProfileContext);
    const authContext = useContext(AuthContext);

    const [fullSize, setFullSize] = useState(false);
    const [myPathsView, setMyPathsView] = useState(false);
    const [favoritesView, setFavoritesView] = useState(false);
    const [pathToDelete, setPathToDelete] = useState(0);
    const [pathToUnfavorite, setPathToUnfavorite] = useState(null);
    const [confirmPathSave, setConfirmPathSave] = useState(false);
    const [confirmLogout, setConfirmLogout] = useState(false);
    const [logoutState, setLogoutState] = useState(false);

    const prevFullSize = useRef();

    let { xs } = useBreakpoints({
        xs: { min: 0, max: 600 },
    });

    useEffect(() => {
        prevFullSize.current = fullSize;
    });

    useEffect(() => {
        if (prevFullSize !== fullSize && !fullSize && userDataContext.mapRef && userDataContext.mapRef.current) {
            setTimeout(() => {
                userDataContext.mapRef.current.resize();
            }, 500);
        }
    }, [fullSize]);

    useEffect(() => {
        if (!xs) {
            userDataContext.setPBPaneView(paneType.NONE);
        }
    }, [xs]);

    const paneType = {
        EDITOR: "editor",
        VIEWER: "viewer",
        PROFILE: "profile", // current user profile editor
        PATH: "paths",
        USER: "user-screen", // shared user profile page
        SETTINGS: "settings",
        NONE: null,
    };

    const createPathHandler = () => {
        let pathId = Object.keys(userDataContext.getUserPaths()).length + 1;
        let newPathName = `NEW PATH ${pathId}`;
        userDataContext.initNewPath(pathId, newPathName);
        userDataContext.setActiveBreak(null);
        userDataContext.setActivePathway(null);

        const paneView = userDataContext.getPBPaneView();

        if (paneView == paneType.EDITOR) {
            setConfirmPathSave({
                nextPathId: pathId,
                nextPane: paneType.EDITOR,
            });
            return;
        } else if (paneView == paneType.PROFILE) {
            profileContext.saveToDB();
        }

        userDataContext.setActivePath(pathId);
        userDataContext.setPBPaneView(paneType.EDITOR);
    };

    const myPathsHandler = () => {
        if (!fullSize) {
            setFullSize(true);
            if (myPathsView) {
                return;
            }
        }
        setFavoritesView(false);
        setMyPathsView(!myPathsView);
    };

    const myPathPaneHandler = () => {
        if (xs) {
            userDataContext.setPBPaneView(paneType.PATH);
        }
    };

    const favoritesHandler = () => {
        if (!fullSize) {
            setFullSize(true);
            if (favoritesView) {
                return;
            }
        }
        setMyPathsView(false);
        setFavoritesView(!favoritesView);
    }

    const setActivePathView = (e) => {
        const pathId = parseInt(e.currentTarget.attributes[1].value);
        const activePane = userDataContext.getPBPaneView();

        if (userDataContext.getActivePath() == pathId && activePane == paneType.VIEWER) {
            userDataContext.setActivePath(0);
            userDataContext.setPBPaneView(paneType.NONE);
        } else {
            userDataContext.setActiveBreak(null);
            userDataContext.setActivePathway(null);

            // * if editor is active, set confirm save path with next path id and next screen as viewer
            if (activePane == paneType.EDITOR) {
                setConfirmPathSave({
                    nextPathId: pathId,
                    nextPane: paneType.VIEWER,
                });
                return;
            }

            if (activePane == paneType.PROFILE) profileContext.saveToDB();

            userDataContext.setActivePath(pathId);
            userDataContext.setPBPaneView(paneType.VIEWER);
        }
    };

    const deletePathHandler = (e) => {
        let pathId = e.currentTarget.attributes[1].value;
        if (!pathToDelete) {
            setPathToDelete(pathId);
        } else {
            userDataContext.deletePath(pathToDelete);
            setPathToDelete(0);
        }
    };

    const setActiveFavoritePathView = (e) => {
        const pathIndex = parseInt(e.currentTarget.attributes[1].value);
        const path = userDataContext.favoritePaths[pathIndex];
        const activePane = userDataContext.getPBPaneView();

        if(userDataContext.activeSharedPath && userDataContext.activeSharedPath.pid === path.pid && activePane === paneType.VIEWER) {
            userDataContext.setActiveSharedPath(null);
            userDataContext.setPBPaneView(paneType.NONE);
        } else {
            userDataContext.setActiveBreak(null);
            userDataContext.setActivePathway(null);

            // * if editor is active, set confirm save path with next path id and next screen as viewer
            if (activePane == paneType.EDITOR) {
                setConfirmPathSave({
                    nextSharedPath: path,
                    nextPane: paneType.VIEWER,
                });
                return;
            }

            if (activePane == paneType.PROFILE) profileContext.saveToDB();

            userDataContext.setActiveSharedPath(path);
        }
    }

    const removeFromFavorites = (e) => {
        let pathIndex = e.currentTarget.attributes[1].value;
        if (pathToUnfavorite === null) {
            setPathToUnfavorite(pathIndex);
        } else {
            const pathId = userDataContext.favoritePaths[pathToUnfavorite].pid;
            pathApi.removePathFromFavorites(authContext.userId, pathId)
                .then(res => {
                    let favorites = [...userDataContext.favoritePaths];
                    favorites.splice(favorites.map(fave => fave.pid).indexOf(pathId), 1);
                    userDataContext.setFavoritePaths(favorites);
                    setPathToUnfavorite(null);
                })
            
        }
    }

    const setActivePathEditor = (e) => {
        const pathId = parseInt(e.currentTarget.attributes[1].value);
        const activePane = userDataContext.getPBPaneView();

        userDataContext.setActiveBreak(null);
        userDataContext.setActivePathway(null);

        // * if editor is active, if pathId is not equal to activePath, set confirmSavePath to be new pathId
        if (activePane == paneType.EDITOR && pathId !== activePath) {
            setConfirmPathSave({
                nextPathId: pathId,
            });
            return;
        } else if (activePane == paneType.PROFILE) profileContext.saveToDB();

        userDataContext.setActivePath(pathId);
        userDataContext.setPBPaneView(paneType.EDITOR);
        // userDataContext.flyToPath(pathId);
    };

    const toggleProfileEditor = () => {
        const paneView = userDataContext.getPBPaneView();
        if (!paneView) {
            userDataContext.setPBPaneView(paneType.PROFILE);
        } else {
            userDataContext.setActiveBreak(null);
            userDataContext.setActivePathway(null);

            if (paneView == paneType.EDITOR) {
                setConfirmPathSave({
                  nextPane: paneType.PROFILE,
                });
                return;
            } else if (paneView == paneType.VIEWER) {
                userDataContext.setPBPaneView(paneType.PROFILE);
                userDataContext.setActivePath(0);
            } else if (paneView == paneType.PATH || paneView == paneType.SETTINGS || paneView == paneType.USER) {
                userDataContext.setPBPaneView(paneType.PROFILE);
            }
        }
    };

    const sharePathHandler = (e) => {
        const pathIndex = parseInt(e.currentTarget.attributes[1].value);
        const path = Object.assign({}, userDataContext.getUserPaths()[pathIndex]);
        if(path.pid) {

            const link = dynamicLinks.buildPathShareLink(path.pid);
            navigator.clipboard.writeText(link);
            toast.success("Copied link to clipboard!")

        } else {
            console.log("PATH : ", Object.assign({}, path));
            pathApi.savePathToDb(authContext.userId, path)
                .then(savedPath => {
                    const { id } = savedPath;

                    const link = dynamicLinks.buildPathShareLink(id);
                    navigator.clipboard.writeText(link);
                    toast.success("Copied link to clipboard!")

                    const { activePath, userPaths, setUserPaths } = userDataContext;

                    let paths = Object.assign({}, userPaths);
                    paths[activePath] = savedPath;
                    setUserPaths(paths);
                })
                .catch(err => {
                    console.log("FAILED TO SAVE PATH: ", err);
                    toast.error("Failed to copy link");
                })
        }
    }

    const settingsHandler = () => {
        userDataContext.setPBPaneView(paneType.SETTINGS);
    }

    const toggleFullSize = () => {
        setFullSize(!fullSize);
    };

    const getSideBarWidth = () => {
        if (fullSize) return xs ? "0" : "300px";
        else return xs ? "0" : "100px";
    };

    const getBottomBarHeight = () => {
        let height = "58vh";
        if (userDataContext.getPBPaneView() === "profile") {
            height = "calc(100vh - 59px)";
        }
        return xs && pbPane ? height : "";
    };

    const getBottomBarMargin = () => {
        return xs && pbPane ? "60px" : "";
    };

    const getPBPaneWidth = () => {
        if (userDataContext.getPBPaneView()) return xs ? "100vw" : "400px";
        else return "0";
    };

    let pbPane;

    switch (userDataContext.getPBPaneView()) {
        case paneType.EDITOR:
            pbPane = (
                <PathCreator
                    confirmPathSave={confirmPathSave}
                    setConfirmPathSave={setConfirmPathSave}
                />
            );
            break;
        case paneType.VIEWER:
            pbPane = userDataContext.getActivePath() ? (
                <PathViewer
                    pathType={'user'}
                    path={userDataContext.getUserPaths()[userDataContext.getActivePath()]}
                />
            ) : userDataContext.activeSharedPath ? (
                <PathViewer
                    pathType={'shared'}
                    path={userDataContext.activeSharedPath}
                />
            ) : <ScaleLoader />;
            break;
        case paneType.PROFILE:
            pbPane = <ProfileEditor />;
            break;
        case paneType.PATH:
            pbPane = (
                <MyPaths
                    myPathsView={myPathsView}
                    pathToDelete={pathToDelete}
                    deletePathHandler={deletePathHandler}
                    setActivePathView={setActivePathView}
                    setPathToDelete={setPathToDelete}
                    setActivePathEditor={setActivePathEditor}
                    paneType={paneType}
                />
            );
            break;
        case paneType.USER:
            pbPane = (
                <UserViewer 
                    user={userDataContext.activeSharedUser}
                />
            )
            break;
        case paneType.SETTINGS:
            pbPane = (
                <Settings />
            )
    }

    return (
        // * LOGO
        // * TOGGLE FULL SIZE
        // * CREATE NEW PATH
        // * MY PATHS ACCORDION
        // * USER ICON -- SIGN IN BUTTON IF NOT SIGNED IN, IF SIGNED IN USER ICON BUTTON WITH LINK TO PROFILE -- SIGNOUT IN FULL SIZE

        <>
            {xs ? (
                <div className="bottomBar">
                    <div
                        className={`${confirmLogout ? "hide" : "bottomBar-item"}`}
                        onClick={createPathHandler}
                        // onClick={() => BottomBarItemClickHandler(paneType.EDITOR)}
                    >
                        {
                            userDataContext.getPBPaneView() === paneType.EDITOR ? (
                                <img src={CreatePathActiveIcon} alt="create path active icon" />
                            ) : (
                                <img src={CreatePathIconMobile} alt="create path icon" />
                            )
                        }
                    </div>

                    <div
                        className={`${confirmLogout ? "hide" : "bottomBar-item"}`}
                        onClick={myPathPaneHandler}
                        // onClick={() => BottomBarItemClickHandler(paneType.VIEWER)}
                    >
                        {userDataContext.getPBPaneView() === paneType.PATH ? (
                            <img src={MyPathsActiveIcon} alt="active my path icon" />
                        ) : (
                            <img src={MyPathsIconMobile} alt="my path icon" />
                        )}
                    </div>

                    <div className={`${confirmLogout ? "hide" : "bottomBar-item"}`}>
                        <div
                            className="sidebar-profile-picture"
                            onClick={toggleProfileEditor}
                        >
                            {profileContext.getProfilePicture() ? (
                                <img
                                    src={profileContext.getProfilePicture()}
                                    alt="profile icon"
                                />
                            ) : (
                                <Icon name="user" size="mini" />
                            )}
                        </div>
                    </div>
                    <div
                        className={`${
                            confirmLogout ? "bottomBar-logout-item" : "bottomBar-item"
                        }`}
                    >
                        <LogOutButton
                            // setAuthModalOpen={setAuthModalOpen}
                            toggleProfileEditor={(open) =>
                                open
                                    ? userDataContext.setPBPaneView(paneType.PROFILE)
                                    : userDataContext.setPBPaneView(paneType.NONE)
                            }
                            activePBPane={userDataContext.getPBPaneView()}
                            confirmLogout={confirmLogout}
                            setConfirmLogout={setConfirmLogout}
                        />
                    </div>
                </div>
            ) : (
                <div
                    id="sidebar"
                    className={`sidebar ${fullSize ? "sidebar-large" : "sidebar-small"}`}
                    onMouseEnter={toggleFullSize}
                    onMouseLeave={toggleFullSize}
                >
                    <div className="pb-logo">
                        {fullSize ? (
                            <div className="pb-logo-large">
                                <img src={PBLogoLarge} />
                            </div>
                        ) : (
                            <div className="pb-logo-small">
                                <img src={PBLogoSmall} />
                            </div>
                        )}
                      </div>

                    <div className="sidebar-item" onClick={createPathHandler}>
                        <img src={CreatePathIcon} />
                        <span>Create New Path</span>
                    </div>

                    <div className="sidebar-item my-paths" onClick={myPathsHandler}>
                        <img src={MyPathsIcon} />
                        <span>My Paths</span>
                    </div>

                    {myPathsView && fullSize && (
                        <div className="my-paths-selectors">
                            {Object.entries(userDataContext.getUserPaths()).map(
                                ([pathId, path], key) => (
                                    <div className="path-selector" key={key}>
                                        <div
                                            className="path-select-button"
                                            value={pathId}
                                            onClickCapture={
                                                pathToDelete == pathId ? null : setActivePathView
                                            }
                                        >
                                            <label>
                                                {pathToDelete == pathId
                                                    ? "Confirm Delete?"
                                                    : path.pathName}
                                            </label>
                                        </div>
                                        <div className="path-selector-actions">
                                            <div
                                                className="edit-path-button"
                                                value={pathId}
                                                onClickCapture={
                                                    pathToDelete == pathId
                                                        ? () => setPathToDelete(0)
                                                        : setActivePathEditor
                                                }
                                            >
                                                <i className="material-icons">
                                                    {pathToDelete == pathId ? "clear" : "edit"}
                                                </i>
                                            </div>
                                            {pathToDelete == pathId ? null : (
                                                <div
                                                    className="share-path-button"
                                                    value={pathId}
                                                    onClickCapture={sharePathHandler}
                                                >
                                                    <i className="material-icons">share</i>
                                                </div>
                                            )}
                                            <div
                                                className="delete-path-button"
                                                value={pathId}
                                                onClickCapture={deletePathHandler}
                                            >
                                                <i className="material-icons">
                                                    {pathToDelete == pathId ? "done" : "delete"}
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}

                    <div className="sidebar-item favorites" onClick={favoritesHandler}>
                        <i className="material-icons">star</i>
                        <span>Favorites</span>
                    </div>

                    {favoritesView && fullSize && (
                        <div className="favorites-selectors">
                            {userDataContext.favoritePaths.map(
                                (path, key) => (
                                    <div className="path-selector" key={key}>
                                        <div
                                            className="path-select-button"
                                            value={key}
                                            onClickCapture={
                                                pathToUnfavorite == key ? null : setActiveFavoritePathView
                                            }
                                        >
                                            {pathToUnfavorite == key
                                                ? "Remove from favorites?"
                                                : path.pathName
                                            }
                                        </div>
                                        <div 
                                            className="path-selector-actions"
                                            style={{
                                                width: pathToUnfavorite === null ? 50 : 100
                                            }}
                                        >
                                            {pathToUnfavorite == key ? (
                                                <div
                                                    className="unfavorite-button"
                                                    value={key}
                                                    onClickCapture={() => setPathToUnfavorite(null)}
                                                >
                                                    <i className="material-icons">clear</i>
                                                </div>
                                            ) : null}
                                            <div
                                                className="unfavorite-button"
                                                value={key}
                                                onClickCapture={removeFromFavorites}
                                            >
                                                <i 
                                                    className="material-icons"
                                                    style={{
                                                        color: pathToUnfavorite == key ? 'white' : 'yellow'
                                                    }}
                                                >
                                                    {pathToUnfavorite == key ? "done" : "star"}
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}

                    <div className="bottom">

                        <div className="sidebar-item settings" onClick={settingsHandler}>
                            <i className="material-icons">settings</i>
                            <span>Settings</span>
                        </div>

                        {authContext.userId ? (
                            <div className="sidebar-item" onClick={toggleProfileEditor}>
                                <div className="sidebar-profile-picture">
                                    {profileContext.getProfilePicture() ? (
                                        <img src={profileContext.getProfilePicture()} />
                                    ) : (
                                        <Icon name="user" size="mini" />
                                    )}
                                </div>
                                <div className="profile-snippet">
                                    {profileContext.username || profileContext.name ? (
                                        <>
                                            {profileContext.username && (
                                                <div className="profile-snippet-username">{`@${profileContext.username}`}</div>
                                            )}
                                            {profileContext.name && (
                                                <div className="profile-snippet-name">
                                                    {profileContext.name}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>Profile</>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className="sidebar-item">
                            <LogOutButton
                                // setAuthModalOpen={setAuthModalOpen}
                                toggleProfileEditor={(open) =>
                                    open
                                        ? userDataContext.setPBPaneView(paneType.PROFILE)
                                        : userDataContext.setPBPaneView(paneType.NONE)
                                }
                                activePBPane={userDataContext.getPBPaneView()}
                                sidebarStateOpen={fullSize}
                                confirmLogout={confirmLogout}
                                setConfirmLogout={setConfirmLogout}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div
                className={`pathbreaker-pane`}
                style={{
                    transition: xs ? "none" : "margin 0.5s ease, width 0.2s linear",
                    width: getPBPaneWidth(),
                    marginLeft: !xs ? getSideBarWidth() : "auto",
                    height: getBottomBarHeight(),
                    marginBottom: getBottomBarMargin(),
                }}
            >
                {pbPane}
            </div>
        </>
    );
};

export default SideBar;
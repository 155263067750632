import React, { useContext } from "react";
import "../styles/MyPaths.scss";
import UserDataContext from "../contexts/UserDataContext";
import CreatePathIconMobile from "../images/mono_color_add_path.png";
const MyPaths = ({
  pathToDelete,
  deletePathHandler,
  setActivePathView,
  setPathToDelete,
  setActivePathEditor,
  paneType,
}) => {
  const userDataContext = useContext(UserDataContext);

  const handleBack = () => {
    userDataContext.setPBPaneView(paneType.NONE);
  };

  const createPathHandler = () => {
    let pathId = Object.keys(userDataContext.getUserPaths()).length + 1;
    let newPathName = `NEW PATH ${pathId}`;
    userDataContext.initNewPath(pathId, newPathName);
    userDataContext.setActiveBreak(null);
    userDataContext.setActivePathway(null);

    const paneView = userDataContext.getPBPaneView();

    userDataContext.setActivePath(pathId);
    userDataContext.setPBPaneView(paneType.EDITOR);
  };

  return (
    <div className="my-paths-selectors-pane">
      <div className="my-paths-header">
        <div>
          My Paths
        </div>

        <div className="path-header-action-button" onClick={handleBack}>
          <i className="material-icons back-icon">expand_more</i>
        </div>
      </div>

      <div className="my-paths-list">

        {Object.entries(userDataContext.getUserPaths()).length > 0 ? (
          Object.entries(userDataContext.getUserPaths()).map(
            ([pathId, path], key) => (
              <div className="path-selector" key={key}>
                <div
                  className="path-select-button"
                  value={pathId}
                  onClickCapture={
                    pathToDelete == pathId ? null : setActivePathView
                  }
                >
                  {pathToDelete == pathId ? "Confirm Delete?" : path.pathName}
                </div>
                <div className="path-selector-actions">
                  <div
                    className="edit-path-button"
                    value={pathId}
                    onClickCapture={
                      pathToDelete == pathId
                        ? () => setPathToDelete(0)
                        : setActivePathEditor
                    }
                  >
                    <i className="material-icons">
                      {pathToDelete == pathId ? "clear" : "edit"}
                    </i>
                  </div>
                  <div
                    className="delete-path-button"
                    value={pathId}
                    onClickCapture={deletePathHandler}
                  >
                    <i className="material-icons">
                      {pathToDelete == pathId ? "done" : "delete"}
                    </i>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <div className="notFound">
            <p>No paths yet. To create a path go to</p>
            <div>
              <img src={CreatePathIconMobile} />
            </div>

            {/* button */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPaths;

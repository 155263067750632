const getCountryFlags = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/country_flags/`)
            .then(res => res.json())
            .then(flags => resolve(flags))
            .catch(err => reject(err))
    });
}

const getCountryISOCodes = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/country_iso/`)
            .then(res => res.json())
            .then(isoCodes => resolve(isoCodes))
            .catch(err => reject(err))
    });
}

const getCountriesVisited = async (userId) => {

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${userId}/countries/`)
            .then(res => res.json())
            .then(response => {
                resolve(response)
            })
            .catch(err => reject(err))
    })
}

const getTopTransportMethods = async (userId) => {

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${userId}/transport/`)
            .then(res => res.json())
            .then(response => {
                resolve(response)
            })
            .catch(err => reject(err))
    })
}

const getTopBreakTypesUsed = async (userId) => {

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${userId}/break_types/`)
            .then(res => res.json())
            .then(response => {
                resolve(response)
            })
            .catch(err => reject(err))
    })
}

const travelerStatsApi = {
    getCountryFlags,
    getCountryISOCodes,
    getCountriesVisited,
    getTopTransportMethods,
    getTopBreakTypesUsed,
}

export default travelerStatsApi;
const getPathById = (pathId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/paths/${pathId}`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(response => resolve(response))
            .catch(err => reject(err));
    })
}

const getPathsByUserId = async (userId) => {
  return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/paths?uid=${userId}`)
          .then(res => res.json())
          .then(paths => resolve(paths))
          .catch(err => reject(err))
  });
}

const addPathToFavorites = async (userId, pathId) => {

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/favorites/${userId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                path_id: pathId
            })
        })
            .then(res => res.json())
            .then(user => resolve(user))
            .catch(err => reject(err));
    })
}

const removePathFromFavorites = async (userId, pathId) => {

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/favorites/${userId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                path_id: pathId
            })
        })
            .then(res => res.json())
            .then(user => resolve(user))
            .catch(err => reject(err));
    })
}

const savePathToDb = async (userId, path) => {
    const breaks = path.nodes.map((break_) => {
        return {
          latitude: break_.lat,
          longitude: break_.long,
          address: break_.address,
          place_name: break_.placeName,
          country: break_.country,
          break_index: break_.breakIndex,
          break_type: break_.fields.breakType,
          ...(break_.fields.title && { title: break_.fields.title }),
          ...(break_.fields.description && {
            description: break_.fields.description,
          }),
          ...(break_.fields.images.length && { images: break_.fields.images }),
          ...(break_.id && { id: break_.id }),
          ...(break_.subbreaks &&
            break_.subbreaks.length && {
              subbreaks: break_.subbreaks.map((subbreak, key) => {
                return {
                  longitude: subbreak.long,
                  latitude: subbreak.lat,
                  address: subbreak.address,
                  place_name: subbreak.placeName,
                  country: subbreak.country,
                  subbreak_index: key,
                  break_type: subbreak.fields.breakType,
                  description: subbreak.fields.description,
                  ...(subbreak.images && subbreak.images.length && {
                    images: subbreak.images,
                  }),
                  ...(subbreak.id && { id: subbreak.id }),
                };
              }),
            }),
        };
    });

    const pathways = path.routePoints.map((pathway) => {
        return {
          pathway_index: pathway.navIndex,
          label: pathway.label,
          start_lng: pathway.startCoords[0],
          start_lat: pathway.startCoords[1],
          end_lng: pathway.endCoords[0],
          end_lat: pathway.endCoords[1],
          nav_type: pathway.navType,
          description: pathway.fields.description,
          transport_types: pathway.fields.transportTypes,
          ...(pathway.fields.images.length && {
            images: pathway.fields.images,
          }),
          ...(pathway.id && { id: pathway.id }),
          ...(pathway.midpoints &&
            pathway.midpoints.length && {
              waypoints: pathway.midpoints.map((waypoint, key) => {
                return {
                  longitude: waypoint.coordinates[0],
                  latitude: waypoint.coordinates[1],
                  waypoint_index: key,
                  address: waypoint.address,
                };
              }),
            }),
        };
    });

    return new Promise((resolve, reject) => {
        // * NEW PATH - POST and save id's returned
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/paths/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid: userId,
                breaks: breaks,
                pathways: pathways,
                ...(path.pathName && {
                    path_name: path.pathName,
                }),
                ...(path.tags !== undefined && {
                    tags: path.tags,
                }),
                details: {
                    ...(path.startDate && {
                        start_date: path.startDate.toUTCString(),
                    }),
                    ...(path.endDate && {
                        end_date: path.endDate.toUTCString(),
                    }),
                    ongoing: path.ongoing,
                    future: path.future,
                    ...(path.caption && {
                        caption: path.caption,
                    }),
                    ...(path.details && path.details.length && {
                        length: path.details.length[0],
                    }),
                    ...(path.details && path.details.budgets.length && {
                        budget: path.details.budgets[0],
                    }),
                    ...(path.details && path.details.cohort.length && {
                        cohorts: path.details.cohort,
                    }),
                    ...(path.details && path.details.companions.length && {
                        companions: path.details.companions,
                    }),
                    ...(path.details && path.details.pets.length && {
                        pets: path.details.pets,
                    }),
                    ...(path.details && path.details.spaces.length && {
                        spaces: path.details.spaces,
                    }),
                    ...(path.details && path.details.stays.length && {
                        stays: path.details.stays,
                    }),
                },
            }),
        })
        .then((res) => res.json())
        .then((savedPath) => {
            console.log("SAVE PATH RESPONSE: ", savedPath);

            path.pid = savedPath.id;

            savedPath.breaks.forEach((b, key) => {
                path.nodes[key].id = b.id;

                if (b.images && b.images.length) {
                    b.images.forEach((img, index) => {
                        path.nodes[key].fields.images[index].id = img.id;
                    });
                }

                if (b.subbreaks && b.subbreaks.length) {
                    b.subbreaks.forEach((sb, index) => {
                        path.nodes[key].subbreaks[index].id = sb.id;

                        if (sb.images && sb.images.length) {
                            sb.images.forEach((img, i) => {
                                path.nodes[key].subbreaks[index].images[i].id = img.id;
                            });
                        }
                    });
                }
            });

            savedPath.pathways.forEach((pw, key) => {
                path.routePoints[key].id = pw.id;

                if (pw.images && pw.images.length) {
                    pw.images.forEach((img, index) => {
                        path.routePoints[key].fields.images[index].id = img.id;
                    });
                }

                if (pw.waypoints && pw.waypoints.length) {
                    pw.waypoints.forEach((wp, index) => {
                        path.routePoints[key].midpoints[index].id = wp.id;
                    });
                }
            });

            console.log("UPDATED PATH: ", path);
            resolve(path);
        })
        .catch((err) => reject(err));
    })
}

const updatePathInDb = async (userId, path) => {
    const breaks = path.nodes.map((break_) => {
        return {
          latitude: break_.lat,
          longitude: break_.long,
          address: break_.address,
          place_name: break_.placeName,
          country: break_.country,
          break_index: break_.breakIndex,
          break_type: break_.fields.breakType,
          ...(break_.fields.title && { title: break_.fields.title }),
          ...(break_.fields.description && {
            description: break_.fields.description,
          }),
          ...(break_.fields.images.length && { images: break_.fields.images }),
          ...(break_.id && { id: break_.id }),
          ...(break_.subbreaks &&
            break_.subbreaks.length && {
              subbreaks: break_.subbreaks.map((subbreak, key) => {
                return {
                  longitude: subbreak.long,
                  latitude: subbreak.lat,
                  address: subbreak.address,
                  place_name: subbreak.placeName,
                  country: subbreak.country,
                  subbreak_index: key,
                  break_type: subbreak.fields.breakType,
                  description: subbreak.fields.description,
                  ...(subbreak.images && subbreak.images.length && {
                    images: subbreak.images,
                  }),
                  ...(subbreak.id && { id: subbreak.id }),
                };
              }),
            }),
        };
    });

    const pathways = path.routePoints.map((pathway) => {
        return {
          pathway_index: pathway.navIndex,
          label: pathway.label,
          start_lng: pathway.startCoords[0],
          start_lat: pathway.startCoords[1],
          end_lng: pathway.endCoords[0],
          end_lat: pathway.endCoords[1],
          nav_type: pathway.navType,
          description: pathway.fields.description,
          transport_types: pathway.fields.transportTypes,
          ...(pathway.fields.images.length && {
            images: pathway.fields.images,
          }),
          ...(pathway.id && { id: pathway.id }),
          ...(pathway.midpoints &&
            pathway.midpoints.length && {
              waypoints: pathway.midpoints.map((waypoint, key) => {
                return {
                  longitude: waypoint.coordinates[0],
                  latitude: waypoint.coordinates[1],
                  waypoint_index: key,
                  address: waypoint.address,
                };
              }),
            }),
        };
    });


    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/paths/${path.pid}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                uid: userId,
                breaks: breaks,
                pathways: pathways,
                path_name: path.pathName,
                ...(path.tags !== undefined && {
                    tags: path.tags,
                }),
                details: {
                    ...(path.startDate && {
                        start_date: path.startDate.toUTCString(),
                    }),
                    ...(path.endDate && {
                        end_date: path.endDate.toUTCString(),
                    }),
                    ongoing: path.ongoing,
                    future: path.future,
                    ...(path.caption && {
                        caption: path.caption,
                    }),
                    ...(path.details && path.details.length && {
                        length: path.details.length[0],
                    }),
                    ...(path.details && path.details.budgets.length && {
                        budget: path.details.budgets[0],
                    }),
                    ...(path.details && path.details.cohort.length && {
                        cohorts: path.details.cohort,
                    }),
                    ...(path.details && path.details.companions.length && {
                        companions: path.details.companions,
                    }),
                    ...(path.details && path.details.pets.length && {
                        pets: path.details.pets,
                    }),
                    ...(path.details && path.details.spaces.length && {
                        spaces: path.details.spaces,
                    }),
                    ...(path.details && path.details.stays.length && {
                        stays: path.details.stays,
                    }),
                },
            })
        })
        .then((res) => res.json())
        .then((updatedPath) => {
            console.log("UPDATE PATH RESPONSE: ", updatedPath);

            // * if context breaks/subbreaks have no id, they are new, so store their ids...
            updatedPath.breaks.forEach((b, key) => {
              if (!path.nodes[key].id)
                path.nodes[key].id = b.id;

              if (b.images && b.images.length) {
                b.images.forEach((img, index) => {
                  if (!path.nodes[key].fields.images[index].id)
                    path.nodes[key].fields.images[index].id =
                      img.id;
                });
              }

              if (b.subbreaks && b.subbreaks.length) {
                b.subbreaks.forEach((sb, index) => {
                  if (!path.nodes[key].subbreaks[index].id)
                    path.nodes[key].subbreaks[index].id = sb.id;

                  if (sb.images && sb.images.length) {
                    sb.images.forEach((img, i) => {
                      if (!path.nodes[key].subbreaks[index].images[i].id)
                        path.nodes[key].subbreaks[index].images[i].id = img.id;
                    });
                  }
                });
              }
            });

            updatedPath.pathways.sort(
              (a, b) => a.pathway_index - b.pathway_index
            );
            // * if context pathways/waypoints have no id, they are new, so store their ids...
            updatedPath.pathways.forEach((pw, key) => {
              if (!path.routePoints[key].id)
                path.routePoints[key].id = pw.id;

              if (pw.images && pw.images.length) {
                pw.images.forEach((img, index) => {
                  if (!path.routePoints[key].fields.images[index].id) {
                    path.routePoints[key].fields.images[index].id = img.id;
                  }
                });
              }

              if (pw.waypoints && pw.waypoints.length) {
                pw.waypoints.forEach((wp, index) => {
                  if (!path.routePoints[key].midpoints[index].id) {
                    path.routePoints[key].midpoints[index].id = wp.id;
                  }
                });
              }
            });

            resolve(path);
          })
          .catch((err) => reject(err));
    })
}

const pathApi = {
    getPathById,
    getPathsByUserId,
    addPathToFavorites,
    removePathFromFavorites,
    savePathToDb,
    updatePathInDb,
}

export default pathApi;
import React, { useContext, useState, useRef, } from "react";
import AuthContext from "../contexts/AuthContext";
import firebase from "../firebase";
import { toast } from 'react-toastify';

import "../styles/FeedbackForm.scss";

const FeedbackForm = (props) => {

    const {
        header,
        subheader,
        firestoreCollectionId,
        onSubmit = undefined,
    } = props;

    const { email } = useContext(AuthContext);

    const [feedbackText, setFeedbackText] = useState('');

    const textAreaRef = useRef(null);

    const onSubmitFeedback = () => {
        if(feedbackText) {
            firebase.firestore()
            .collection(firestoreCollectionId)
            .add({
                feedback: feedbackText,
                date: new Date(),
                email: email
            })
            .then(res => {
                console.log("FEEDBACK SUBMITTED: ", res);
                setFeedbackText('');
                toast.success("Thanks for your feedback!");
                if (onSubmit) onSubmit();
            })
            .catch(err => {
                console.error("Failed to submit feedback: ", err);
                toast.error("Failed to submit feedback. Please try again later.");
            })
        }
    }

    return (
        <div className="feedback-form">

            {header ? <h2>{header}</h2> : null}

            {subheader ? <label>{subheader}</label> : null}

            <textarea
                ref={textAreaRef}
                value={feedbackText}
                onChange={e => setFeedbackText(e.target.value)}
                onInput={() => {
                    textAreaRef.current.style.height = "";
                    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
                }}
            />

            <button
                onClick={onSubmitFeedback}
                disabled={feedbackText.length ? false : true}
                style={{
                    opacity: feedbackText.length ? 1 : 0.6,
                }}
            >
                Submit Feedback
            </button>
        </div>
    )
}

export default FeedbackForm;
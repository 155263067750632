
import React, { Component, useState, useContext } from 'react';
import _ from 'lodash';
import AuthContext from './AuthContext';

import profileAvatarYellow from '../images/default-profile-avatars/profile-avatar-yellow.png';

const ProfileContext = React.createContext('ben');

export const ProfileStore = (props) => {

    const authContext = useContext(AuthContext);

    const [newUser, setNewUser] = useState(false);

    const [profilePicture, setProfilePicture] = useState(profileAvatarYellow);

    const [name, setName] = useState('');

    const [username, setUsername] = useState('');

    const [bio, setBio] = useState('');

    const [accountCreationDate, setAccountCreationDate] = useState(null);

    const [passport, setPassport] = useState({
        birthday: {
            impactTravel: false,
            value: null
        },
        hometown: {
            impactTravel: false,
            value: ''
        },
        nationality: {
            impactTravel: false,
            value: []
        },
        language: {
            impactTravel: false,
            value: []
        },
        ethnicity: {
            impactTravel: false,
            value: []
        },
        gender: {
            impactTravel: false,
            value: []
        },
        sexuality: {
            impactTravel: false,
            value: [],
        },
        religion: {
            impactTravel: false,
            value: []
        },
        diet: {
            impactTravel: false,
            value: ''
        },
        medical: {
            impactTravel: false,
            value: ''
        },
        impairment: {
            impactTravel: false,
            value: ''
        },
        neurodiversity: {
            impactTravel: false,
            value: ''
        },
        mental: {
            impactTravel: false,
            value: ''
        },
        mobility: {
            impactTravel: false,
            value: []
        },

        // children: false,
        // pets: []
    });

    const [interestLibrary, setInterestLibrary] = useState({
        recreation: [],
        culture: [],
        wellness: [],
        nature: [],
        shopping: [],
        fun: [],
        sightseeing: [],
        nightlife: [],
        cuisine: [],
    });

    const getProfilePicture = () => {
        return profilePicture;
    }

    const getPassport = () => {
        return passport;
    }

    const getPassportValue = (type) => {
        return passport[type] ? passport[type].value : null;
    }

    const updatePassportValue = (type, value) => {
        let newPassport = Object.assign({}, passport);
        newPassport[type].value = value;
        setPassport(newPassport);
    }

    const getPassportImpactFields = () => {
        let impactFields = [];
        Object.entries(passport).forEach(([category, entry], key) => {
            if(entry.impactTravel) impactFields.push(category);
        });
        return impactFields;
    }

    const getGenderIdentities = () => {
        return passport.gender.value;
    }

    const updateGenderIdentities = (identities) => {
        let newPassport = Object.assign({}, passport);
        newPassport.gender.value = identities;
        setPassport(newPassport);
    }

    const getInterestLibrary = () => {
        return interestLibrary;
    }

    const getUserInterestsByCategory = (category) => {
        return interestLibrary[category];
    }

    const updateUserInterestsByCategory = (category, interests) => {

        let newInterestLib = Object.assign({}, interestLibrary);
        
        newInterestLib[category] = interests;

        setInterestLibrary(newInterestLib);

    }


    const selectInterestTag = (interest, category) => {

        let newInterestLib = Object.assign({}, interestLibrary);

        if(!newInterestLib[category].includes(interest)) {
            newInterestLib[category].push(interest);
        }

        setInterestLibrary(newInterestLib);

    }

    const deselectInterestTag = (interest, category) => {

        let newInterestLib = Object.assign({}, interestLibrary);

        if(newInterestLib[category].includes(interest)) {
            newInterestLib[category] = newInterestLib[category].filter(tag => tag !== interest);
        }

        setInterestLibrary(newInterestLib);
    }

    const getNumInterestTagsSelected = () => {
        let num = 0;
        Object.values(interestLibrary).forEach(category => num += category.length);
        return num;
    }

    const saveToDB = () => {

        fetch(`${process.env.REACT_APP_BACKEND_API_BASE}/users/${authContext.userId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                username: username,
                bio: bio,
                profile_picture: profilePicture,
                passport: {
                    ...passport,
                    ...((passport.birthday.impactTravel && passport.birthday.value) && {'birthday': { 'impactTravel': true, 'value': passport.birthday.value.toUTCString()}})
                },
                interests: interestLibrary,
            })
        })
        .then(res => res.json())
        .then(savedUser => {
            console.log("PROFILE SUCCESSFULLY SAVED: ", savedUser);
            // userDataContext.setPBPaneView(null);
        })
        .catch(err => {
            console.error('FAILED TO SAVE PROFILE: ', err);
        })
    }

    const loadProfileFieldsFromDB = user => {

        setName(user.name || '');
        setUsername(user.username || '')
        setBio(user.bio || '')
        if(user.profile_picture) setProfilePicture(user.profile_picture.image_url);
        setAccountCreationDate(user.account_created);

        if (user.passport) {
            let userPassport = Object.assign({}, passport)

            let formatPassport = _.omit({
                ...user.passport,
                birthday: user.passport.birthday ? new Date(Date.parse(user.passport.birthday)) : null,
                nationality: user.passport.nationalities.map(nation => nation.name),
                ethnicity: user.passport.ethnicities.map(ethnicity => ethnicity.name),
                gender: user.passport.genders.map(gender => gender.name),
                language: user.passport.languages.map(lang => lang.name),
                religion: user.passport.religions.map(religion => religion.name),
                sexuality: user.passport.sexualities.map(sex => sex.name),
                mobility: user.passport.mobility.map(mobility => mobility.name),
            }, ['ethnicities', 'nationalities', 'genders', 'languages', 'religions', 'sexualities', 'id']);

            console.log("FORMAT PASSPORT: ", formatPassport);
            
            Object.entries(formatPassport).forEach(([category, value]) => {
                if(value && (value.constructor !== Array || value.length)) {
                    userPassport[category].impactTravel = true;
                    userPassport[category].value = value;
                }
            })
            setPassport(userPassport);
        }

        if (user.interests) {
            let interests = Object.assign({}, interestLibrary)
            user.interests.forEach(interest => {
                interests[interest.category].push(interest.name);
            })
            setInterestLibrary(interests)
        }
    }

    const signOut = () => {
        setName('')
        setUsername('')
        setBio('')
        setProfilePicture('https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true')
        setPassport({
            birthday: { impactTravel: false, value: null },
            hometown: { impactTravel: false, value: '' },
            nationality: { impactTravel: false, value: [] },
            language: { impactTravel: false, value: [] },
            ethnicity: { impactTravel: false, value: [] },
            gender: { impactTravel: false, value: [] },
            sexuality: { impactTravel: false, value: [] },
            religion: { impactTravel: false, value: [] },
            diet: { impactTravel: false, value: '' },
            medical: { impactTravel: false, value: '' },
            impairment: { impactTravel: false, value: '' },
            neurodiversity: { impactTravel: false, value: '' },
            mental: { impactTravel: false, value: '' },
            mobility: { impactTravel: false, value: [] },
        })
        setInterestLibrary({
            recreation: [],
            culture: [],
            wellness: [],
            nature: [],
            shopping: [],
            fun: [],
            sightseeing: [],
            nightlife: [],
            cuisine: [],
        })
    }

    return (
        <ProfileContext.Provider value={{
            newUser,
            setNewUser,

            getProfilePicture,

            name,
            setName,

            username,
            setUsername,

            bio,
            setBio,

            accountCreationDate,
            setAccountCreationDate,

            profilePicture,
            setProfilePicture,

            passport,
            setPassport,
            getPassport,

            interestLibrary,
            setInterestLibrary,
            getInterestLibrary,

            // setInterestLibOptions,
            // getInterestLibOptions,

            getPassportValue,
            updatePassportValue,
            getGenderIdentities,
            updateGenderIdentities,
            getUserInterestsByCategory,
            updateUserInterestsByCategory,
            // getInterestLibOptionsByCategory,
            selectInterestTag,
            deselectInterestTag,
            getNumInterestTagsSelected,
            getPassportImpactFields,

            saveToDB,
            loadProfileFieldsFromDB,
            signOut,

        }}>
            {props.children}
        </ProfileContext.Provider>
    )
}

export default ProfileContext;

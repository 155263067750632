import React, { useState, useContext } from 'react';
import ProfileContext from '../contexts/ProfileContext.js';
import UserDataContext from '../contexts/UserDataContext.js';
import Tags from './Tags.js';
import { interestLibTags } from "../resources/profileOptions";

import "../styles/PathCreator.scss";

const TagsEditor = ({ activePath }) => {

    const profileContext = useContext(ProfileContext);
    const userDataContext = useContext(UserDataContext)

    const [openSection, setOpenSection] = useState(null);

    const CATEGORIES = {
        RECREATION: {
            'name': 'recreation',
            'label': 'Recreation'
        },
        CULTURE: {
            'name': 'culture',
            'label': 'Culture'
        },
        WELLNESS: {
            'name': 'wellness',
            'label': 'Wellness'
        },
        NATURE: {
            'name': 'nature',
            'label': 'Nature'
        },
        SHOPPING: {
            'name': 'shopping',
            'label': 'Shopping'
        },
        FUN: {
            'name': 'fun',
            'label': 'Fun & Games'
        },
        SIGHTSEEING: {
            'name': 'sightseeing',
            'label': 'Sightseeing'
        },
        NIGHTLIFE: {
            'name': 'nightlife',
            'label': 'Nightlife'
        },
        CUISINE: {
            'name': 'cuisine',
            'label': 'Cuisine'
        }
    }

    const handleClickSectionHeader = (category) => {
        if(openSection == category) {
            setOpenSection(null);
        } else {
            setOpenSection(category);
        }
    }

    return (
        <div className="tags-editor-container">
            {Object.values(CATEGORIES).map((category, key) => {
                return (
                    <div className="tags-category-editor" key={key}>
                        <div className="tags-category-header" onClick={() => handleClickSectionHeader(category.name)}>
                            {category.label}
                            {userDataContext.getSelectedPathTagsByCategory(activePath, category.name).length ? (
                                    <label>{userDataContext.getSelectedPathTagsByCategory(activePath, category.name).length}</label>
                                ) : (
                                    null
                                )
                            }
                            <i className="material-icons">{`expand_${openSection == category.name ? 'less' : 'more'}`}</i>
                        </div>
                        <div className="tags-category-content" style={{ display: openSection == category.name ? 'block' : 'none'}}>
                            <Tags 
                                tagList={interestLibTags[category.name]}
                                selectedTags={userDataContext.getSelectedPathTagsByCategory(activePath, category.name)}
                                updateSelectedTags={(type, selectedTags) => userDataContext.updatePathTagsByCategory(activePath, type, selectedTags)}
                                type={category.name}
                                maxNew={5}
                                multi={true}
                                allowNewTags={true}
                                newTagMaxLength={80}
                            />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default TagsEditor;
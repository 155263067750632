import React, { useState, useContext, useRef, useEffect } from "react";
import AuthContext from "../contexts/AuthContext";
import UserDataContext from "../contexts/UserDataContext";
import pathApi from "../utils/pathApi";
import travelerStatsApi from "../utils/travelerStatsApi";
import pathUtils from "../utils/pathUtils";

import { localSpotIcons, navIcons, } from "../constants";

import "../styles/TravelerStats.scss";

const TravelerStats = (props) => {

    const {
        userId
    } = props;

    const {
        userPaths,
    } = useContext(UserDataContext);

    const authContext = useContext(AuthContext);

    const [numPaths, setNumPaths] = useState(null);
    const [distanceTraveledKm, setDistanceTraveledKm] = useState(null);
    const [distanceTraveledMiles, setDistanceTraveledMiles] = useState(null);
    const [countriesVisited, setCountriesVisited] = useState(null);
    const [topBreakTypesUsed, setTopBreakTypesUsed] = useState(null);
    const [topTransportsUsed, setTopTransportsUsed] = useState(null);
    const [countryFlags, setCountryFlags] = useState(null);

    useEffect(() => {

        fetchFlags();

        if(userId === authContext.userId) {
            console.log("CURRENT USER")
            setStats(Object.values(userPaths));
        } else {
            pathApi.getPathsByUserId(userId)
                .then(paths => formatPathsAndSetStats(paths))
                .catch(err => console.error("FAILED TO GET USERS PATHS: ", err))
        }
        fetchCountriesVisited(userId);
        fetchTopBreakTypes(userId);
        fetchTopTransportMethods(userId);
    }, [])

    const fetchFlags = async () => {
        const flags = await travelerStatsApi.getCountryFlags();
        setCountryFlags(await flags)
    }

    const fetchCountriesVisited = async (id) => {
        travelerStatsApi.getCountriesVisited(id)
            .then(async res => {
                if (res.success) {
                    const flags = await travelerStatsApi.getCountryFlags();
                    const countries = res.countries.filter(async country => {
                        if (await flags[country]) return true;
                        else return false
                    });
                    if (countries.includes('USA') && countries.includes('United States')) {
                        countries.splice(countries.indexOf('United States'), 1)
                    }
                    if (countries.includes('UK') && countries.includes('United Kingdom')) {
                        countries.splice(countries.indexOf('United Kingdom'), 1)
                    }
                    setCountriesVisited(countries);
                }
            })
            .catch(err => {
                console.log("FAILED TO GET COUNTRIES VISITED: ", err);
            })
    }

    const fetchTopBreakTypes = (id) => {
        travelerStatsApi.getTopBreakTypesUsed(id)
            .then(res => {
                if (res.success) {
                    setTopBreakTypesUsed(res.top_break_types);
                }
            })
            .catch(err => {
                console.log("FAILED TO GET TOP BREAK TYPES: ", err);
            })
    }

    const fetchTopTransportMethods = (id) => {
        travelerStatsApi.getTopTransportMethods(id)
            .then(res => {
                if (res.success) {
                    setTopTransportsUsed(res.top_transport_methods);
                }
            })
            .catch(err => {
                console.log("FAILED TO GET TOP BREAK TYPES: ", err);
            })
    }

    const formatPathsAndSetStats = async (paths) => {
        const formattedPaths = await Promise.all(paths.map(path => pathUtils.formatPathFromDb(path)));
        console.log("FORMATTED PATHS: ", formattedPaths)
        setStats(formattedPaths);
    }

    const setStats = (formattedPaths) => {
        let totalPaths = 0, distanceKm = 0, distanceMiles = 0;
        totalPaths = formattedPaths.length;
        console.log("FORMATTED LENGTH: ", totalPaths);
        distanceKm = pathUtils.getTotalDistanceCoveredKm(formattedPaths);
        distanceMiles = pathUtils.kmToMiles(distanceKm);
        setNumPaths(totalPaths);
        setDistanceTraveledKm(distanceKm);
        setDistanceTraveledMiles(distanceMiles);
    }

    return (
        <div className="traveler-stats-container">
            {(numPaths !== null && distanceTraveledKm !== null && distanceTraveledMiles !== null) && (
                <div className="traveler-stats-path-stats">
                    <div>
                        <h2>
                            {numPaths}
                        </h2>
                        <label>Paths</label>
                    </div>
                    <div>
                        <h2>
                            {distanceTraveledKm.toFixed(0)}
                        </h2>
                        <label>Km</label>
                    </div>
                    <div>
                        <h2>
                        {distanceTraveledMiles.toFixed(0)}
                        </h2>
                        <label>Miles</label>
                    </div>
                </div>
            )}
            {(countriesVisited !== null) ? (
                countriesVisited.length > 0 ? (
                    <div className="countries-container">
                        <h4>Countries Visited<label>{` (${countriesVisited.length})`}</label></h4>
                        <span>
                            {countriesVisited.map((country, i) => {
                                if(!countryFlags[country]) return null;

                                else return (
                                    <div key={i}>
                                        {countryFlags !== null ? (
                                            <h2>{countryFlags[country]}</h2>
                                        ) : null }
                                        <label>{country}</label>
                                    </div>
                                )
                            })}
                        </span>
                    </div>
                ) : null
            ) : null}

            {(topBreakTypesUsed !== null && topTransportsUsed !== null) ? (
                <div className="path-icons-stats">
                    <span>
                        <div className="flex left-just">
                            <h4>Top Activities:</h4>
                        </div>
                        <div className="flex left-just">
                            <h4>Top Modes of Transport:</h4>
                        </div>
                    </span>
                    <span>
                        <div className="flex">
                            <div className="icon-container">
                                {topBreakTypesUsed.map((breakType, i) => (
                                    <div style={{ margin: '0 3px'}} key={i}>
                                        <img src={localSpotIcons[breakType]} style={{ height: 30, width: 30 }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex">
                            <div className="icon-container">
                                {topTransportsUsed.map((transport, i) => (
                                    <div style={{ margin: '0 3px'}} key={i}>
                                        <img src={navIcons[transport]} style={{ height: 30, width: 30 }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </span>
                </div>
            ) : null}

        </div>
    )

}

export default TravelerStats;
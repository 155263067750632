import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import MapComponent from '../components/MapComponent';
import UserDataContext from '../contexts/UserDataContext';
import AuthContext from '../contexts/AuthContext';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/General.scss';
import '../styles/ProfileModal.scss';
import Logo from '../images/new_pathbreaker_logo_black.PNG';
import SideBar from '../components/SideBar';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ProfileContext from '../contexts/ProfileContext';
import Tabs from '../components/Tabs';
import PassportEditor from '../components/PassportEditor';
import InterestLibraryEditor from '../components/InterestLibraryEditor';

import { useBreakpoints } from "react-breakpoints-hook";
import pathApi from '../utils/pathApi';
import pathUtils from '../utils/pathUtils';
import { ToastContainer } from "react-toastify";
import UsernamePrompt from '../components/UsernamePrompt';
import NewUserFlow from '../components/NewUserFlow';

// We can rename this file if we want -- Dashboard can be temporary
// I've implemented a responsive Grid using Semantic UI React -- there is a lot of documentation for this online
// ---- This Semantic Grid doesn't have to be permanent - it was just so I could set up the PathCreator pane on the left side so I could work on the Map component on the right side
const Dashboard = (props) => {

    const { mobile } = useBreakpoints({
        mobile: { min: 0, max: 600 },
    });

    const userDataContext = useContext(UserDataContext);
    const profileContext = useContext(ProfileContext);
    const authContext = useContext(AuthContext);

    const { pathId } = useParams();

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: mobile ? '1.8rem 0.8rem 0 0.8rem' : '3rem 2rem',
            overflow: 'hidden',
            height: mobile ? '600px' : 'initial'
        },
        overlay: {
            zIndex: 1000,
            backgroundColor: "rgba(33, 88, 92, 0.8)",
        },
    };
    
    useEffect(() => {
        Modal.setAppElement('#dashboard');
    }, [])

    useEffect(() => {
        if(pathId) {
            console.log("GOT PATH ID PARAM: ", pathId);

            const fetchPath = async (id) => {
                let sharedPath = await pathApi.getPathById(id);
                sharedPath = await pathUtils.formatPathFromDb(await sharedPath);
                console.log("FORMATTED SHARED PATH")
                userDataContext.setActiveSharedPath(await sharedPath);
            }

            fetchPath(pathId);
        }
    }, [pathId])

    
    return (
        <Fragment>
            <div id="dashboard" className="dashboard-container">

                <div className="sidebar-container" style={{ width: "fit-content"}}>
                    <SideBar
                        activePath={userDataContext.getActivePath()}
                    />
                </div>

                <MapComponent
                    activePath={userDataContext.getActivePath()}
                    showScratchMap={true}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    draggable
                    style={{
                        position: "absolute",
                        right: 5,
                        top: 0,
                        width: 250,
                    }}
                />

            </div>
            <Modal 
                isOpen={profileContext.newUser}
                onRequestClose={() => {
                    if(profileContext.username) {
                        profileContext.setNewUser(false);
                    } 
                }}
                style={modalStyles}
                contentLabel="My Profile"
            >
                {!profileContext.username ? (
                    <div className="username-prompt-modal">
                        <UsernamePrompt />
                    </div>
                ) : (
                    <NewUserFlow />
                )}
            </Modal>
        </Fragment>

    );
}

export default Dashboard;